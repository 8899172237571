import { RIGHT_KEYS, ROLES } from 'config/constants';
import { union } from 'lodash/array';

export function checkRightInAllPlatform({
  rights = {},
  checkingRight = '',
}) {
  const list = Object.keys(rights);

  const platformWithRight = list
    .filter((current) => {
      const currentRights = rights[current] || [];
      const isRight = !!(currentRights[checkingRight] || false);

      return isRight;
    })
    // eslint-disable-next-line radix,no-restricted-globals
    .map((id) => (isNaN(id) ? id : Number.parseInt(id)));

  const isThisRight = platformWithRight && platformWithRight.length > 0;

  return {
    isThisRight,
    platformWithRight,
  };
}

export function formatRights({
  rules,
  allPlatformsData,
}) {
  const commonRights = {};

  rules.filter((rule) => {
    const { checked, isRuleCommon } = rule || {};

    return checked && isRuleCommon;
  })
    .forEach((rule) => {
      const { checked, ruleId, ruleData } = rule || {};

      if (checked) {
        commonRights[ruleId] = ruleData;
      }
    });

  const rightPlatforms = allPlatformsData.reduce(
    (accum, current, index) => {
      const currentId = (current || {}).id;
      const facilityName = `facility_id_${currentId}`;

      const idRules = {};

      rules
        .filter((rule) => {
          const { isRuleCommon } = rule || {};

          return !isRuleCommon;
        })
        .forEach((ruleList) => {
          const currentPlatformRule = ruleList[index];
          const { checked, ruleId, ruleData } = currentPlatformRule;

          if (checked) {
            idRules[ruleId] = ruleData;
          }
        });

      return {
        ...accum,
        [facilityName]: idRules,
      };
    },
    {},
  );

  const rights = {
    ...rightPlatforms,
    common: commonRights,
  };

  return rights;
}

export function getUserRights(operator) {
  const { role, rights } = operator || {};

  if ([ROLES.admin, ROLES.dealer].includes(role)) {
    return {
      isAdmin: true,
      isOperatorUpdate: true,
      isAccountUpdate: true,
      isBlacklistShow: true,
      isBlacklistUpdate: true,
      isFacilityCreate: true,
      isFacilityShow: true,
      listFacilityShow: [],
      isFacilityUpdate: true,
      listFacilityUpdate: [],
      isPlacesShow: true,
      listPlacesShow: [],
      isPlacesUpdate: true,
      listPlacesUpdate: [],
      isAutomationShow: true,
      listAutomationShow: [],
      isAutomationUpdate: true,
      listAutomationUpdate: [],
      isAutomationOpenGate: true,
      listAutomationOpenGate: [],
      isSiteShow: true,
      listSiteShow: [],
      isSiteUpdate: true,
      listSiteUpdate: [],
      isCustomerShow: true,
      listCustomerShow: [],
      isCustomerUpdate: true,
      listCustomerUpdate: [],
      isCustomerSessionGateControl: true,
      listCustomerSessionGateControl: [],
      isCustomerAccessTokenControl: true,
      listCustomerAccessTokenControl: [],
      isTariffShow: true,
      listTariffShow: [],
      isTariffUpdate: true,
      listTariffUpdate: [],
      isSessionShow: true,
      listSessionShow: [],
      isSessionChangeStatus: true,
      listSessionChangeStatus: [],
      isSessionChangeTariff: true,
      listSessionChangeTariff: [],
      isPaymentShow: true,
      listPaymentShow: [],
      isPaymentCancel: true,
      listPaymentCancel: [],
      isPassTypeShow: true,
      listPassTypeShow: [],
      isPassTypeUpdate: true,
      listPassTypeUpdate: [],
      isTicketTypeShow: true,
      listTicketTypeShow: [],
      isTicketTypeUpdate: true,
      listTicketTypeUpdate: [],
      isPassShow: true,
      listPassShow: [],
      isPassUpdate: true,
      listPassUpdate: [],
      isTicketUpdate: true,
      listTicketUpdate: [],
      isPassManualPayment: true,
      listPassManualPayment: [],
      isPassCancel: true,
      listPassCancel: [],
      isAnalyticsShow: true,
      listAnalyticsShow: [],
      isGatewayLogShow: true,
      listGatewayLogShow: [],
    };
  }

  const { common = [] } = rights || {};

  const isOperatorUpdate = !!(common[RIGHT_KEYS.operatorUpdate] || false);
  const isAccountUpdate = !!(common[RIGHT_KEYS.accountUpdate] || false);
  const isBlacklistShow = !!(common[RIGHT_KEYS.blackListShow] || false);
  const isBlacklistUpdate = !!(common[RIGHT_KEYS.blackListUpdate] || false);

  const {
    isThisRight: isFacilityShow,
    platformWithRight: listFacilityShow,
  } = checkRightInAllPlatform({
    rights,
    checkingRight: RIGHT_KEYS.facilityShow,
  }) || {};

  const {
    isThisRight: isFacilityUpdate,
    platformWithRight: listFacilityUpdate,
  } = checkRightInAllPlatform({
    rights,
    checkingRight: RIGHT_KEYS.facilityUpdate,
  }) || {};

  const {
    isThisRight: isPlacesShow,
    platformWithRight: listPlacesShow,
  } = checkRightInAllPlatform({
    rights,
    checkingRight: RIGHT_KEYS.placesShow,
  }) || {};

  const {
    isThisRight: isPlacesUpdate,
    platformWithRight: listPlacesUpdate,
  } = checkRightInAllPlatform({
    rights,
    checkingRight: RIGHT_KEYS.placesShow,
  }) || {};

  const {
    isThisRight: isAutomationShow,
    platformWithRight: listAutomationShow,
  } = checkRightInAllPlatform({
    rights,
    checkingRight: RIGHT_KEYS.automationShow,
  }) || {};

  const {
    isThisRight: isAutomationUpdate,
    platformWithRight: listAutomationUpdate,
  } = checkRightInAllPlatform({
    rights,
    checkingRight: RIGHT_KEYS.automationUpdate,
  }) || {};

  const {
    isThisRight: isAutomationOpenGate,
    platformWithRight: listAutomationOpenGate,
  } = checkRightInAllPlatform({
    rights,
    checkingRight: RIGHT_KEYS.automationOpenGate,
  }) || {};

  const {
    isThisRight: isSiteShow,
    platformWithRight: listSiteShow,
  } = checkRightInAllPlatform({
    rights,
    checkingRight: RIGHT_KEYS.siteShow,
  }) || {};

  const {
    isThisRight: isSiteUpdate,
    platformWithRight: listSiteUpdate,
  } = checkRightInAllPlatform({
    rights,
    checkingRight: RIGHT_KEYS.siteUpdate,
  }) || {};

  const {
    isThisRight: isCustomerShow,
    platformWithRight: listCustomerShow,
  } = checkRightInAllPlatform({
    rights,
    checkingRight: RIGHT_KEYS.customerShow,
  }) || {};

  const {
    isThisRight: isCustomerUpdate,
    platformWithRight: listCustomerUpdate,
  } = checkRightInAllPlatform({
    rights,
    checkingRight: RIGHT_KEYS.customerUpdate,
  }) || {};

  const {
    isThisRight: isCustomerSessionGateControl,
    platformWithRight: listCustomerSessionGateControl,
  } = checkRightInAllPlatform({
    rights,
    checkingRight: RIGHT_KEYS.customerSessionGateControl,
  }) || {};

  const {
    isThisRight: isCustomerAccessTokenControl,
    platformWithRight: listCustomerAccessTokenControl,
  } = checkRightInAllPlatform({
    rights,
    checkingRight: RIGHT_KEYS.customerAccessTokenControl,
  }) || {};

  const {
    isThisRight: isTariffShow,
    platformWithRight: listTariffShow,
  } = checkRightInAllPlatform({
    rights,
    checkingRight: RIGHT_KEYS.tariffShow,
  }) || {};

  const {
    isThisRight: isTariffUpdate,
    platformWithRight: listTariffUpdate,
  } = checkRightInAllPlatform({
    rights,
    checkingRight: RIGHT_KEYS.tariffUpdate,
  }) || {};

  const {
    isThisRight: isSessionShow,
    platformWithRight: listSessionShow,
  } = checkRightInAllPlatform({
    rights,
    checkingRight: RIGHT_KEYS.sessionShow,
  }) || {};

  const {
    isThisRight: isSessionChangeStatus,
    platformWithRight: listSessionChangeStatus,
  } = checkRightInAllPlatform({
    rights,
    checkingRight: RIGHT_KEYS.sessionChangeStatus,
  }) || {};

  const {
    isThisRight: isSessionChangeTariff,
    platformWithRight: listSessionChangeTariff,
  } = checkRightInAllPlatform({
    rights,
    checkingRight: RIGHT_KEYS.sessionChangeTariff,
  }) || {};

  const {
    isThisRight: isPaymentsShow,
    platformWithRight: listPaymentsShow,
  } = checkRightInAllPlatform({
    rights,
    checkingRight: RIGHT_KEYS.paymentsShow,
  }) || {};

  const {
    isThisRight: isPaymentsCancel,
    platformWithRight: listPaymentsCancel,
  } = checkRightInAllPlatform({
    rights,
    checkingRight: RIGHT_KEYS.paymentsCancel,
  }) || {};

  const {
    isThisRight: isPassTypeShow,
    platformWithRight: listPassTypeShow,
  } = checkRightInAllPlatform({
    rights,
    checkingRight: RIGHT_KEYS.passTypeShow,
  }) || {};

  const {
    isThisRight: isPassTypeUpdate,
    platformWithRight: listPassTypeUpdate,
  } = checkRightInAllPlatform({
    rights,
    checkingRight: RIGHT_KEYS.passTypeUpdate,
  }) || {};

  const {
    isThisRight: isTicketTypeShow,
    platformWithRight: listTicketTypeShow,
  } = checkRightInAllPlatform({
    rights,
    checkingRight: RIGHT_KEYS.ticketTypeShow,
  }) || {};

  const {
    isThisRight: isTicketTypeUpdate,
    platformWithRight: listTicketTypeUpdate,
  } = checkRightInAllPlatform({
    rights,
    checkingRight: RIGHT_KEYS.ticketTypeUpdate,
  }) || {};

  const {
    isThisRight: isPassShow,
    platformWithRight: listPassShow,
  } = checkRightInAllPlatform({
    rights,
    checkingRight: RIGHT_KEYS.passShow,
  }) || {};

  const {
    isThisRight: isPassUpdate,
    platformWithRight: listPassUpdate,
  } = checkRightInAllPlatform({
    rights,
    checkingRight: RIGHT_KEYS.passUpdate,
  }) || {};

  const {
    isThisRight: isPassManualPayment,
    platformWithRight: listPassManualPayment,
  } = checkRightInAllPlatform({
    rights,
    checkingRight: RIGHT_KEYS.passManualPayment,
  }) || {};

  const {
    isThisRight: isPassCancel,
    platformWithRight: listPassCancel,
  } = checkRightInAllPlatform({
    rights,
    checkingRight: RIGHT_KEYS.passCancel,
  }) || {};

  const {
    isThisRight: isTicketUpdateOwn,
    platformWithRight: listTicketUpdateOwn,
  } = checkRightInAllPlatform({
    rights,
    checkingRight: RIGHT_KEYS.ticketUpdate,
  }) || {};

  const {
    isThisRight: isTicketUpdateAll,
    platformWithRight: listTicketUpdateAll,
  } = checkRightInAllPlatform({
    rights,
    checkingRight: RIGHT_KEYS.ticketUpdateAll,
  }) || {};

  const isTicketUpdate = isTicketUpdateOwn || isTicketUpdateAll;
  const listTicketUpdate = union(listTicketUpdateAll, listTicketUpdateOwn);

  const {
    isThisRight: isAnalyticsShow,
    platformWithRight: listAnalyticsShow,
  } = checkRightInAllPlatform({
    rights,
    checkingRight: RIGHT_KEYS.analyticsShow,
  }) || {};

  const {
    isThisRight: isGatewayLogShow,
    platformWithRight: listGatewayLogShow,
  } = checkRightInAllPlatform({
    rights,
    checkingRight: RIGHT_KEYS.gatewayLogShow,
  }) || {};

  const re =  {
    isAdmin: false,
    isOperatorUpdate: role === ROLES.dealer,
    isAccountUpdate,
    isBlacklistShow,
    isBlacklistUpdate,
    isFacilityCreate: role === ROLES.dealer,
    isFacilityShow,
    listFacilityShow,
    isFacilityUpdate,
    listFacilityUpdate,
    isPlacesShow,
    listPlacesShow,
    isPlacesUpdate,
    listPlacesUpdate,
    isAutomationShow,
    listAutomationShow,
    isAutomationUpdate,
    listAutomationUpdate,
    isAutomationOpenGate,
    listAutomationOpenGate,
    isSiteShow,
    listSiteShow,
    isSiteUpdate,
    listSiteUpdate,
    isCustomerShow,
    listCustomerShow,
    isCustomerUpdate,
    listCustomerUpdate,
    isCustomerSessionGateControl,
    listCustomerSessionGateControl,
    isCustomerAccessTokenControl,
    listCustomerAccessTokenControl,
    isTariffShow,
    listTariffShow,
    isTariffUpdate,
    listTariffUpdate,
    isSessionShow,
    listSessionShow,
    isSessionChangeStatus,
    listSessionChangeStatus,
    isSessionChangeTariff,
    listSessionChangeTariff,
    isPaymentsShow,
    listPaymentsShow,
    isPaymentsCancel,
    listPaymentsCancel,
    isPassTypeShow,
    listPassTypeShow,
    isPassTypeUpdate,
    listPassTypeUpdate,
    isTicketTypeShow,
    listTicketTypeShow,
    isTicketTypeUpdate,
    listTicketTypeUpdate,
    isPassShow,
    listPassShow,
    isPassUpdate,
    listPassUpdate,
    isTicketUpdate,
    listTicketUpdate,
    isPassManualPayment,
    listPassManualPayment,
    isPassCancel,
    listPassCancel,
    isAnalyticsShow,
    listAnalyticsShow,
    isGatewayLogShow,
    listGatewayLogShow,
  };

  console.log(re)

  return re;
}

export default {
  getUserRights,
};
