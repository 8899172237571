export const COOKIE_NAMES = {
  accessToStaging: 'accessToStaging',
};

export const LS_KEYS = {
  sessionsShowPhotos: 'sessions_show_photos',
};

export const SUPPORT_PHONE_STR = '+7 495 477 47 04';

export const SECTIONS = {
  topBar: 'topbar-section',
  filters: 'filters-section',
  right: 'right-section',
};

export const PATH_PAGES = {
  userAgreement: '/user-agreement',
  fastLogin: '/fast-login',
  home: '/home',
  settings: '/settings',
  companySettings: '/settings/company',
  facilitySettings: '/settings/facility/:facilityId',
  newFacilitySettings: '/settings/facility/new',
  scheduleSettings: '/settings/facility/:facilityId/schedule',
  placesSettings: '/settings/facility/:facilityId/places',
  integrationSettings: '/settings/facility/:facilityId/integration',
  tariffSettings: '/settings/facility/:facilityId/tariffs',
  allTariffSettings: '/settings/facility/:facilityId/tariffs/all',
  newTariffSettings: '/settings/facility/:facilityId/tariffs/new',
  oneTariffSettings: '/settings/facility/:facilityId/tariffs/:tariffId',
  tariffPlanSettings: '/settings/facility/:facilityId/tariff-plans',
  siteSettings: '/settings/facility/:facilityId/site',
  allTariffPlansSettings: '/settings/facility/:facilityId/tariff-plans/all',
  newTariffPlanSettings: '/settings/facility/:facilityId/tariff-plans/new',
  oneTariffPlanSettings: '/settings/facility/:facilityId/tariff-plans/:tariffPlanId',
  passesSettings: '/settings/facility/:facilityId/passes',
  allPassesSettings: '/settings/facility/:facilityId/passes/all',
  newPassSettings: '/settings/facility/:facilityId/passes/new',
  onePassSettings: '/settings/facility/:facilityId/passes/:passId',
  ticketsSettings: '/settings/facility/:facilityId/tickets',
  allTicketsSettings: '/settings/facility/:facilityId/tickets/all',
  newTicketSettings: '/settings/facility/:facilityId/tickets/new',
  oneTicketSettings: '/settings/facility/:facilityId/tickets/:ticketId',
  licenseSettings: '/settings/facility/:facilityId/license',
  customers: '/customers/:facilityId',
  allCustomers: '/customers/:facilityId/all',
  oneCustomer: '/customers/:facilityId/:id',
  oneCustomerInfo: '/customers/:facilityId/info/:id',
  addCustomer: '/customers/:facilityId/new',
  passes: '/passes/:facilityId',
  allPasses: '/passes/:facilityId/all',
  onePass: '/passes/:facilityId/:id',
  addPass: '/passes/:facilityId/new',
  onePassInfo: '/passes/:facilityId/info/:id',
  tickets: '/tickets/:facilityId',
  allTickets: '/tickets/:facilityId/all',
  oneTicket: '/tickets/:facilityId/:id',
  addTicket: '/tickets/:facilityId/new',
  oneTicketInfo: '/tickets/:facilityId/info/:id',
  sessions: '/sessions/:facilityId',
  allSessions: '/sessions/:facilityId/all',
  payments: '/payments/:facilityId',
  allPayments: '/payments/:facilityId/all',
  gatewayEvents: '/gate-events/:facilityId',
  allGatewayEvents: '/gate-events/:facilityId/all',
  account: '/account',
  operators: '/operators',
  operatorsAll: '/operators/all',
  operatorsActions: '/operators/actions',
  oneOperator: '/operators/:id',
  addNewOperator: '/operators/new',
  blackList: '/black-list',
  allBlackList: '/black-list/all',
  newBlackList: '/black-list/new',
  oneBlackList: '/black-list/:id',
  analytics: '/analytics/:facilityId',
  analyticsv2: '/v2/analytics',
  help: '/help',
  notEnoughRights: '/not-enough-rights',
};

export const RIGHT_KEYS = {
  admin: 'admin',
  operatorUpdate: 'operator_update',
  facilityShow: 'facility_show',
  facilityUpdate: 'facility_update',
  facilityCreate: 'facility_create',
  placesShow: 'places_show',
  placesUpdate: 'places_update',
  automationShow: 'automation_show',
  automationUpdate: 'automation_update',
  automationOpenGate: 'automation_open_gate',
  siteShow: 'site_show',
  siteUpdate: 'site_update',
  customerShow: 'customer_show',
  customerUpdate: 'customer_update',
  customerSessionGateControl: 'customer_session_gate_control',
  customerAccessTokenControl: 'customer_access_token_control',
  blackListShow: 'blacklist_show',
  blackListUpdate: 'blacklist_update',
  tariffShow: 'tariff_show',
  tariffUpdate: 'tariff_update',
  accountUpdate: 'account_update',
  analyticsShow: 'analytics_show',
  sessionShow: 'session_show',
  sessionChangeStatus: 'session_change_status',
  sessionChangeTariff: 'session_change_tariff',
  paymentsShow: 'payments_show',
  paymentsCancel: 'payments_cancel',
  passTypeShow: 'pass_type_show',
  passTypeUpdate: 'pass_type_update',
  ticketTypeShow: 'ticket_type_show',
  ticketTypeUpdate: 'ticket_type_update',
  passShow: 'pass_show',
  passUpdate: 'pass_update',
  ticketUpdate: 'ticket_update',
  ticketUpdateAll: 'ticket_update_all',
  passManualPayment: 'pass_manual_payment',
  passCancel: 'pass_cancel',
  gatewayLogShow: 'gateway_log_show',
};

export const ROLES = {
  admin: 'admin',
  dealer: 'dealer',
  custom: 'custom',
};

export const MODULES_KEYS = {
  payments: 'hasModulePayments',
};

export const MAP_MODULES_RIGHTS = {
  [RIGHT_KEYS.accountUpdate]: [MODULES_KEYS.payments],
  [RIGHT_KEYS.paymentsShow]: [MODULES_KEYS.payments],
  [RIGHT_KEYS.paymentsCancel]: [MODULES_KEYS.payments],
  [RIGHT_KEYS.tariffShow]: [MODULES_KEYS.payments],
  [RIGHT_KEYS.tariffUpdate]: [MODULES_KEYS.payments],
  [RIGHT_KEYS.passShow]: [MODULES_KEYS.payments],
  [RIGHT_KEYS.passCancel]: [MODULES_KEYS.payments],
  [RIGHT_KEYS.passUpdate]: [MODULES_KEYS.payments],
  [RIGHT_KEYS.passManualPayment]: [MODULES_KEYS.payments],
  [RIGHT_KEYS.passTypeShow]: [MODULES_KEYS.payments],
  [RIGHT_KEYS.passTypeUpdate]: [MODULES_KEYS.payments],
};

export const LICENSE_TARIFF_TYPES = {
  base: 0,
  standard: 1,
  advanced: 2,
};

export const LICENSE_TARIFF_TYPES_MAP = {
  [LICENSE_TARIFF_TYPES.base]: 'Базовый',
  [LICENSE_TARIFF_TYPES.standard]: 'Стандартный',
  [LICENSE_TARIFF_TYPES.advanced]: 'Продвинутый',
};

export const PLACE_STATUSES = {
  free: 0,
  reserved: 1,
};

export const PLACE_PURPOSES = {
  unavailable: 0,
  reactive: 1,
  pass: 2,
};

export const CONTROLLER_TYPES = {
  gsm: 'ParklyGSM',
  cameConnect: 'Came',
  bewardCamera: 'BewardCamera',
  laurent: 'Laurent',
  ledMatrix: 'LedMatrix',
  zktecoCamera: 'ZKTeco',
};

export const GATE_TYPES = {
  barrier: 'barrier',
  gate: 'gate',
  door: 'door',
};

export const GATE_TYPE_LABELS = {
  barrier: 'Шлагбаум',
  gate: 'Ворота',
  door: 'Дверь',
};

export const GATE_DIRECTIONS = {
  enter: 'enter',
  exit: 'exit',
  both: 'both',
};

export const GATE_DIRECTIONS_LABEL = {
  enter: 'Въезд',
  exit: 'Выезд',
  both: 'Въезд/Выезд',
  door_enter: 'Вход',
  door_exit: 'Выход',
  door_both: 'Вход/Выход',
};

export const DEVICE_STATUS = {
  active: 'active',
  disabled: 'disabled',
  not_connected: 'not_connected',
  broken: 'broken',
};

export const SESSION_STATUSES = {
  active: 0,
  finished: 1,
  await_finish: 11,
  stopped: 2,
  canceled: 3,
  auto_finish: 4,
};

export const SESSION_STATUSES_MAP = {
  [SESSION_STATUSES.active]: 'Активная',
  [SESSION_STATUSES.finished]: 'Завершена',
  [SESSION_STATUSES.await_finish]: 'Ожидает завершения',
  [SESSION_STATUSES.stopped]: 'Остановлена',
  [SESSION_STATUSES.canceled]: 'Отменена',
};

export const TARIFF_STATUS = {
  draft: 0,
  active: 1,
  archive: 2,
};

export const TARIFF_TYPE = {
  standard: 0,
  interval: 1,
  cumulative: 2,
};

export const TARIFF_INTERVALS = {
  min: 1,
  hour: 60,
  day: 1440,
  month: 43200,
};

export const TARIFF_INTERVAL_MAP = {
  [TARIFF_INTERVALS.min]: 'мин',
  [TARIFF_INTERVALS.hour]: 'час',
  [TARIFF_INTERVALS.day]: 'сут',
  [TARIFF_INTERVALS.month]: 'мес',
};

export const TARIFF_PLAN_STATUS = {
  draft: 0,
  active: 1,
  archive: 2,
};

export const TARIFF_PLAN_TYPES = {
  detail: 0,
  week: 1,
  month: 2,
};

export const TARIFF_PLAN_CHANGE_TYPES = {
  new: 0,
  old: 1,
  combo: 2,
  auto: 3,
};

export const ACCESS_TOKEN_TYPES = {
  phone: 0,
  plateNumber: 1,
  rfid: 2,
};

export const PASSES_SETTINGS_STATUS = {
  draft: 0,
  active: 1,
  archive: 2,
};

export const PASSES_TYPES = {
  active: 'active',
  overdue: 'overdue',
  old_overdue: 'old_overdue',
  archive: 'archive',
};

export const PASSES_STATUSES = {
  draft: 0,
  awaitingPayment: 1,
  active: 2,
  canceled: 3,
  finished: 4,
};

export const PASSES_STATUSES_MAP = {
  [PASSES_STATUSES.draft]: 'Черновик',
  [PASSES_STATUSES.awaitingPayment]: 'Ожидает оплаты',
  [PASSES_STATUSES.active]: 'Активный',
  [PASSES_STATUSES.canceled]: 'Отменен',
  [PASSES_STATUSES.finished]: 'Завершен',
};

export const PASSES_STATUSES_COLOR_MAP = {
  [PASSES_STATUSES.draft]: 'grey',
  [PASSES_STATUSES.awaitingPayment]: 'yellow',
  [PASSES_STATUSES.active]: '#43D37D',
  [PASSES_STATUSES.canceled]: 'red',
  [PASSES_STATUSES.finished]: 'grey',
};

export const ACCESS_TOKEN_TYPES_MAP = {
  [ACCESS_TOKEN_TYPES.phone]: 'Номер телефона',
  [ACCESS_TOKEN_TYPES.plateNumber]: 'Госномер',
  [ACCESS_TOKEN_TYPES.rfid]: 'RFID',
};

export const ACCOUNT_OPERATIONS_TYPES = {
  deposit: 1,
  withdraw: 2,
  charge: 3,
  addition: 4,
};

export const ACCOUNT_OPERATIONS_TYPES_MAP = {
  [ACCOUNT_OPERATIONS_TYPES.deposit]: 'Пополнение счета',
  [ACCOUNT_OPERATIONS_TYPES.withdraw]: 'Вывод со счета',
  [ACCOUNT_OPERATIONS_TYPES.charge]: 'Оплата',
  [ACCOUNT_OPERATIONS_TYPES.addition]: 'Зачисление',
};

export const ACCOUNT_OPERATIONS_STATUS = {
  new: 1,
  inProgress: 2,
  canceled: 3,
  success: 4,
  error: 5,
};

export const ACCOUNT_OPERATIONS_STATUS_MAP = {
  [ACCOUNT_OPERATIONS_STATUS.new]: 'Новая',
  [ACCOUNT_OPERATIONS_STATUS.inProgress]: 'В обработке',
  [ACCOUNT_OPERATIONS_STATUS.canceled]: 'Отменена',
  [ACCOUNT_OPERATIONS_STATUS.success]: 'Завершена',
  [ACCOUNT_OPERATIONS_STATUS.error]: 'Ошибка',
};

//    public const STATUS_NEW = 1;
//     public const STATUS_INVOICED = 2;
//     public const STATUS_REQUIRED3DS = 21;
//     public const STATUS_AUTHORIZED = 3;
//     public const STATUS_PAID = 4;
//     public const STATUS_CANCELED = 5;
//     public const STATUS_REFUNDED = 6;
//     public const STATUS_FAILED = 7;
//     public const STATUS_DELETED = 8;
//     public const STATUS_EXPIRED = 9;

export const PAYMENTS_STATUSES = {
  new: 1,
  invoiced: 2,
  required3ds: 21,
  authorized: 3,
  paid: 4,
  canceled: 5,
  refunded: 6,
  failed: 7,
  deleted: 8,
  expired: 9,
};

export const PAYMENTS_STATUSES_MAP = {
  [PAYMENTS_STATUSES.new]: 'Новый',
  [PAYMENTS_STATUSES.invoiced]: 'Выставлен',
  [PAYMENTS_STATUSES.required3ds]: 'Требуется 3DS',
  [PAYMENTS_STATUSES.authorized]: 'Авторизован',
  [PAYMENTS_STATUSES.paid]: 'Оплачен',
  [PAYMENTS_STATUSES.canceled]: 'Отменен',
  [PAYMENTS_STATUSES.refunded]: 'Возвращен',
  [PAYMENTS_STATUSES.failed]: 'Ошибка',
  [PAYMENTS_STATUSES.deleted]: 'Удален',
  [PAYMENTS_STATUSES.expired]: 'Просрочен',
};

export const PAYMENT_ORDERS_STATUSES = {
  new: 0,
  invoiced: 10,
  paid: 20,
  done: 30,
  failed: 40,
  canceled: 50,
};

export const PAYMENT_ORDERS_STATUSES_MAP = {
  [PAYMENT_ORDERS_STATUSES.new]: 'Новый',
  [PAYMENT_ORDERS_STATUSES.invoiced]: 'Выставлен',
  [PAYMENT_ORDERS_STATUSES.paid]: 'Оплачен',
  [PAYMENT_ORDERS_STATUSES.done]: 'Завершен',
  [PAYMENT_ORDERS_STATUSES.failed]: 'Неуспешен',
  [PAYMENT_ORDERS_STATUSES.canceled]: 'Отменен',
};

export const SITE_THEMES = {
  default: 'default',
  default_orange: 'default_orange',
  light: 'light',
};

export const ANALYTIC_TYPES = {
  revenue: 'revenue',
  avgCheck: 'avg-check',
  sessions: 'sessions',
  avgSessionDuration: 'avg-session-duration',
};

export const UNITS = {
  hour: 'h',
  day: 'd',
  week: 'w',
  month: 'm',
};

export const UNITS_PLURAL_MAP = {
  [UNITS.hour]: ['час', 'часа', 'часов'],
  [UNITS.day]: ['день', 'дня', 'дней'],
  [UNITS.week]: ['неделя', 'недели', 'недель'],
  [UNITS.month]: ['месяц', 'месяца', 'месяцев'],
};

export const UNITS_CAPITALIZE_PLURAL_MAP = {
  [UNITS.hour]: ['Час', 'Часа', 'Часов'],
  [UNITS.day]: ['День', 'Дня', 'Дней'],
  [UNITS.week]: ['Неделя', 'Недели', 'Недель'],
  [UNITS.month]: ['Месяц', 'Месяца', 'Месяцев'],
};

export const TICKET_HOLDER_TYPES = {
  car: 1,
  pedesterian: 2,
};

export const TICKET_HOLDER_TYPES_MAP = {
  [TICKET_HOLDER_TYPES.car]: 'Автомобиль',
  [TICKET_HOLDER_TYPES.pedesterian]: 'Пешеход',
};

export const PASS_REQ_TYPES = {
  phone: 'phone',
  fio: 'fio',
  idNumber: 'idNumber',
  stsNumber: 'stsNumber',
  carModel: 'carModel',
  plateNumber: 'plateNumber',
  additionalPhone: 'additionalPhone',
  additionalPlateNumber: 'additionalPlateNumber',
};

export const PASS_REQ_TYPES_MAP = {
  [PASS_REQ_TYPES.phone]: 'Номер телефона',
  [PASS_REQ_TYPES.fio]: 'ФИО',
  [PASS_REQ_TYPES.idNumber]: 'Номер паспорта',
  [PASS_REQ_TYPES.stsNumber]: 'Номер СТС',
  [PASS_REQ_TYPES.carModel]: 'Марка и модель авто',
  [PASS_REQ_TYPES.plateNumber]: 'Госномер',
  [PASS_REQ_TYPES.additionalPhone]: 'Доп. номер телефона',
  [PASS_REQ_TYPES.additionalPlateNumber]: 'Доп. госномер',
};

export const GATEWAY_EVENTS_STATUSES = {
  inProcess: 1,
  success: 2,
  failNoExtension: 3,
  failDeviceError: 4,
  failNoToken: 5,
  failTokenNotActive: 6,
  failBlacklisted: 7,
  failPortLocked: 8,
  failPortConditionLock: 9,
  // failAccessDenied: 10, // не используется
  failNeedPayment: 11,
  failFacilitySchedule: 12,
  failGateHasNoController: 13,
  failWrongGate: 14,
  failWrongState: 15,
  failNoFreePlaces: 16,
  failNoDraftSession: 17,
  failSessionFlowLocked: 18,
  failTokenFlood: 19,
  failSessionNoTariffPlan: 20,
  failNoAwaitFinishSession: 21,
  failIsNotMobileNumber: 22,
  failNoActiveSession: 23,
  failUnknownError: 99,
};

export const FAIL_GATEWAY_EVENTS_STATUSES = [
  GATEWAY_EVENTS_STATUSES.failNoExtension,
  GATEWAY_EVENTS_STATUSES.failDeviceError,
  GATEWAY_EVENTS_STATUSES.failNoToken,
  GATEWAY_EVENTS_STATUSES.failTokenNotActive,
  GATEWAY_EVENTS_STATUSES.failBlacklisted,
  GATEWAY_EVENTS_STATUSES.failPortLocked,
  GATEWAY_EVENTS_STATUSES.failPortConditionLock,
  // GATEWAY_EVENTS_STATUSES.failAccessDenied, // не используется
  GATEWAY_EVENTS_STATUSES.failNeedPayment,
  GATEWAY_EVENTS_STATUSES.failFacilitySchedule,
  GATEWAY_EVENTS_STATUSES.failGateHasNoController,
  GATEWAY_EVENTS_STATUSES.failWrongGate,
  GATEWAY_EVENTS_STATUSES.failWrongState,
  GATEWAY_EVENTS_STATUSES.failNoFreePlaces,
  GATEWAY_EVENTS_STATUSES.failNoDraftSession,
  GATEWAY_EVENTS_STATUSES.failNoAwaitFinishSession,
  GATEWAY_EVENTS_STATUSES.failSessionFlowLocked,
  GATEWAY_EVENTS_STATUSES.failTokenFlood,
  GATEWAY_EVENTS_STATUSES.failSessionNoTariffPlan,
  GATEWAY_EVENTS_STATUSES.failIsNotMobileNumber,
  GATEWAY_EVENTS_STATUSES.failNoActiveSession,
  GATEWAY_EVENTS_STATUSES.failUnknownError,
];

export const MAP_GATEWAY_EVENTS_STATUS_COLOR = {
  [GATEWAY_EVENTS_STATUSES.inProcess]: 'blue',
  [GATEWAY_EVENTS_STATUSES.success]: '#43D37D',
  [GATEWAY_EVENTS_STATUSES.failNoExtension]: 'red',
  [GATEWAY_EVENTS_STATUSES.failDeviceError]: 'red',
  [GATEWAY_EVENTS_STATUSES.failNoToken]: 'red',
  [GATEWAY_EVENTS_STATUSES.failTokenNotActive]: 'red',
  [GATEWAY_EVENTS_STATUSES.failBlacklisted]: 'red',
  [GATEWAY_EVENTS_STATUSES.failPortLocked]: 'red',
  [GATEWAY_EVENTS_STATUSES.failPortConditionLock]: 'red',
  // [GATEWAY_EVENTS_STATUSES.failAccessDenied]: 'red', // не используется
  [GATEWAY_EVENTS_STATUSES.failNeedPayment]: 'red',
  [GATEWAY_EVENTS_STATUSES.failFacilitySchedule]: 'red',
  [GATEWAY_EVENTS_STATUSES.failGateHasNoController]: 'red',
  [GATEWAY_EVENTS_STATUSES.failWrongGate]: 'red',
  [GATEWAY_EVENTS_STATUSES.failWrongState]: 'red',
  [GATEWAY_EVENTS_STATUSES.failNoFreePlaces]: 'red',
  [GATEWAY_EVENTS_STATUSES.failNoDraftSession]: 'red',
  [GATEWAY_EVENTS_STATUSES.failNoAwaitFinishSession]: 'red',
  [GATEWAY_EVENTS_STATUSES.failSessionFlowLocked]: 'red',
  [GATEWAY_EVENTS_STATUSES.failTokenFlood]: 'red',
  [GATEWAY_EVENTS_STATUSES.failSessionNoTariffPlan]: 'red',
  [GATEWAY_EVENTS_STATUSES.failIsNotMobileNumber]: 'red',
  [GATEWAY_EVENTS_STATUSES.failNoActiveSession]: 'red',
  [GATEWAY_EVENTS_STATUSES.failUnknownError]: 'red',
};

export const MAP_GATEWAY_EVENTS_STATUS_NAME = {
  [GATEWAY_EVENTS_STATUSES.inProcess]: 'В обработке',
  [GATEWAY_EVENTS_STATUSES.success]: 'Успешно',
  [GATEWAY_EVENTS_STATUSES.failNoExtension]: 'Нет добавочного номера',
  [GATEWAY_EVENTS_STATUSES.failDeviceError]: 'Ошибка устройства (контроллера)',
  [GATEWAY_EVENTS_STATUSES.failNoToken]: 'Нет доступа',
  [GATEWAY_EVENTS_STATUSES.failTokenNotActive]: 'Доступ не активирован',
  [GATEWAY_EVENTS_STATUSES.failBlacklisted]: 'В черном списке',
  [GATEWAY_EVENTS_STATUSES.failPortLocked]: 'Проезд заблокирован другим гостем',
  [GATEWAY_EVENTS_STATUSES.failPortConditionLock]: 'Условия для проезда не выполнены',
  // [GATEWAY_EVENTS_STATUSES.failAccessDenied]: 'Доступ запрещен', // не используется
  [GATEWAY_EVENTS_STATUSES.failNeedPayment]: 'Необходима оплата',
  [GATEWAY_EVENTS_STATUSES.failFacilitySchedule]: 'Парковка закрыта',
  [GATEWAY_EVENTS_STATUSES.failGateHasNoController]: 'Барьер не имеет контроллеров',
  [GATEWAY_EVENTS_STATUSES.failWrongGate]: 'Уже есть активная сессия',
  [GATEWAY_EVENTS_STATUSES.failWrongState]: 'Некорректное состояние сессии',
  [GATEWAY_EVENTS_STATUSES.failNoFreePlaces]: 'Нет свободных мест',
  [GATEWAY_EVENTS_STATUSES.failNoDraftSession]: 'Нет сессии для подтверждения въезда',
  [GATEWAY_EVENTS_STATUSES.failNoAwaitFinishSession]: 'Нет сессии для подтверждения выезда',
  [GATEWAY_EVENTS_STATUSES.failSessionFlowLocked]: 'Предыдущий проезд еще не завершен',
  [GATEWAY_EVENTS_STATUSES.failTokenFlood]: 'Повторное использование токена',
  [GATEWAY_EVENTS_STATUSES.failSessionNoTariffPlan]: 'Не задан тарифный план для сессии',
  [GATEWAY_EVENTS_STATUSES.failIsNotMobileNumber]: 'Звонок не с мобильного номера',
  [GATEWAY_EVENTS_STATUSES.failNoActiveSession]: 'Выезд без активной сессии',
  [GATEWAY_EVENTS_STATUSES.failUnknownError]: 'Неизвестная ошибка',
};

export const GATEWAY_EVENTS_TYPES = {
  manual: 1,
  manualNoCustomer: 2,
  callAppeared: 3,
  callDtmf: 4,
  camera: 5,
  initDraft: 6,
  attachToken: 7,
  parkly: 9,
};

export const DEFAULT_GATEWAY_EVENTS_TYPES_FILTERS = [
  [GATEWAY_EVENTS_TYPES.manual, GATEWAY_EVENTS_TYPES.manualNoCustomer].join(','),
  [GATEWAY_EVENTS_TYPES.callAppeared, GATEWAY_EVENTS_TYPES.callDtmf].join(','),
  `${GATEWAY_EVENTS_TYPES.camera}`,
  `${GATEWAY_EVENTS_TYPES.parkly}`,
];

export const MAP_GATEWAY_EVENTS_TYPES_NAME = {
  [GATEWAY_EVENTS_TYPES.manual]: 'Оператор Р360',
  [GATEWAY_EVENTS_TYPES.manualNoCustomer]: 'Оператор Р360',
  [GATEWAY_EVENTS_TYPES.callAppeared]: 'Звонок',
  [GATEWAY_EVENTS_TYPES.callDtmf]: 'Звонок',
  [GATEWAY_EVENTS_TYPES.camera]: 'Распознавание госномера',
  [GATEWAY_EVENTS_TYPES.initDraft]: 'Подтверждение проезда',
  [GATEWAY_EVENTS_TYPES.attachToken]: 'Дополнительный доступ',
  [GATEWAY_EVENTS_TYPES.parkly]: 'Паркли',
};

export const ANALYTICS_MODES = {
  hour: 'hour',
  day: 'day',
  week: 'week',
  month: 'month',
};

export default {
  PATH_PAGES,
};

export const DEFAULT_RECEIPT_PHONE = '74954774704';
