import React from 'react';

import Container from '@material-ui/core/Container';

import Tickets from 'components/organisms/Tickets';
import HeaderWithNavigationLinks from 'components/templates/HeaderWithNavigationLinks';

import { useCheckFacilityRights, useCommonRights, useHasModule } from 'helpers/hooks';
import { useCurrentFacilityMatch } from 'helpers/routerHooks';

import { MODULES_KEYS, PATH_PAGES, RIGHT_KEYS } from 'config/constants';

import { useStyles } from './styles';

function AllTicketsPage({ match }) {
  const styles = useStyles();
  useCurrentFacilityMatch();

  const { params: matchParams } = match || {};
  const { facilityId: currentFacilityId } = matchParams || {};

  const hasCustomerShowRight = useCheckFacilityRights(RIGHT_KEYS.customerShow, currentFacilityId);
  const hasPassShowRight = useCheckFacilityRights(RIGHT_KEYS.passShow, currentFacilityId);
  const hasBlackListShowRight = useCommonRights(RIGHT_KEYS.blackListShow);

  const hasPaymentModule = useHasModule(currentFacilityId, MODULES_KEYS.payments);

  return (
    <Container className={styles.container}>
      <HeaderWithNavigationLinks
        links={[
          hasCustomerShowRight
            ? { to: PATH_PAGES.customers.replace(':facilityId', currentFacilityId), label: 'Клиенты' }
            : null,
          { to: PATH_PAGES.tickets.replace(':facilityId', currentFacilityId), label: 'Пропуска' },
          hasPassShowRight && hasPaymentModule
            ? { to: PATH_PAGES.passes.replace(':facilityId', currentFacilityId), label: 'Абонементы' }
            : null,
          hasBlackListShowRight ? { to: PATH_PAGES.blackList, label: 'Черный список' } : null,
        ]}
      />
      <div className={styles.pageBody}>
        <Tickets facilityId={currentFacilityId} showControls showPagination />
      </div>
    </Container>
  );
}

export default AllTicketsPage;
