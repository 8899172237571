import React from 'react';
import { Tooltip } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { ACCESS_TOKEN_TYPES } from 'config/constants';
import CameraAltTwoToneIcon from '@material-ui/icons/CameraAltTwoTone';
import WarningTwoToneIcon from '@material-ui/icons/WarningTwoTone';
import { formatPhoneNumberInput } from '@parkly/shared/helpers';
import SessionAccessTokensInaccurate from 'components/molecules/SessionAccessTokensInaccurate';
import isEmpty from 'lodash/isEmpty';

function formatToken({ type, token }) {
  switch (type) {
    case ACCESS_TOKEN_TYPES.phone:
      return formatPhoneNumberInput(token);
    case ACCESS_TOKEN_TYPES.rfid:
    case ACCESS_TOKEN_TYPES.plateNumber:
    default:
      return token.replaceAll(' ', ' ');
  }
}

export default function SessionAccessToken({
  tokens,
  isFinish = false,
  hideEdit = false,
  hidePhotos = false,
  hasAddPhone = false,
  isFreeExit = false,
  onImageClick = () => {},
  sessionId,
  facilityId,
}) {
  if (isEmpty(tokens)) {
    return <Typography></Typography>;
  }

  const iconComponent = isFinish
    ? <ArrowBackIcon htmlColor="red" />
    : <ArrowForwardIcon htmlColor="#43D37D" />;

  const handleClick = (id) => {
    onImageClick(id);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
        gap: 10,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {(tokens || []).map((token) => (
          <div
            key={token.gatewayEventId}
            style={{
              display: 'flex',
              height: 28,
              alignItems: 'center',
            }}
          >
            {iconComponent}
            {isFreeExit ? (
              <Tooltip title="Свободный выезд">
                <Typography>
                  {formatToken(token)}
                </Typography>
            </Tooltip>
            ) : (
              <Typography>
                {formatToken(token)}
              </Typography>
            )}
            {token.type === ACCESS_TOKEN_TYPES.plateNumber && token.isInaccurate && (
              <Tooltip title="Возможно госномер разпознался с ошибкой">
                <WarningTwoToneIcon htmlColor="orange" />
              </Tooltip>
            )}
            {!hidePhotos && token.photo && (
              <IconButton size="small" onClick={() => handleClick(token.photo.url)}>
                <CameraAltTwoToneIcon />
              </IconButton>
            )}
          </div>
        ))}
      </div>
      {!isFinish && !hideEdit && (
        <SessionAccessTokensInaccurate
          facilityId={facilityId}
          sessionId={sessionId}
          hasAddPhone={hasAddPhone}
        />
      )}
    </div>
  );
}
