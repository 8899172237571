import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    marginLeft: 0,
    height: '50vh',
  },
  bewardSettings: {
    minWidth: 500,
  },
  zktecoSettings: {
    minWidth: 500,
  },
  inputItem: {
    marginTop: 0,
    marginBottom: 0,
  },
  inputPasswordItem: {
    marginTop: 0,
    marginBottom: 0,
    width: '100%',
  },
  checkbox: {
    marginTop: 0,
    marginBottom: 0,
  },
  checkboxError: {
    color: 'red',
  },
  btn: {
    width: '100%',
    height: 48,
  },
  subTitle: {
    fontSize: 16,
    fontWeight: 700,
  },
  selectorsForm: {
    height: 48,
  },
  selectorsLabel: {
    marginLeft: 10,
    transform: 'translate(0, 16px) scale(1)',
    '&.MuiInputLabel-shrink': {
      marginLeft: 0,
    },
  },
  gridContainer: {
    margin: 0,
    overflowX: 'auto',
  },
  itemContainer: {
    minHeight: 'unset !important',
    paddingTop: '16px !important',
    paddingBottom: '16px !important',
  },
  itemGridTitle: {
    minHeight: 'unset !important',
    paddingTop: '8px !important',
    paddingBottom: '8px !important',
  },
  orderedList: {
    '& ol': {
      padding: 0,
      paddingLeft: 16,
      counterReset: 'item',
    },
    '& ol > li': {
      counterIncrement: 'item',
    },
    '& ol ol > li': {
      display: 'block',
    },
    '& ol ol > li:before': {
      content: 'counters(item, ".") ". "',
    },
    '& li span': {
      fontWeight: 700,
    },
  },
  paperModal: {
    width: 600,
  },
}));

export default {
  useStyles,
};
