import { updateStoreReqRes } from '@parkly/shared/helpers';
import {
  CLEAR_FACILITIES_CACHE,
  CREATE_FACILITY,
  DELETE_FACILITY_GATE,
  DELETE_FACILITY_INTEGRATION_FLOW,
  GET_FACILITIES,
  GET_FACILITY,
  GET_FACILITY_AREAS,
  GET_FACILITY_GATES,
  GET_FACILITY_GATES_WITH_CONTROLLERS,
  GET_FACILITY_INTEGRATION_FLOW,
  GET_FACILITY_INTEGRATION_INFO,
  GET_FACILITY_PASS_PLACES,
  GET_FACILITY_PLACES,
  GET_FACILITY_SCHEDULE,
  GET_FACILITY_SITE_SETTING,
  GET_FREE_FACILITY_PLACES,
  OPEN_FACILITY_GATE,
  SET_FACILITY_GATE,
  SET_FACILITY_INTEGRATION_FLOW,
  SET_FACILITY_PLACES,
  SET_FACILITY_SCHEDULE,
  SET_FACILITY_SITE_SETTING,
  UPDATE_FACILITY,
} from 'actions/types';

const INITIAL_STATE = {
  allFacilities: {
    loading: false,
    data: null,
  },
  oneFacility: {
    loading: false,
    list: [],
  },
  createFacility: {
    loading: false,
  },
  updateFacility: {
    loading: false,
  },
  oneFacilitySchedule: {
    loading: false,
    list: [],
  },
  setFacilitySchedule: {
    loading: false,
  },
  oneFacilityPlaces: {
    loading: false,
    list: [],
  },
  oneFacilityAreas: {
    loading: false,
    list: [],
  },
  oneFacilityPassPlaces: {
    loading: false,
    list: [],
  },
  allFacilityFreePlaces: {
    loading: false,
    list: [],
  },
  setFacilityPlaces: {
    loading: false,
  },
  allFacilityGates: {
    loading: false,
    list: [],
  },
  allFacilityGatesWithControllers: {
    loading: false,
    list: [],
  },
  setFacilityGate: {
    loading: false,
  },
  deleteFacilityGate: {
    loading: false,
  },
  openGate: {
    loading: false,
  },
  allFacilityIntegrationFlows: {
    loading: false,
    list: [],
  },
  setFacilityIntegrationFlow: {
    loading: false,
  },
  allFacilityIntegrationInfo: {
    loading: false,
    list: [],
  },
  deleteFacilityIntegrationFlow: {
    loading: false,
  },
  oneFacilitySiteSetting: {
    loading: false,
    list: [],
  },
  setFacilitySiteSetting: {
    loading: false,
  },
};

function updateOneFacilityList({
  list,
  newFacility,
  error,
}) {
  const { id } = newFacility || error || {};

  if (!id) {
    return list;
  }

  const filteredList = list
    .slice(0, 5)
    .filter((item = {}) => {
      const currentId = (item || {}).id;
      const isFilter = currentId !== id;

      return isFilter;
    });

  const newList = [
    ...filteredList,
    newFacility || error,
  ];

  return newList;
}

function updateOneFacilityScheduleList({
  list,
  newSchedule,
  error,
}) {
  const { id } = newSchedule || error || {};

  if (!id) {
    return list;
  }

  const filteredList = list
    .slice(0, 5)
    .filter((item = {}) => {
      const currentId = (item || {}).id;
      const isFilter = currentId !== id;

      return isFilter;
    });

  const newList = [
    ...filteredList,
    newSchedule || error,
  ];

  return newList;
}

function updateOneFacilitySiteSettingList({
  list,
  newSiteSetting,
  error,
}) {
  const { id } = newSiteSetting || error || {};

  if (!id) {
    return list;
  }

  const filteredList = list
    .slice(0, 5)
    .filter((item = {}) => {
      const currentId = (item || {}).id;
      const isFilter = currentId !== id;

      return isFilter;
    });

  const newList = [
    ...filteredList,
    newSiteSetting || error,
  ];

  return newList;
}

function updateOneFacilityPlacesList({
  list,
  newPlace,
  error,
}) {
  const { id } = newPlace || error || {};

  if (!id) {
    return list;
  }

  const filteredList = list
    .slice(0, 5)
    .filter((item = {}) => {
      const currentId = (item || {}).id;
      const isFilter = currentId !== id;

      return isFilter;
    });

  const newList = [
    ...filteredList,
    newPlace || error,
  ];

  return newList;
}

function updateAllFacilityGatesList({
  list,
  newGates,
  error,
}) {
  const { facilityId } = newGates || error || {};

  if (!facilityId) {
    return list;
  }

  const filteredList = list
    .slice(0, 5)
    .filter((item = {}) => {
      const currentId = (item || {}).facilityId;
      const isFilter = currentId !== facilityId;

      return isFilter;
    });

  const newList = [
    ...filteredList,
    newGates || error,
  ];

  return newList;
}

function getNewFacilityState({
  prevState,
  payload,
}) {
  return {
    loading: (prevState || {}).loading,
    ...payload,
  };
}

function getUpdateFacilityState({
  prevState,
  payload,
}) {
  return {
    loading: (prevState || {}).loading,
    ...payload,
  };
}

function getSetFacilityState({
  prevState,
  payload,
}) {
  return {
    loading: (prevState || {}).loading,
    ...payload,
  };
}

export default function facilities(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_FACILITIES:
      return updateStoreReqRes(
        state,
        'allFacilities',
        action,
      );

    case GET_FACILITY:
      return {
        ...state,
        oneFacility: {
          ...state.oneFacility,
          loading: action.payload.loading,
          list: updateOneFacilityList({
            list: state.oneFacility.list,
            newFacility: action.payload.facility,
            error: action.payload.error,
          }),
        },
      };

    case CREATE_FACILITY:
      return {
        ...state,
        createFacility: getNewFacilityState({
          prevState: state.createFacility,
          payload: action.payload,
        }),
      };

    case UPDATE_FACILITY:
      return {
        ...state,
        updateFacility: getUpdateFacilityState({
          prevState: state.updateFacility,
          payload: action.payload,
        }),
      };

    case GET_FACILITY_SCHEDULE:
      return {
        ...state,
        oneFacilitySchedule: {
          ...state.oneFacilitySchedule,
          loading: action.payload.loading,
          list: updateOneFacilityScheduleList({
            list: state.oneFacilitySchedule.list,
            newSchedule: action.payload.schedule,
            error: action.payload.error,
          }),
        },
      };

    case SET_FACILITY_SCHEDULE:
      return {
        ...state,
        setFacilitySchedule: getSetFacilityState({
          prevState: state.setFacilitySchedule,
          payload: action.payload,
        }),
      };

    case GET_FACILITY_PLACES:
      return {
        ...state,
        oneFacilityPlaces: {
          ...state.oneFacilityPlaces,
          loading: action.payload.loading,
          list: updateOneFacilityPlacesList({
            list: state.oneFacilityPlaces.list,
            newPlace: action.payload.places,
            error: action.payload.error,
          }),
        },
      };

    case GET_FACILITY_PASS_PLACES:
      return {
        ...state,
        oneFacilityPassPlaces: {
          ...state.oneFacilityPassPlaces,
          loading: action.payload.loading,
          list: updateOneFacilityPlacesList({
            list: state.oneFacilityPassPlaces.list,
            newPlace: action.payload.places,
            error: action.payload.error,
          }),
        },
      };

    case SET_FACILITY_PLACES:
      return {
        ...state,
        setFacilityPlaces: getSetFacilityState({
          prevState: state.setFacilityPlaces,
          payload: action.payload,
        }),
      };

    case GET_FACILITY_AREAS:
      return updateStoreReqRes(
        state,
        'oneFacilityAreas',
        action,
      );

    case GET_FREE_FACILITY_PLACES:
      return updateStoreReqRes(
        state,
        'allFacilityFreePlaces',
        action,
      );

    case GET_FACILITY_GATES:
      return updateStoreReqRes(
        state,
        'allFacilityGates',
        action,
      );

    case GET_FACILITY_GATES_WITH_CONTROLLERS:
      return updateStoreReqRes(
        state,
        'allFacilityGatesWithControllers',
        action,
      );

    case SET_FACILITY_GATE:
      return {
        ...state,
        setFacilityGate: getSetFacilityState({
          prevState: state.setFacilityGate,
          payload: action.payload,
        }),
      };

    case DELETE_FACILITY_GATE:
      return {
        ...state,
        deleteFacilityGate: getSetFacilityState({
          prevState: state.deleteFacilityGate,
          payload: action.payload,
        }),
      };

    case OPEN_FACILITY_GATE:
      return {
        ...state,
        openGate: getSetFacilityState({
          prevState: state.openGate,
          payload: action.payload,
        }),
      };

    case GET_FACILITY_INTEGRATION_FLOW:
      return updateStoreReqRes(
        state,
        'allFacilityIntegrationFlows',
        action,
      );

    case GET_FACILITY_INTEGRATION_INFO:
      return updateStoreReqRes(
        state,
        'allFacilityIntegrationInfo',
        action,
      );

    case SET_FACILITY_INTEGRATION_FLOW:
      return {
        ...state,
        setFacilityIntegrationFlow: getSetFacilityState({
          prevState: state.setFacilityIntegrationFlow,
          payload: action.payload,
        }),
      };

    case DELETE_FACILITY_INTEGRATION_FLOW:
      return {
        ...state,
        deleteFacilityIntegrationFlow: getSetFacilityState({
          prevState: state.deleteFacilityIntegrationFlow,
          payload: action.payload,
        }),
      };

    case GET_FACILITY_SITE_SETTING:
      return {
        ...state,
        oneFacilitySiteSetting: {
          ...state.oneFacilitySiteSetting,
          loading: action.payload.loading,
          list: updateOneFacilitySiteSettingList({
            list: state.oneFacilitySiteSetting.list,
            newSiteSetting: action.payload.settings,
            error: action.payload.error,
          }),
        },
      };

    case SET_FACILITY_SITE_SETTING:
      return {
        ...state,
        setFacilitySiteSetting: getSetFacilityState({
          prevState: state.setFacilitySiteSetting,
          payload: action.payload,
        }),
      };

    case CLEAR_FACILITIES_CACHE:
      return {
        ...state,
        allFacilities: INITIAL_STATE.allFacilities,
        oneFacility: INITIAL_STATE.oneFacility,
        createFacility: INITIAL_STATE.createFacility,
        updateFacility: INITIAL_STATE.updateFacility,
      };

    default:
      return state;
  }
}
