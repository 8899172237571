import React, { useEffect } from 'react';
import moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import { CircularProgress, Grid } from '@material-ui/core';
import HeaderWithBackBtn from '@parkly/shared/components/molecules/HeaderWithBackBtn';
import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';
import {
  cleanPhone,
  formatPhoneNumber,
  formatPhoneNumberInput,
  getApiErrorMsg,
  toNativeTimeFormat,
  useFormFields,
  useModal,
} from '@parkly/shared/helpers';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import CustomLink from '@parkly/shared/components/atoms/CustomLink';
import ItemSelectors from '@parkly/shared/components/molecules/ItemSelectors';
import ManualPayPassModalContent from 'components/organisms/ManualPayPassModalContent';
import RenewPassModalContent from 'components/organisms/RenewPassModalContent';
import Typography from '@material-ui/core/Typography';
import CalendarIcon from '@parkly/shared/components/atoms/icons/CalendarIcon';
import DateTimeInput from '@parkly/shared/components/atoms/DateTimeInput';
import PassPlaceSelect from 'components/organisms/PassPlaceSelect';
import clone from 'lodash/clone';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {
  PASS_REQ_TYPES,
  PASS_REQ_TYPES_MAP,
  PASSES_STATUSES,
  PASSES_STATUSES_MAP,
  PATH_PAGES,
  RIGHT_KEYS,
} from 'config/constants';
import {
  cancelPassAction,
  clearPassesCacheAction,
  getActivePassSettingsAction,
  getOnePassAction,
  payPassAction,
  updatePassesAction,
} from 'actions/passes';
import { useCurrentFacilityMatch } from 'helpers/routerHooks';
import { useCheckFacilityRights } from 'helpers/hooks';
import CancelPassModalContent from 'components/organisms/CancelPassModalContent';
import { MAX_PHONE_NUMBER_LENGTH } from '@parkly/shared/config/constants';
import { useStyles } from './styles';
import FastForwardIcon from '@material-ui/icons/FastForward';
import PaymentIcon from '@material-ui/icons/Payment';
import CancelIcon from '@material-ui/icons/Cancel';


const PASSES_STATUSES_ITEMS = [
  { id: PASSES_STATUSES.draft, title: PASSES_STATUSES_MAP[PASSES_STATUSES.draft] },
  { id: PASSES_STATUSES.awaitingPayment, title: PASSES_STATUSES_MAP[PASSES_STATUSES.awaitingPayment] },
  // { id: PASSES_STATUSES.active, title: PASSES_STATUSES_MAP[PASSES_STATUSES.active] },
  // { id: PASSES_STATUSES.canceled, title: PASSES_STATUSES_MAP[PASSES_STATUSES.canceled] },
  // { id: PASSES_STATUSES.finished, title: PASSES_STATUSES_MAP[PASSES_STATUSES.finished] },
];

function formatValue(name, value) {
  if (name === 'phone') {
    return formatPhoneNumberInput(value);
  }

  return value;
}

function getOnePass({
  onePassState,
  match,
}) {
  const passId = ((match || {}).params || {}).id;

  const { list } = onePassState || {};
  const currentPass = (list || []).find(({ id } = {}) => {
    // eslint-disable-next-line
    const isNeeded = ('' + id) === ('' + passId);

    return isNeeded;
  });

  return {
    passId,
    currentPass,
  };
}

function OnePassPage({
  match,

  getOnePassReg,
  onePassState,

  setPassesReg,
  setPassState,

  cancelPassState,

  clearPassesCacheReg,
}) {
  const { t } = useTranslation();
  const styles = useStyles();
  const history = useHistory();
  useCurrentFacilityMatch(PATH_PAGES.passes);
  const { params, path } = match || {};
  const {
    id: passId,
    facilityId,
  } = params || {};

  const isNewMode = !passId && path === PATH_PAGES.addPass;

  const [fields, errors, onChange, setErrors, updateState] = useFormFields({
    initValues: {
      customer: '',
      customerId: '',
      passTypeId: '',
      placeId: '',
      startTime: '',
      finishTime: '',
      status: PASSES_STATUSES.draft,
      reqValues: [],
    },
    formatValueFunc: formatValue,
  });

  const hasPassUpdateRight = useCheckFacilityRights(RIGHT_KEYS.passUpdate, facilityId);
  const hasPassCancelRight = useCheckFacilityRights(RIGHT_KEYS.passCancel, facilityId);
  const hasPassManualPaymentRight = useCheckFacilityRights(RIGHT_KEYS.passManualPayment, facilityId);

  useEffect(() => {
    const {
      currentPass,
    } = getOnePass({
      onePassState,
      match,
    });

    const { loading: isOneCustomerLoading } = onePassState || {};
    if (!isOneCustomerLoading && !currentPass && !isNewMode) {
      getOnePassReg({
        id: passId,
        facilityId,
      });
    }

    if (currentPass && !isNewMode) {
      const { error } = currentPass || {};

      if (error) {
        return;
      }

      const reqValues = [];
      currentPass.requiredData.forEach(({ type, value, ...other }) => {

        let newValue = '';
        if ([PASS_REQ_TYPES.phone, PASS_REQ_TYPES.additionalPhone].includes(type)) {
          newValue = formatPhoneNumber(value);
        } else {
          newValue = value;
        }

        reqValues.push({ type, value: newValue, ...other });
      });

      updateState({
        ...currentPass,
        reqValues,
        startTime: moment(currentPass.startTime),
        finishTime: moment(currentPass.finishTime),
      });
    }
  }, [facilityId, getOnePassReg, isNewMode, match, onePassState, passId, updateState]);

  useEffect(() => {
    const error = (setPassState || {}).error;
    if (error) {
      const { response } = error || {};
      const { data } = response || {};
      const backErrors = (data || {}).errors || {};

      setErrors({
        ...backErrors,
      });
    }
  }, [setErrors, setPassState]);

  const countAdditionalPhone = (fields.reqValues || [])
    .filter((({ type }) => type === PASS_REQ_TYPES.additionalPhone)).length;
  const countAdditionalPlateNumbers = (fields.reqValues || [])
    .filter((({ type }) => type === PASS_REQ_TYPES.additionalPlateNumber)).length;

  const onSubmit = (e) => {
    e.preventDefault();
    e.nativeEvent.preventDefault();

    if (!hasPassUpdateRight) {
      return;
    }

    setPassesReg({
      id: passId,
      facilityId,
      customerId: fields.customerId,
      passTypeId: fields.passTypeId,
      placeId: fields.placeId || null,
      startTime: fields.startTime ? fields.startTime : null,
      finishTime: fields.finishTime ? fields.finishTime : null,
      status: fields.status,
      reqValues: fields.reqValues
        .filter(({ value }) => !!value)
        .map(({ type, value, ...other }) => ({
          type,
          value: [PASS_REQ_TYPES.phone, PASS_REQ_TYPES.additionalPhone].includes(type) ? cleanPhone(value) : value,
          ...other,
        })),
    }, history);
  };

  const {
    currentPass,
  } = getOnePass({
    onePassState,
    match,
  });

  const {
    status,
    url,
    placeId,
    areaId,
    canBeRenewal,
    passType,
  } = currentPass || {};

  const [payModalNode, openPayModal, hidePayModal] = useModal({
    content: <ManualPayPassModalContent
      facilityId={facilityId}
      passId={passId}
      price={fields.price}
      onComplete={() => {
        hidePayModal();
        clearPassesCacheReg();
      }}
      onClose={() => {
        hidePayModal();
      }}
    />,
  });

  const [renewModalNode, openRenewModal, hideRenewModal] = useModal({
    content: <RenewPassModalContent
      facilityId={facilityId}
      passId={passId}
      onComplete={() => {
        hideRenewModal();
        clearPassesCacheReg();
      }}
      onClose={() => {
        hideRenewModal();
      }}
    />,
  });

  const [cancelModalNode, openCancelModal, hideCancelModal] = useModal({
    content: <CancelPassModalContent
      facilityId={facilityId}
      passId={passId}
      availableCancelPayment={status === PASSES_STATUSES.active}
      onComplete={() => {
        hideCancelModal();
        clearPassesCacheReg();
      }}
      onClose={() => {
        hideCancelModal();
      }}
    />,
  });

  const {
    loading: isSaveLoading,
  } = setPassState || {};

  const { loading: isCancelLoading } = cancelPassState || {};

  if (
    !isNewMode && !currentPass
    && (onePassState || {}).loading
  ) {
    return (
      <CircularIndeterminate style={{ minHeight: 600 }} />
    );
  }

  const { error: currentPassError } = currentPass || {};

  if (currentPassError) {
    const currentPassErrorMsg = getApiErrorMsg(currentPassError);
    return (
      <Container className={styles.container}>
        <HeaderWithBackBtn
          title="Абонемент"
        />
        <Typography>{currentPassErrorMsg}</Typography>
      </Container>
    );
  }

  const updateAvailable = [PASSES_STATUSES.active, PASSES_STATUSES.draft, PASSES_STATUSES.awaitingPayment].includes(status)
    || !hasPassUpdateRight;

  return (
    <Container className={styles.container} maxWidth="md">
      <HeaderWithBackBtn
        title={isNewMode ? 'Добавление абонемента' : 'Абонемент'}
      />
      <form noValidate className={styles.form} onSubmit={onSubmit}>
        <Grid container spacing={2}>
          <Grid item container spacing={2} xs={12}>
            <Grid item xs={12} md={7}>
              <CustomTextInput
                required
                className={styles.inputItem}
                value={fields.passType ? fields.passType.name : ''}
                disabled
                label="Тип абонемента"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DateTimeInput
                className={[
                  styles.datePicker,
                  !updateAvailable ? styles.datePickerDisabled : '',
                  // (errors || {}).startDate ? styles.datePickerWithError : '',
                ].join(' ')}
                name="startTime"
                label="Время начала"
                placeholder="Не задано"
                rightIcon={(<CalendarIcon />)}
                disabled={!updateAvailable}
                onChange={({ value }) => updateState({
                  startTime: moment(value),
                  finishTime: moment(value).clone().add(passType.duration, 'hours'),
                })}
                value={toNativeTimeFormat(fields.startTime)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DateTimeInput
                disabled={!updateAvailable}
                className={[
                  styles.datePicker,
                  !updateAvailable ? styles.datePickerDisabled : '',
                  // (errors || {}).startDate ? styles.datePickerWithError : '',
                ].join(' ')}
                name="finishTime"
                label="Время окончания"
                placeholder="Не задано"
                rightIcon={(<CalendarIcon />)}
                onChange={({ value }) => updateState({ finishTime: moment(value) })}
                value={toNativeTimeFormat(fields.finishTime)}
              />
            </Grid>
            {fields.passType && fields.passType.isBookPlace && (
              <Grid item xs={12} md={10}>
                <PassPlaceSelect
                  disabled={!updateAvailable}
                  facilityId={facilityId}
                  passTypeId={fields.passTypeId}
                  initPlaceId={placeId}
                  initAreaId={areaId}
                  selTitle={fields.placeTitle}
                  onChange={(id, title) => updateState({ placeId: id, placeTitle: title })}
                />
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              {([PASSES_STATUSES.awaitingPayment, PASSES_STATUSES.draft].includes(status)) && (
                <ItemSelectors
                  classNameForm={styles.selectorsForm}
                  classNameLabel={styles.selectorsLabel}
                  className={styles.selector}
                  disabled={!updateAvailable}
                  label="Статус"
                  items={PASSES_STATUSES_ITEMS}
                  error={!!errors.status}
                  name="status"
                  onChange={onChange}
                  currentValue={fields.status}
                />
              )}
              {![PASSES_STATUSES.awaitingPayment, PASSES_STATUSES.draft].includes(status) && (
                <CustomTextInput
                  className={styles.inputItem}
                  value={PASSES_STATUSES_MAP[fields.status]}
                  name="status"
                  disabled
                  label="Статус"
                />
              )}
            </Grid>
            <Grid item xs={12} md={6} className={styles.link}>
              {!isNewMode && url && (
                <CustomLink href={url} target="_blank">
                  Ссылка для оплаты/продления
                </CustomLink>
              )}
            </Grid>
            <Grid item xs={12} md={7}>
              <CustomTextInput
                required
                className={styles.inputItem}
                value={fields.customerName || ''}
                name="customerName"
                error={!!errors.customerId}
                disabled
                label="Клиент"
              />
            </Grid>
            <Grid item xs={12} container spacing={2} style={{ padding: 0, margin: 0 }}>
              {fields && (fields.reqValues || []).map(({ value, type }, reqIndex) => (
                <Grid key={`req_value_${reqIndex}`} item xs={12} sm={6} md={4}>
                  <CustomTextInput
                    required={!type.includes('additional')}
                    className={styles.inputItem}
                    error={errors[`requiredData.${reqIndex}.value`] || errors[`requiredData.${type}`] || false}
                    label={PASS_REQ_TYPES_MAP[type]}
                    value={value || ''}
                    autoComplete="off"
                    disabled={!updateAvailable}
                    inputProps={{
                      maxLength: [PASS_REQ_TYPES.phone, PASS_REQ_TYPES.additionalPhone].includes(type)
                        ? MAX_PHONE_NUMBER_LENGTH : 250,
                    }}
                    InputProps={{
                      endAdornment: type.includes('additional') ? (
                        <InputAdornment position="end">
                          <IconButton
                            disabled={!updateAvailable}
                            size="small"
                            onClick={() => {
                              const newReqValues = clone(fields.reqValues);
                              newReqValues[reqIndex].value = '';
                              updateState({
                                reqValues: newReqValues,
                              });
                            }}
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </InputAdornment>
                      ) : null,
                    }}
                    onChange={(e) => {
                      const { target } = e || {};
                      const {
                        value: newValue,
                      } = target || {};

                      const newReqValues = clone(fields.reqValues);

                      if ([PASS_REQ_TYPES.phone, PASS_REQ_TYPES.additionalPhone].includes(type)) {
                        newReqValues[reqIndex].value = formatPhoneNumberInput(newValue);
                      } else {
                        newReqValues[reqIndex].value = newValue;
                      }

                      updateState({
                        reqValues: newReqValues,
                      });
                    }}
                  />
                </Grid>
              ))}
              {updateAvailable && fields.passType && (fields.passType.needAdditionalPhone || fields.passType.needAdditionalPlateNumber) && (
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    {fields.passType.needAdditionalPhone && countAdditionalPhone < 3 && (
                      <Grid item>
                        <CustomLink
                          onClick={() => {
                            const newReqValues = clone(fields.reqValues);
                            newReqValues.push({ type: PASS_REQ_TYPES.additionalPhone, value: '' });

                            updateState({
                              reqValues: newReqValues,
                            });
                          }}
                        >
                          Добавить доп. номер телефона
                        </CustomLink>
                      </Grid>
                    )}
                    {updateAvailable && fields.passType.needAdditionalPlateNumber && countAdditionalPlateNumbers < 4 && (
                      <Grid item>
                        <CustomLink
                          onClick={() => {
                            const newReqValues = clone(fields.reqValues);
                            newReqValues.push({ type: PASS_REQ_TYPES.additionalPlateNumber, value: '' });

                            updateState({
                              reqValues: newReqValues,
                            });
                          }}
                        >
                          Добавить доп. гос номер
                        </CustomLink>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}

            </Grid>
          </Grid>
          <Grid container className={styles.btnsContainer} spacing={2} item xs={12}>
            <Grid item xs={3}>
              <CustomBtn
                type="submit"
                className={styles.btnSubmit}
                btnType="primaryBlue"
                disabled={!updateAvailable}
              >
                {isNewMode ? 'Добавить' : 'Сохранить'}
                {isSaveLoading && (
                  <CircularProgress
                    style={{ marginLeft: 5 }}
                    size={20}
                    color="inherit"
                  />
                )}
              </CustomBtn>
            </Grid>
            {!isNewMode && hasPassCancelRight && [PASSES_STATUSES.draft, PASSES_STATUSES.awaitingPayment, PASSES_STATUSES.active].includes(status) && (
              <Grid item>
                <CustomBtn
                  className={styles.btnSubmit}
                  btnType="primaryRed"
                  onClick={openCancelModal}
                  disabled={!hasPassCancelRight}
                >
                  <CancelIcon style={{ marginRight: 5 }} fontSize="small" />
                  Отменить
                </CustomBtn>
              </Grid>
            )}
            { !isNewMode && hasPassManualPaymentRight && status === PASSES_STATUSES.awaitingPayment && (
              <Grid item>
                <CustomBtn
                  className={styles.btnSubmit}
                  // disabled={isCreateLoading}
                  onClick={openPayModal}
                >
                  <PaymentIcon style={{ marginRight: 5 }} fontSize="small" />
                  Добавить оплату
                  {isSaveLoading && (
                    <CircularProgress
                      style={{ marginLeft: 5 }}
                      size={20}
                      color="inherit"
                    />
                  )}
                </CustomBtn>
              </Grid>
            )}
            {hasPassManualPaymentRight && canBeRenewal && (
              <Grid item>
                <CustomBtn
                  className={styles.btnSubmit}
                  // disabled={isCreateLoading}
                  onClick={openRenewModal}
                >
                  <FastForwardIcon style={{ marginRight: 5 }} fontSize="small" />
                  Продлить
                  {isSaveLoading && (
                    <CircularProgress
                      style={{ marginLeft: 5 }}
                      size={20}
                      color="inherit"
                    />
                  )}
                </CustomBtn>
              </Grid>
            )}
          </Grid>
        </Grid>
      </form>
      {payModalNode}
      {renewModalNode}
      {cancelModalNode}
    </Container>
  );
}

function mapStateToProps(state) {
  const { passes } = state || {};
  const {
    onePass,
    setPass,
    activePassSettings,
    cancelPass,
    payPass,
  } = passes || {};

  return {
    onePassState: onePass,
    setPassState: setPass,
    cancelPassState: cancelPass,
    payPassState: payPass,
    activePassSettingsState: activePassSettings,
  };
}

const ConnectedOneCustomerPage = connect(
  mapStateToProps,
  {
    getOnePassReg: getOnePassAction,
    setPassesReg: updatePassesAction,
    getActivePassSettingsReg: getActivePassSettingsAction,
    cancelPassReg: cancelPassAction,
    payPassReg: payPassAction,
    clearPassesCacheReg: clearPassesCacheAction,
  },
)(OnePassPage);

export default ConnectedOneCustomerPage;
