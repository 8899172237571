import React, { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import HeaderWithBackBtn from '@parkly/shared/components/molecules/HeaderWithBackBtn';
import Container from '@material-ui/core/Container';
import { CircularProgress, Grid } from '@material-ui/core';
import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';
import { getApiErrorMsg, useFormFields } from '@parkly/shared/helpers';
import Typography from '@material-ui/core/Typography';
import ItemSelectors from '@parkly/shared/components/molecules/ItemSelectors';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import {
  PASSES_SETTINGS_STATUS,
  PATH_PAGES,
  PLACE_PURPOSES,
  RIGHT_KEYS,
  TARIFF_STATUS,
  TICKET_HOLDER_TYPES,
  TICKET_HOLDER_TYPES_MAP,
  UNITS,
} from 'config/constants';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Autocomplete, ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import ChevronDownIcon from '@parkly/shared/components/atoms/icons/ChevronDownIcon';
import { getOnePassSettingsAction, setPassesSettingsAction } from 'actions/passes';
import { getFacilityPassPlacesAction } from 'actions/facilities';
import { useCurrentFacilityMatch } from 'helpers/routerHooks';
import { useCheckFacilityRights } from 'helpers/hooks';
import CustomLink from '@parkly/shared/components/atoms/CustomLink';
import { useActiveTariffPlans } from 'api/query/tariffs';
import { useSetTicketTypeMutation, useTicketType } from 'api/query/tickets';
import { useQueryClient } from '@tanstack/react-query';
import { useStyles } from './styles';
import isEmpty from 'lodash/isEmpty';
import Switch from '@material-ui/core/Switch';

const UNITS_LIST = [
  { id: UNITS.hour, title: 'Час' },
  { id: UNITS.day, title: 'День' },
  { id: UNITS.month, title: 'Месяц' },
];

function getOneFacilityPlaces({
  oneFacilityPlacesState,
  facilityId,
}) {
  const { list } = oneFacilityPlacesState || {};
  const currentFacilityPlacesData = (list || []).find(({ id } = {}) => {
    // eslint-disable-next-line
    const isNeeded = ('' + id) === ('' + facilityId);

    return isNeeded;
  });

  const {
    places: currentFacilityPlaces,
    error,
  } = currentFacilityPlacesData || {};

  return {
    facilityId,
    currentFacilityPlaces,
    currentFacilityPlacesError: error,
  };
}

function OneTicketSettingsPage({
  match,

  oneFacilityPlacesState,
  getFacilityPlacesReq,
}) {
  const styles = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const queryClient = useQueryClient();
  useCurrentFacilityMatch(PATH_PAGES.ticketsSettings);

  const { params, path } = match || {};
  const {
    ticketId,
    facilityId,
  } = params || {};

  const hasTicketsSettingsUpdateRight = useCheckFacilityRights(RIGHT_KEYS.ticketTypeUpdate, facilityId);

  const isNewMode = !ticketId && path === PATH_PAGES.newTicketSettings;

  const [fields, errors, onChange, setErrors, updateState] = useFormFields({
    initValues: {
      name: '',
      description: '',
      tariffPlanId: '',
      intervalValue: '',
      intervalMode: UNITS.day,
      isShowingAtSite: false,
      isSingle: false,
      isFixedPlace: false,
      isBookPlace: true,
      isAllPlace: true,
      isIgnoreSessions: false,
      holdValue: '',
      holdMode: UNITS.day,
      placeHoldValue: '',
      placeHoldMode: UNITS.day,
      notifyValue: '',
      notifyMode: UNITS.day,
      maxDaysToStart: 0,
      holderType: TICKET_HOLDER_TYPES.car,
      needPhone: true,
      needFio: false,
      needPlateNumber: false,
      needCarModel: false,
      needStsNumber: false,
      needIdNumber: false,
      status: PASSES_SETTINGS_STATUS.draft,
      places: [],
    },
  });

  const setTicketTypeMutation = useSetTicketTypeMutation({
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['ticket-types'] });
      queryClient.invalidateQueries({ queryKey: ['active-ticket-types'] });
      queryClient.invalidateQueries({ queryKey: ['ticket-type'] });

      history.replace(PATH_PAGES.allTicketsSettings.replace(':facilityId', facilityId));
    },
    onError(error) {
      if (error) {
        const { response } = error || {};
        const { data } = response || {};
        const backErrors = (data || {}).errors || {};

        setErrors({
          ...backErrors,
        });
      }
    },
  });

  const { isLoading: isSaveLoading} = setTicketTypeMutation || {};

  const {
    data: currentTicket,
    isLoading: isTicketLoading,
    error: ticketSettingsError,
  } = useTicketType({
    variables: { facilityId, id: ticketId },
    enabled: !isNewMode,
  });

  useEffect(() => {
    if (!currentTicket || isNewMode) {
      return;
    }

    updateState({
      ...currentTicket,
    });
  }, [isNewMode, currentTicket, updateState]);

  const {
    data: activeTariffPlansData,
    isLoading: isActiveTariffPlansLoading,
  } = useActiveTariffPlans({
    variables: { facilityId, withTechnical: true },
  });

  const activeTariffPlansItems = useMemo(() => (activeTariffPlansData || [])
    .map(({ id, name }) => ({
      id,
      title: name,
    })), [activeTariffPlansData]);

  useEffect(() => {
    const {
      loading: isLoading,
    } = oneFacilityPlacesState || {};

    if (isLoading) {
      return;
    }

    const {
      currentFacilityPlaces,
      currentFacilityPlacesError,
    } = getOneFacilityPlaces({
      oneFacilityPlacesState,
      facilityId,
    });

    if (currentFacilityPlaces || currentFacilityPlacesError) {
      return;
    }

    getFacilityPlacesReq({ id: facilityId, purpose: PLACE_PURPOSES.reactive });
  }, [oneFacilityPlacesState, getFacilityPlacesReq, facilityId]);

  const onSubmit = useCallback((e) => {
    e.preventDefault();
    e.nativeEvent.preventDefault();

    if (fields.isBookPlace && !fields.isAllPlace && isEmpty(fields.places)) {
      setErrors({
        places: true,
      });
      return;
    }

    if (isNewMode) {
      setTicketTypeMutation.mutate({
        facilityId,
        name: fields.name,
        description: fields.description,
        tariffPlanId: fields.tariffPlanId,
        intervalValue: fields.intervalValue,
        intervalMode: fields.intervalMode,
        isShowingAtSite: fields.isShowingAtSite,
        isSingle: fields.isSingle,
        isIgnoreSessions: fields.isIgnoreSessions,
        holdValue: fields.holdValue,
        holdMode: fields.holdMode,
        placeHoldValue: fields.placeHoldValue,
        placeHoldMode: fields.placeHoldMode,
        notifyValue: fields.notifyValue,
        notifyMode: fields.notifyMode,
        maxDaysToStart: fields.maxDaysToStart,
        holderType: fields.holderType,
        needPhone: fields.needPhone || fields.isShowingAtSite,
        needFio: fields.needFio,
        needPlateNumber: fields.needPlateNumber,
        needCarModel: fields.needCarModel,
        needStsNumber: fields.needStsNumber,
        needIdNumber: fields.needIdNumber,
        status: fields.status,
        isBookPlace: fields.isBookPlace,
        isFixedPlace: fields.isFixedPlace,
        places: fields.isBookPlace && !fields.isAllPlace ? fields.places.map(({ id }) => id) : [],
      });
      return;
    }

    setTicketTypeMutation.mutate({
      facilityId,
      id: ticketId,
      name: fields.name,
      description: fields.description,
      tariffPlanId: fields.tariffPlanId,
      intervalValue: fields.intervalValue,
      intervalMode: fields.intervalMode,
      isShowingAtSite: fields.isShowingAtSite,
      isSingle: fields.isSingle,
      isIgnoreSessions: fields.isIgnoreSessions,
      holdValue: fields.holdValue,
      holdMode: fields.holdMode,
      placeHoldValue: fields.placeHoldValue,
      placeHoldMode: fields.placeHoldMode,
      notifyValue: fields.notifyValue,
      notifyMode: fields.notifyMode,
      maxDaysToStart: fields.maxDaysToStart,
      holderType: fields.holderType,
      needPhone: fields.needPhone || fields.isShowingAtSite,
      needFio: fields.needFio,
      needPlateNumber: fields.needPlateNumber,
      needCarModel: fields.needCarModel,
      needStsNumber: fields.needStsNumber,
      needIdNumber: fields.needIdNumber,
      status: fields.status,
      isBookPlace: fields.isBookPlace,
      isFixedPlace: fields.isFixedPlace,
      places: fields.isBookPlace && !fields.isAllPlace ? fields.places.map(({ id }) => id) : [],
    });
  }, [isNewMode, setTicketTypeMutation, facilityId, ticketId, fields]);

  const { loading: isPlacesLoading } = oneFacilityPlacesState || {};

  const { status } = currentTicket || {};

  const isDisabledUpdate = !(isNewMode || status === PASSES_SETTINGS_STATUS.draft);

  const availableStatuses = useMemo(() => {
    if (isNewMode) {
      return [
        { id: PASSES_SETTINGS_STATUS.draft, title: 'Черновик' },
        { id: PASSES_SETTINGS_STATUS.active, title: 'Активный' },
        { id: PASSES_SETTINGS_STATUS.archive, title: 'Архивный' },
      ];
    }

    switch (status) {
      case PASSES_SETTINGS_STATUS.active:
        return [
          { id: TARIFF_STATUS.active, title: 'Активный' },
          { id: TARIFF_STATUS.archive, title: 'Архивный' },
        ];
      case PASSES_SETTINGS_STATUS.archive:
        return [
          { id: PASSES_SETTINGS_STATUS.archive, title: 'Архивный' },
        ];
      case PASSES_SETTINGS_STATUS.draft:
      default:
        return [
          { id: PASSES_SETTINGS_STATUS.draft, title: 'Черновик' },
          { id: PASSES_SETTINGS_STATUS.active, title: 'Активный' },
          { id: PASSES_SETTINGS_STATUS.archive, title: 'Архивный' },
        ];
    }
  }, [isNewMode, status]);

  const {
    currentFacilityPlaces,
  } = getOneFacilityPlaces({
    oneFacilityPlacesState,
    facilityId,
  });

  const { places: currentFacilityPlacesData } = currentFacilityPlaces || {};

  const placesItems = useMemo(() => {
    const result = [];
    const showZoneName = (currentFacilityPlacesData || []).length > 1;
    (currentFacilityPlacesData || []).forEach(({ name: zoneName, places }) => {
      (places || []).forEach(({ id, name }) => {
        result.push({
          id,
          title: showZoneName ? `${zoneName} - ${name}` : name,
        });
      });
    });
    return result;
  }, [currentFacilityPlacesData]);

  const handlePlacesChange = useCallback((e, value) => {
    updateState({
      places: value,
    });
    setErrors({
      places: false,
    });
  }, [setErrors, updateState]);

  const handleSelectAllPlaces = useCallback(() => {
    updateState({
      places: placesItems,
    });
    setErrors({
      places: false,
    });
  }, [setErrors, updateState, placesItems]);

  const handleHolderTypeChange = useCallback((event, newValue) => {
    if (isDisabledUpdate || !hasTicketsSettingsUpdateRight) {
      return;
    }

    const val = newValue || TICKET_HOLDER_TYPES.car;

    if (val === TICKET_HOLDER_TYPES.pedesterian) {
      updateState({
        holderType: val,
        isBookPlace: false,
        isFixedPlace: false,
        needPlateNumber: false,
        needCarModel: false,
        needStsNumber: false,
      });
    } else {
      updateState({ holderType: val });
    }
  }, [hasTicketsSettingsUpdateRight, isDisabledUpdate, updateState]);

  if ((isTicketLoading && !isNewMode) || isPlacesLoading || isActiveTariffPlansLoading) {
    return (
      <CircularIndeterminate style={{ minHeight: 600 }} />
    );
  }

  if (ticketSettingsError) {
    const errorMessage = getApiErrorMsg(ticketSettingsError);

    return (
      <Container className={styles.container} maxWidth="sm">
        <HeaderWithBackBtn
          title={isNewMode ? 'Добавление типа пропуска' : 'Тип пропуска'}
        />
        <Typography>{`${errorMessage}`}</Typography>
      </Container>
    );
  }

  return (
    <Container className={styles.container} maxWidth="sm">
      <HeaderWithBackBtn
        title={isNewMode ? 'Добавление типа пропуска' : 'Тип пропуска'}
      />
      <form noValidate className={styles.form} onSubmit={onSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Typography>
                  Для кого пропуск
                </Typography>
              </Grid>
              <Grid item>
                <ToggleButtonGroup
                  className={styles.holderTypeToggleBtns}
                  value={fields.holderType}
                  exclusive
                  onChange={handleHolderTypeChange}
                >
                  <ToggleButton disabled={!hasTicketsSettingsUpdateRight || isDisabledUpdate} value={TICKET_HOLDER_TYPES.car}>
                    <Typography>
                      {TICKET_HOLDER_TYPES_MAP[TICKET_HOLDER_TYPES.car]}
                    </Typography>
                  </ToggleButton>
                  <ToggleButton disabled={!hasTicketsSettingsUpdateRight || isDisabledUpdate} value={TICKET_HOLDER_TYPES.pedesterian}>
                    <Typography>
                      {TICKET_HOLDER_TYPES_MAP[TICKET_HOLDER_TYPES.pedesterian]}
                    </Typography>
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <CustomTextInput
              className={styles.inputItem}
              required
              error={!!errors.name}
              name="name"
              label="Название"
              value={fields.name}
              autoComplete="off"
              disabled={!hasTicketsSettingsUpdateRight}
              inputProps={{
                maxLength: 250,
              }}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTextInput
              className={styles.inputItem}
              error={!!errors.description}
              name="description"
              label="Описание"
              value={fields.description || ''}
              autoComplete="off"
              disabled={!hasTicketsSettingsUpdateRight}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ItemSelectors
              classNameForm={styles.selectorsForm}
              classNameLabel={styles.selectorsLabel}
              className={styles.selector}
              disabled={!hasTicketsSettingsUpdateRight}
              label="Тарифный план *"
              items={activeTariffPlansItems}
              error={!!errors.tariffPlanId}
              name="tariffPlanId"
              onChange={onChange}
              currentValue={fields.tariffPlanId}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={8} md={4}>
                <CustomTextInput
                  className={styles.inputItem}
                  required
                  error={!!errors.intervalValue}
                  name="intervalValue"
                  label="Макс длительность"
                  value={fields.intervalValue}
                  autoComplete="off"
                  disabled={!hasTicketsSettingsUpdateRight}
                  inputProps={{
                    min: 0,
                    type: 'number',
                  }}
                  onChange={onChange}
                />
              </Grid>
              <Grid item xs={4} md={2}>
                <ItemSelectors
                  classNameForm={styles.selectorsForm}
                  classNameLabel={styles.selectorsLabel}
                  className={styles.selector}
                  items={UNITS_LIST}
                  disabled={!hasTicketsSettingsUpdateRight}
                  name="intervalMode"
                  onChange={onChange}
                  currentValue={fields.intervalMode}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  className={styles.checkbox}
                  control={(
                    <Checkbox
                      disabled={!hasTicketsSettingsUpdateRight}
                      checked={fields.isSingle}
                      name="isSingle"
                      onChange={onChange}
                      color="primary"
                    />
                  )}
                  label="Разовый доступ"
                />
              </Grid>
            </Grid>
          </Grid>
          {fields.holderType === TICKET_HOLDER_TYPES.car && (
            <>
              <Grid item xs={12}>
                <FormControlLabel
                  // className={styles.checkbox}
                  control={(
                    <Checkbox
                      disabled={isDisabledUpdate || !hasTicketsSettingsUpdateRight}
                      checked={fields.isBookPlace}
                      name="isBookPlace"
                      onChange={onChange}
                      color="primary"
                    />
                  )}
                  label="Занимает машиноместо"
                />
              </Grid>
              {fields.isBookPlace && (
                <Grid item xs={12}>
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        // className={styles.checkbox}
                        control={(
                          <Checkbox
                            disabled={!hasTicketsSettingsUpdateRight}
                            checked={fields.isAllPlace}
                            name="isAllPlace"
                            onChange={onChange}
                            color="primary"
                          />
                        )}
                        label="Все места c типом Реактивная/бронирование"
                      />
                    </Grid>
                    {!fields.isAllPlace && (
                      <Grid item xs={12}>
                        <Autocomplete
                          multiple
                          getOptionLabel={(option) => option.title}
                          getOptionSelected={(option, value) => option.id === value.id}
                          options={placesItems}
                          disableCloseOnSelect
                          value={fields.places}
                          disabled={!hasTicketsSettingsUpdateRight}
                          onChange={handlePlacesChange}
                          popupIcon={<ChevronDownIcon />}
                          renderInput={(params) => (
                            <CustomTextInput
                              /* eslint-disable-next-line react/jsx-props-no-spreading */
                              {...params}
                              required
                              className={styles.inputAutocompleteItem}
                              error={!!errors.places}
                              disabled={!hasTicketsSettingsUpdateRight}
                              label="Машиноместа"
                            />
                          )}
                        />
                        {hasTicketsSettingsUpdateRight && (
                          <CustomLink onClick={handleSelectAllPlaces}>Выбрать все места</CustomLink>
                        )}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}
              {fields.isBookPlace && (
                <Grid item xs={12}>
                  <Typography component="div">
                    <Grid component="label" container alignItems="center" spacing={1}>
                      <Grid item>Место назначается автоматически</Grid>
                      <Grid item>
                        <Switch
                          disabled={!hasTicketsSettingsUpdateRight}
                          checked={fields.isFixedPlace}
                          onChange={onChange}
                          name="isFixedPlace"
                          color="primary"
                        />
                      </Grid>
                      <Grid item>Можно выбрать машиноместо</Grid>
                    </Grid>
                  </Typography>
                </Grid>
              )}
            </>
          )}
          <Grid container spacing={2} item xs={12}>
            <Grid item xs={12}>
              <FormControlLabel
                // className={styles.checkbox}
                control={(
                  <Checkbox
                    disabled={!hasTicketsSettingsUpdateRight}
                    checked={fields.isIgnoreSessions}
                    name="isIgnoreSessions"
                    onChange={onChange}
                    color="primary"
                  />
                )}
                label={fields.holderType === TICKET_HOLDER_TYPES.car
                  ? 'Не учитывать въезд и выезд при доступе'
                  : 'Не учитывать вход и выход при доступе'
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={0} item xs={12}>
            <Grid item xs={12}>
              <Typography>
                За сколько дней доступен заказ пропуска
              </Typography>
            </Grid>
            <Grid container spacing={2} item xs={12} alignItems="center">
              <Grid item xs={8} md={3}>
                <CustomTextInput
                  className={styles.inputItem}
                  error={!!errors.maxDaysToStart}
                  name="maxDaysToStart"
                  label="Дней"
                  value={fields.maxDaysToStart}
                  autoComplete="off"
                  disabled={!hasTicketsSettingsUpdateRight}
                  inputProps={{
                    min: 0,
                    type: 'number',
                  }}
                  onChange={onChange}
                />
              </Grid>
              {parseInt(fields.maxDaysToStart, 10) === 0 && (
                <Grid item>
                  <Typography color="textSecondary">
                    Без ограничения
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              Запрашивать для оформления
            </Typography>
          </Grid>
          <Grid container spacing={1} item xs={12}>
            <Grid item>
              <FormControlLabel
                control={(
                  <Checkbox
                    disabled={!hasTicketsSettingsUpdateRight || fields.isShowingAtSite}
                    checked={fields.needPhone || fields.isShowingAtSite}
                    name="needPhone"
                    onChange={onChange}
                    color="primary"
                  />
                )}
                label="Номер телефона"
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={(
                  <Checkbox
                    disabled={!hasTicketsSettingsUpdateRight}
                    checked={fields.needFio}
                    name="needFio"
                    onChange={onChange}
                    color="primary"
                  />
                )}
                label="ФИО"
              />
            </Grid>
            {fields.holderType === TICKET_HOLDER_TYPES.car && (
              <Grid item>
                <FormControlLabel
                  control={(
                    <Checkbox
                      disabled={!hasTicketsSettingsUpdateRight}
                      checked={fields.needPlateNumber}
                      name="needPlateNumber"
                      onChange={onChange}
                      color="primary"
                    />
                  )}
                  label="Госномер авто"
                />
              </Grid>
            )}
            {fields.holderType === TICKET_HOLDER_TYPES.car && (
              <Grid item>
                <FormControlLabel
                  control={(
                    <Checkbox
                      disabled={!hasTicketsSettingsUpdateRight}
                      checked={fields.needCarModel}
                      name="needCarModel"
                      onChange={onChange}
                      color="primary"
                    />
                  )}
                  label="Марка и модель авто"
                />
              </Grid>
            )}
            {fields.holderType === TICKET_HOLDER_TYPES.car && (
              <Grid item>
                <FormControlLabel
                  control={(
                    <Checkbox
                      disabled={!hasTicketsSettingsUpdateRight}
                      checked={fields.needStsNumber}
                      name="needStsNumber"
                      onChange={onChange}
                      color="primary"
                    />
                  )}
                  label="Номер СТС"
                />
              </Grid>
            )}
          </Grid>
          <Grid container item spacing={2}>
            <Grid item xs={4}>
              <ItemSelectors
                classNameForm={styles.selectorsForm}
                classNameLabel={styles.selectorsLabel}
                className={styles.selector}
                items={availableStatuses}
                name="status"
                disabled={!hasTicketsSettingsUpdateRight}
                onChange={onChange}
                currentValue={fields.status}
              />
            </Grid>
            {hasTicketsSettingsUpdateRight && (
              <Grid item xs={3}>
                <CustomBtn
                  className={styles.saveBtn}
                  btnType="primaryBlue"
                  type="submit"
                  disabled={isSaveLoading}
                >
                  {t('tariffs.save')}
                  {isSaveLoading && (
                    <CircularProgress
                      style={{ marginLeft: 5 }}
                      size={20}
                      color="inherit"
                    />
                  )}
                </CustomBtn>
              </Grid>
            )}
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}

function mapStateToProps(state) {
  const {
    facilities,
    passes,
  } = state || {};
  const { oneFacilityPassPlaces } = facilities || {};
  const {
    onePassSettings: onePassSettingsState,
    setPassSettings: setPassSettingsState,
  } = passes || {};

  return {
    oneFacilityPlacesState: oneFacilityPassPlaces,
    onePassSettingsState,
    setPassSettingsState,
  };
}

const ConnectedOneTariffPage = connect(
  mapStateToProps,
  {
    getOnePassSettingsReq: getOnePassSettingsAction,
    setPassesSettingsReq: setPassesSettingsAction,
    getFacilityPlacesReq: getFacilityPassPlacesAction,
  },
)(OneTicketSettingsPage);

export default ConnectedOneTariffPage;
