import { callAPI } from '@parkly/shared/api';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import moment from 'moment-timezone';

async function validGetTicketsSettings({
  res,
}) {
  const { data } = res || {};
  const curData = (data || {}).data || [];
  const curMeta = (data || {}).meta || {};

  const {
    current_page,
    from,
    last_page,
    path,
    per_page,
    to,
    total,
  } = curMeta || {};

  const ticketTypesMeta = {
    currentPage: current_page,
    from,
    lastPage: last_page,
    path,
    perPage: per_page,
    to,
    total,
  };

  const ticketTypesData = curData.map((item) => {
    const {
      id,
      name,
      intervalValue,
      intervalMode,
      tariffPlanId,
      tariffPlanName,
      holderType,
      isSingle,
      status,
    } = item;

    return {
      id,
      name,
      intervalValue,
      intervalMode,
      tariffPlanId,
      tariffPlanName,
      holderType,
      isSingle,
      status,
    };
  });

  const result = {
    ticketTypesData,
    ticketTypesMeta,
  };

  return result;
}

export async function getTicketsSettings({
  facilityId,
  page,
  perPage,
}) {
  const query = new URLSearchParams(pickBy({
    page,
    perPage,
  }, identity));

  const queryParams = query.toString();

  const endpoint = `facilities/${facilityId}/ticket-types?${queryParams}`;

  return callAPI(endpoint)
    .then((res) => validGetTicketsSettings({
      res,
    }));
}

async function validGetTicketSettings({ res }) {
  const { data } = res || {};
  const resultData = (data || {}).data || {};

  const {
    id,
    name,
    description,
    status,
    tariffPlanId,
    intervalValue,
    intervalMode,
    isShowingAtSite,
    isSingle,
    isBookPlace,
    isFixedPlace,
    isIgnoreSessions,
    holdValue,
    holdMode,
    placeHoldValue,
    placeHoldMode,
    notifyValue,
    notifyMode,
    requiredData,
    places,
    maxDaysToStart,
    holderType,
  } = resultData;

  const {
    phone: needPhone,
    fio: needFio,
    plateNumber: needPlateNumber,
    carModel: needCarModel,
    stsNumber: needStsNumber,
    idNumber: needIdNumber,
    additionalPhone: needAdditionalPhone,
    additionalPlateNumber: needAdditionalPlateNumber,
  } = requiredData || {};

  const isAllPlace = (places || []).length === 0;

  return {
    id,
    name,
    description,
    status,
    tariffPlanId,
    intervalValue,
    intervalMode,
    isShowingAtSite,
    isSingle,
    isFixedPlace,
    isBookPlace,
    isAllPlace,
    isIgnoreSessions,
    maxDaysToStart,
    places,
    holdValue,
    holdMode,
    placeHoldValue,
    placeHoldMode,
    notifyValue,
    notifyMode,
    holderType,
    needPhone,
    needFio,
    needPlateNumber,
    needCarModel,
    needStsNumber,
    needIdNumber,
    needAdditionalPhone,
    needAdditionalPlateNumber,
  };
}

export async function getTicketSetting({ facilityId, id }) {
  return callAPI(`facilities/${facilityId}/ticket-types/${id}`)
    .then((res) => validGetTicketSettings({
      res,
    }));
}

async function validGetActiveTicketTypes({
  res,
}) {
  const { data: resData } = res || {};
  const { data } = resData || {};

  const passesData = data.map((item) => {
    const {
      id,
      name,
      places,
      startTime,
      finishTime,
      requiredData,
      duration,
      maxDayToStart,
      isBookPlace,
      price,
      tariffPlanName,
      intervalMode,
      intervalValue,
    } = item;

    const {
      phone: needPhone,
      fio: needFio,
      plateNumber: needPlateNumber,
      carModel: needCarModel,
      stsNumber: needStsNumber,
      idNumber: needIdNumber,
      additionalPhone: needAdditionalPhone,
      additionalPlateNumber: needAdditionalPlateNumber,
    } = requiredData || {};

    const {
      phone,
      fio,
      plateNumber,
      carModel,
      stsNumber,
      idNumber,
      additionalPhone,
      additionalPlateNumber,
    } = requiredData || {};

    return {
      id,
      name,
      places,
      duration,
      maxDayToStart,
      startTime,
      finishTime,
      isBookPlace,
      price,
      tariffPlanName,
      intervalMode,
      intervalValue,
      reqData: {
        phone,
        fio,
        plateNumber,
        carModel,
        stsNumber,
        idNumber,
        additionalPhone,
        additionalPlateNumber,
      },
      needPhone,
      needFio,
      needPlateNumber,
      needCarModel,
      needStsNumber,
      needIdNumber,
      needAdditionalPhone,
      needAdditionalPlateNumber,
    };
  });

  return passesData;
}

export async function getActiveTicketSettings({
  facilityId,
  simple,
}) {
  const query = new URLSearchParams(pickBy({
    simple: simple ? 1 : undefined,
  }, identity));

  const queryParams = query.toString();

  return callAPI(`facilities/${facilityId}/ticket-types/active?${queryParams}`)
    .then((res) => validGetActiveTicketTypes({
      res,
    }));
}

function validCreate(result) {
  const { res } = result || {};
  const { data } = res || {};

  const {
    id,
    success,
  } = data;

  return {
    id,
    success,
  };
}

export async function setTicketSettings({
  facilityId,
  id,
  name,
  description,
  tariffPlanId,
  intervalValue,
  intervalMode,
  isShowingAtSite,
  isSingle,
  isIgnoreSessions,
  isBookPlace,
  isFixedPlace,
  holdValue,
  holdMode,
  placeHoldValue,
  placeHoldMode,
  notifyValue,
  notifyMode,
  maxDaysToStart,
  holderType,
  needPhone,
  needFio,
  needPlateNumber,
  needCarModel,
  needStsNumber,
  needIdNumber,
  needAdditionalPhone,
  needAdditionalPlateNumber,
  status,
  places,
}) {
  return callAPI(
    `facilities/${facilityId}/ticket-types`,
    'POST',
    {
      id: id || null,
      name,
      description,
      status,
      tariffPlanId,
      intervalValue,
      intervalMode,
      isShowingAtSite,
      isSingle,
      isIgnoreSessions,
      isBookPlace,
      isFixedPlace,
      holdValue: holdValue || 0,
      holdMode,
      placeHoldValue: placeHoldValue || 0,
      placeHoldMode,
      notifyValue: notifyValue || 0,
      notifyMode,
      maxDaysToStart: maxDaysToStart || 0,
      holderType,
      places,
      requiredData: {
        phone: needPhone,
        fio: needFio,
        plateNumber: needPlateNumber,
        carModel: needCarModel,
        stsNumber: needStsNumber,
        idNumber: needIdNumber,
        additionalPhone: needAdditionalPhone,
        additionalPlateNumber: needAdditionalPlateNumber,
      },
    },
  )
    .then((res) => validCreate({
      res,
    }));
}

async function validGetPassSettingPlaces({ res }) {
  const { data } = res || {};
  const resultData = (data || {}).data || {};

  return resultData;
}

export async function getTicketSettingPlaces({ facilityId, id }) {
  return callAPI(`facilities/${facilityId}/ticket-types/active/${id}/places`)
    .then((res) => validGetPassSettingPlaces({
      res,
    }));
}

async function validGetTickets({
  res,
}) {
  const { data } = res || {};
  const curData = (data || {}).data || [];
  const curMeta = (data || {}).meta || {};

  const {
    current_page,
    from,
    last_page,
    path,
    per_page,
    to,
    total,
  } = curMeta || {};

  const ticketsMeta = {
    currentPage: current_page,
    from,
    lastPage: last_page,
    path,
    perPage: per_page,
    to,
    total,
  };

  const ticketsData = curData.map((item) => {
    const {
      id,
      number,
      passTypeName,
      passTypeId,
      customerName,
      customerId,
      finishTime,
      startTime,
      placeName,
      status,
      isOverdue,
      requiredData,
      price,
      canBeCanceled,
      comment,
      createdBy,
    } = item;

    return {
      id,
      number,
      passTypeName,
      passTypeId,
      customerName,
      customerId,
      finishTime,
      startTime,
      placeName,
      status,
      isOverdue,
      requiredData,
      price,
      canBeCanceled,
      comment,
      createdBy,
    };
  });

  const result = {
    ticketsData,
    ticketsMeta,
  };

  return result;
}

export async function getTickets({
  facilityId,
  type,
  status,
  passType,
  search,
  dateFrom,
  dateTo,
  page,
  perPage,
  sortBy,
  sortDirection,
}) {
  const query = new URLSearchParams(pickBy({
    page,
    perPage,
    type,
    status: status.join(','),
    dateFrom: dateFrom && moment(dateFrom).utc().toISOString(),
    dateTo: dateTo && moment(dateTo).utc().toISOString(),
    passType: passType === 'unselect' ? undefined : passType,
    search,
    sortBy,
    sortDirection,
  }, identity));

  const queryParams = query.toString();

  const endpoint = `facilities/${facilityId}/ticket?${queryParams}`;

  return callAPI(endpoint)
    .then((res) => validGetTickets({
      res,
    }));
}

async function validGetTicket({ res }) {
  const { data } = res || {};
  const resultData = (data || {}).data || {};

  const {
    id,
    number,
    customerName,
    customerId,
    status,
    price,
    passTypeId,
    passType,
    placeId,
    areaId,
    startTime,
    finishTime,
    requiredData,
    url,
    canBeCanceled,
    comment,
  } = resultData;

  const {
    requiredData: passTypeRequiredData,
  } = passType || {};

  const {
    phone: needPhone,
    fio: needFio,
    plateNumber: needPlateNumber,
    carModel: needCarModel,
    stsNumber: needStsNumber,
    idNumber: needIdNumber,
    additionalPhone: needAdditionalPhone,
    additionalPlateNumber: needAdditionalPlateNumber,
  } = passTypeRequiredData || {};

  return {
    id,
    number,
    customerName,
    customerId,
    price,
    placeId,
    areaId,
    status,
    passTypeId,
    passType: {
      ...(passType || {}),
      needPhone,
      needFio,
      needPlateNumber,
      needCarModel,
      needStsNumber,
      needIdNumber,
      needAdditionalPhone,
      needAdditionalPlateNumber,
    },
    startTime,
    finishTime,
    requiredData,
    url,
    canBeCanceled,
    comment,
  };
}

export async function getTicket({ facilityId, id }) {
  return callAPI(`facilities/${facilityId}/ticket/${id}`)
    .then((res) => validGetTicket({
      res,
    }));
}

async function validGetTicketInfo({ res }) {
  const { data } = res || {};
  const resultData = (data || {}).data || {};

  const {
    id,
    number,
    customer,
    status,
    price,
    passType,
    place,
    startTime,
    finishTime,
    requiredData,
    url,
    sessions,
    payments,
    canBeCanceled,
    comment,
    createdAt,
    createdBy,
  } = resultData;

  return {
    id,
    number,
    customer,
    price,
    place,
    status,
    ticketType: passType,
    startTime,
    finishTime,
    requiredData,
    url,
    sessions,
    payments,
    canBeCanceled,
    comment,
    createdAt,
    createdBy,
  };
}

export async function getTicketInfo({ facilityId, id }) {
  return callAPI(`facilities/${facilityId}/ticket/${id}/info`)
    .then((res) => validGetTicketInfo({
      res,
    }));
}

export async function createTicket({
  facilityId,
  customerId,
  status,
  ticketTypeId,
  placeId,
  comment,
  startTime,
  finishTime,
  reqValues,
}) {
  return callAPI(
    `facilities/${facilityId}/ticket`,
    'POST',
    {
      status,
      comment,
      customerId: customerId || null,
      passTypeId: ticketTypeId,
      placeId: placeId || null,
      startTime,
      finishTime,
      requiredData: reqValues,
    },
  )
    .then((res) => validCreate({
      res,
    }));
}

export async function updateTicket({
  facilityId,
  id,
  customerId,
  status,
  ticketTypeId,
  placeId,
  comment,
  startTime,
  finishTime,
  reqValues,
}) {
  return callAPI(
    `facilities/${facilityId}/ticket`,
    'PUT',
    {
      id,
      status,
      comment,
      customerId,
      passTypeId: ticketTypeId,
      placeId: placeId || null,
      startTime,
      finishTime,
      requiredData: reqValues,
    },
  )
    .then((res) => validCreate({
      res,
    }));
}

export async function activateTicket({
  facilityId,
  id,
}) {
  return callAPI(
    `facilities/${facilityId}/ticket/${id}/activate`,
    'POST',
  )
    .then((res) => validCreate({
      res,
    }));
}

export async function cancelTicket({
  facilityId,
  id,
}) {
  return callAPI(
    `facilities/${facilityId}/ticket/${id}/cancel`,
    'POST',
  )
    .then((res) => validCreate({
      res,
    }));
}
