import React, { useCallback, useEffect } from 'react';
import moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import { Card, CircularProgress, Grid } from '@material-ui/core';
import HeaderWithBackBtn from '@parkly/shared/components/molecules/HeaderWithBackBtn';
import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';
import {
  cleanPhone,
  formatPhoneNumber,
  formatPhoneNumberInput,
  plural,
  toNativeTimeFormat,
  useFormFields,
} from '@parkly/shared/helpers';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import CustomLink from '@parkly/shared/components/atoms/CustomLink';
import ItemSelectors from '@parkly/shared/components/molecules/ItemSelectors';
import Typography from '@material-ui/core/Typography';
import {
  PASS_REQ_TYPES,
  PASS_REQ_TYPES_MAP,
  PASSES_STATUSES,
  PASSES_STATUSES_MAP,
  PATH_PAGES,
  RIGHT_KEYS,
  UNITS_PLURAL_MAP,
} from 'config/constants';
import {
  cancelPassAction,
  clearPassesCacheAction,
  createPassesAction,
  getOnePassAction,
  payPassAction,
} from 'actions/passes';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useCurrentFacilityMatch } from 'helpers/routerHooks';
import CustomerAsyncSelect from 'components/organisms/CustomerAsyncSelect';

import PassPlaceSelect from 'components/organisms/PassPlaceSelect';
import CalendarIcon from '@parkly/shared/components/atoms/icons/CalendarIcon';
import DateTimeInput from '@parkly/shared/components/atoms/DateTimeInput';
import clone from 'lodash/clone';
import { useCheckFacilityRights } from 'helpers/hooks';
import { MAX_PHONE_NUMBER_LENGTH } from '@parkly/shared/config/constants';
import { useActivePassTypes } from 'api/query/passes';
import { useStyles } from './styles';

const PASSES_STATUSES_ITEMS = [
  { id: PASSES_STATUSES.draft, title: PASSES_STATUSES_MAP[PASSES_STATUSES.draft] },
  { id: PASSES_STATUSES.awaitingPayment, title: PASSES_STATUSES_MAP[PASSES_STATUSES.awaitingPayment] },
  // { id: PASSES_STATUSES.active, title: PASSES_STATUSES_MAP[PASSES_STATUSES.active] },
  // { id: PASSES_STATUSES.canceled, title: PASSES_STATUSES_MAP[PASSES_STATUSES.canceled] },
  // { id: PASSES_STATUSES.finished, title: PASSES_STATUSES_MAP[PASSES_STATUSES.finished] },
];

function formatValue(name, value) {
  if (name === 'phone') {
    return formatPhoneNumberInput(value);
  }

  if (name === 'additionalPhone') {
    return formatPhoneNumberInput(value);
  }

  return value;
}

function NewOnePassPage({
  match,

  setPassesReg,
  setPassState,
}) {
  const { t } = useTranslation();
  const styles = useStyles();
  const history = useHistory();
  useCurrentFacilityMatch(PATH_PAGES.passes);
  const { params, path } = match || {};
  const {
    id: passId,
    facilityId,
  } = params || {};

  const isNewMode = !passId && path === PATH_PAGES.addPass;

  const [fields, errors, onChange, setErrors, updateState] = useFormFields({
    initValues: {
      customer: '',
      customerId: '',
      passTypeId: '',
      placeId: '',
      placeTitle: '',
      startTime: moment().startOf('day'),
      finishTime: moment().startOf('day'),
      status: PASSES_STATUSES.draft,
      reqValues: [],
    },
    formatValueFunc: formatValue,
  });

  const {
    data: activePassSettingsData,
    isLoading: isActivePassTypesLoading,
  } = useActivePassTypes({
    variables: { facilityId },
  });

  const hasPassUpdateRight = useCheckFacilityRights(RIGHT_KEYS.passUpdate, facilityId);

  useEffect(() => {
    const { error } = setPassState || {};
    if (error) {
      const { response } = error || {};
      const { data } = response || {};
      const backErrors = (data || {}).errors || {};

      setErrors({
        ...backErrors,
      });
    }
  }, [setErrors, setPassState]);

  const onSubmit = (e) => {
    e.preventDefault();
    e.nativeEvent.preventDefault();

    setPassesReg({
      facilityId,
      customerId: fields.customerId,
      passTypeId: fields.passTypeId,
      placeId: fields.placeId || null,
      startTime: fields.startTime ? fields.startTime : null,
      finishTime: fields.finishTime ? fields.finishTime : null,
      status: fields.status,
      reqValues: fields.reqValues
        .filter(({ value }) => !!value)
        .map(({ type, value, ...other }) => ({
          type,
          value: [PASS_REQ_TYPES.phone, PASS_REQ_TYPES.additionalPhone].includes(type) ? cleanPhone(value) : value,
          ...other,
        })),
    }, history);
  };

  const selPassSettings = (activePassSettingsData || []).find(({ id }) => id === fields.passTypeId);

  useEffect(() => {
    if (!selPassSettings) {
      return;
    }

    const reqValues = [];
    Object.entries(selPassSettings.reqData).forEach((entry) => {
      const [key, value] = entry;

      if (value && !key.includes('additional')) {
        reqValues.push({ type: key, value: '' });
      }
    });

    updateState({
      placeId: '',
      placeTitle: '',
      reqValues,
      startTime: moment(selPassSettings.startTime),
      finishTime: moment(selPassSettings.finishTime),
    });
  }, [selPassSettings, updateState]);

  useEffect(() => {
    if (!selPassSettings) {
      return;
    }

    updateState({
      finishTime: fields.startTime.clone().add(selPassSettings.duration, 'hours'),
    });
  }, [selPassSettings, updateState, fields.startTime]);

  const handleChangeCustomer = useCallback((e, v) => {
    updateState({
      customerId: v ? v.id : '',
    });
    setErrors({
      customerId: false,
    });

    if (v && (v.phone || v.id === null)) {
      const reqIndex = fields.reqValues.findIndex(({ type }) => type === PASS_REQ_TYPES.phone);

      if (reqIndex !== -1) {
        const newReqValues = clone(fields.reqValues);
        newReqValues[reqIndex].value = v.id === null ? '+7' : formatPhoneNumber(v.phone);
        updateState({
          reqValues: newReqValues,
        });
      }
    }

    if (v && (v.title || v.id === null)) {
      const reqIndex = fields.reqValues.findIndex(({ type }) => type === PASS_REQ_TYPES.fio);

      if (reqIndex !== -1) {
        const newReqValues = clone(fields.reqValues);
        newReqValues[reqIndex].value = v.id === null ? '' : v.title;
        updateState({
          reqValues: newReqValues,
        });
      }
    }
  }, [fields.reqValues, setErrors, updateState]);

  const countAdditionalPhone = fields.reqValues
    .filter((({ type }) => type === PASS_REQ_TYPES.additionalPhone)).length;
  const countAdditionalPlateNumbers = fields.reqValues
    .filter((({ type }) => type === PASS_REQ_TYPES.additionalPlateNumber)).length;

  const {
    loading: isSaveLoading,
  } = setPassState || {};

  if (isActivePassTypesLoading) {
    return (
      <CircularIndeterminate style={{ minHeight: 600 }} />
    );
  }

  return (
    <div className={styles.container}>
      <HeaderWithBackBtn
        title="Добавление абонемента"
      />
      <form noValidate className={styles.form} onSubmit={onSubmit}>
        <div>
          <Typography>Выберите тип абонемента:</Typography>
          <Grid className={styles.typesContainer} container spacing={1} wrap="nowrap">
            {(activePassSettingsData || []).map(({ id, name, tariffPlanName, intervalMode, intervalValue }) => (
              <Grid item key={id}>
                <Card
                  variant="outlined"
                  className={[
                    styles.typeCard,
                    id === fields.passTypeId ? styles.typeCardSelected : '',
                  ].join(' ')}
                  onClick={() => {
                    updateState({ passTypeId: id });
                  }}
                >
                  <Typography className={styles.cardTitle}>{name}</Typography>
                  <Typography>{`${intervalValue} ${plural(intervalValue, UNITS_PLURAL_MAP[intervalMode])}`}</Typography>
                  {/*<Typography>{tariffPlanName}</Typography>*/}
                </Card>
              </Grid>
            ))}
          </Grid>
        </div>
        <Container maxWidth="sm" className={styles.innerContainer}>
          <Grid container spacing={2}>
            {fields.passTypeId && (
              <>
                <Grid item xs={12} md={6}>
                  <DateTimeInput
                    className={[
                      styles.datePicker,
                    // (errors || {}).startDate ? styles.datePickerWithError : '',
                    ].join(' ')}
                    name="startTime"
                    label="Время начала"
                    placeholder="Не задано"
                    rightIcon={(<CalendarIcon />)}
                    onChange={({ value }) => updateState({ startTime: moment(value) })}
                    value={toNativeTimeFormat(fields.startTime)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DateTimeInput
                    className={[
                      styles.datePicker,
                    // (errors || {}).startDate ? styles.datePickerWithError : '',
                    ].join(' ')}
                    name="finishTime"
                    label="Время окончания"
                    placeholder="Не задано"
                    rightIcon={(<CalendarIcon />)}
                    onChange={({ value }) => updateState({ finishTime: moment(value) })}
                    value={toNativeTimeFormat(fields.finishTime)}
                  />
                </Grid>
                {selPassSettings.isBookPlace && (
                  <Grid item xs={12} md={10}>
                    <PassPlaceSelect
                      facilityId={facilityId}
                      passTypeId={fields.passTypeId}
                      selTitle={fields.placeTitle}
                      onChange={(id, title) => updateState({ placeId: id, placeTitle: title })}
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={8}>
                  <ItemSelectors
                    classNameForm={styles.selectorsForm}
                    classNameLabel={styles.selectorsLabel}
                    className={styles.selector}
                    disabled={!hasPassUpdateRight}
                    label="Статус"
                    items={PASSES_STATUSES_ITEMS}
                    error={!!errors.status}
                    name="status"
                    onChange={onChange}
                    currentValue={fields.status}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <CustomerAsyncSelect
                    facilityId={facilityId}
                    onChange={handleChangeCustomer}
                    error={!!errors.customerId}
                  />
                </Grid>
                {(fields.customerId || fields.customerId === null) && (
                  <Grid item xs={12} container spacing={2} style={{ padding: 0, margin: 0 }}>
                    {fields && (fields.reqValues || []).map(({ value, type }, reqIndex) => (
                      <Grid key={`req_value_${reqIndex}`} item xs={12} sm={6} md={4}>
                        <CustomTextInput
                          required={!type.includes('additional')}
                          className={styles.inputItem}
                          error={errors[`requiredData.${reqIndex}.value`] || errors[`requiredData.${type}`] || false}
                          label={PASS_REQ_TYPES_MAP[type]}
                          value={value || ''}
                          autoComplete="off"
                          disabled={!hasPassUpdateRight}
                          inputProps={{
                            maxLength:
                            [PASS_REQ_TYPES.phone, PASS_REQ_TYPES.additionalPhone].includes(type)
                              ? MAX_PHONE_NUMBER_LENGTH : 250,
                          }}
                          InputProps={{
                            endAdornment: type.includes('additional') ? (
                              <InputAdornment position="end">
                                <IconButton
                                  size="small"
                                  edge="end"
                                  onClick={() => {
                                    const newReqValues = clone(fields.reqValues);
                                    newReqValues.splice(reqIndex, 1);
                                    updateState({
                                      reqValues: newReqValues,
                                    });
                                  }}
                                >
                                  <CloseIcon fontSize="small" />
                                </IconButton>
                              </InputAdornment>
                            ) : null,
                          }}
                          onChange={(e) => {
                            const { target } = e || {};
                            const {
                              value: newValue,
                            } = target || {};

                            const newReqValues = clone(fields.reqValues);

                            if ([PASS_REQ_TYPES.phone, PASS_REQ_TYPES.additionalPhone].includes(type)) {
                              newReqValues[reqIndex].value = formatPhoneNumberInput(newValue);
                            } else {
                              newReqValues[reqIndex].value = newValue;
                            }

                            updateState({
                              reqValues: newReqValues,
                            });
                          }}
                        />
                      </Grid>
                    ))}
                    {selPassSettings && (selPassSettings.needAdditionalPhone || selPassSettings.needAdditionalPlateNumber) && (
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          {selPassSettings.needAdditionalPhone && countAdditionalPhone < 3 && (
                            <Grid item>
                              <CustomLink
                                onClick={() => {
                                  const newReqValues = clone(fields.reqValues);
                                  newReqValues.push({ type: PASS_REQ_TYPES.additionalPhone, value: '' });

                                  updateState({
                                    reqValues: newReqValues,
                                  });
                                }}
                              >
                                Добавить доп. номер телефона
                              </CustomLink>
                            </Grid>
                          )}
                          {selPassSettings.needAdditionalPlateNumber && countAdditionalPlateNumbers < 4 && (
                            <Grid item>
                              <CustomLink
                                onClick={() => {
                                  const newReqValues = clone(fields.reqValues);
                                  newReqValues.push({ type: PASS_REQ_TYPES.additionalPlateNumber, value: '' });

                                  updateState({
                                    reqValues: newReqValues,
                                  });
                                }}
                              >
                                Добавить доп. гос номер
                              </CustomLink>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    )}

                  </Grid>
                )}
              </>
            )}
            <Grid container className={styles.btnsContainer} spacing={2} item xs={12}>
              <Grid item xs={3}>
                <CustomBtn
                  type="submit"
                  className={styles.btnSubmit}
                  btnType="primaryBlue"
                  disabled={!(fields.customerId || fields.customerId === null)}
                >
                  Добавить
                  {isSaveLoading && (
                    <CircularProgress
                      style={{ marginLeft: 5 }}
                      size={20}
                      color="inherit"
                    />
                  )}
                </CustomBtn>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </form>
    </div>
  );
}

function mapStateToProps(state) {
  const { passes } = state || {};
  const {
    onePass,
    createPass,
    activePassSettings,
    cancelPass,
    payPass,
  } = passes || {};

  return {
    onePassState: onePass,
    setPassState: createPass,
    cancelPassState: cancelPass,
    payPassState: payPass,
  };
}

const ConnectedOneCustomerPage = connect(
  mapStateToProps,
  {
    getOnePassReg: getOnePassAction,
    setPassesReg: createPassesAction,
    cancelPassReg: cancelPassAction,
    payPassReg: payPassAction,
    clearPassesCacheReg: clearPassesCacheAction,
  },
)(NewOnePassPage);

export default ConnectedOneCustomerPage;
