import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Container from '@material-ui/core/Container';
import { useCurrentFacilityMatch } from 'helpers/routerHooks';
import { PATH_PAGES, RIGHT_KEYS } from 'config/constants';
import HeaderWithNavigationLinks from 'components/templates/HeaderWithNavigationLinks';
import { useCheckFacilityRights } from 'helpers/hooks';
import Payments from 'components/organisms/Payments';
import { useStyles } from './styles';

function AllPaymentsPage({
  match,
}) {
  const styles = useStyles();
  const { t } = useTranslation();

  useCurrentFacilityMatch();

  const { params: matchParams } = match || {};
  const {
    facilityId: currentFacilityId,
  } = matchParams || {};

  const hasSessionShowRight = useCheckFacilityRights(RIGHT_KEYS.sessionShow, currentFacilityId);
  const hasGatewayLogShowRight = useCheckFacilityRights(RIGHT_KEYS.gatewayLogShow, currentFacilityId);

  return (
    <Container className={styles.container}>
      <HeaderWithNavigationLinks
        links={[
          hasSessionShowRight
            ? { to: PATH_PAGES.sessions.replace(':facilityId', currentFacilityId), label: 'Сессии' }
            : null,
          { to: PATH_PAGES.payments.replace(':facilityId', currentFacilityId), label: 'Оплаты' },
          hasGatewayLogShowRight
            ? { to: PATH_PAGES.gatewayEvents.replace(':facilityId', currentFacilityId), label: 'Проезды' }
            : null,
        ]}
      />
      <div className={styles.pageBody}>
        <Payments
          facilityId={currentFacilityId}
          showControls
          showPagination
        />
      </div>
    </Container>
  );
}

function mapStateToProps(state) {
  return {};
}

const ConnectedAllPaymentsPage = connect(
  mapStateToProps,
  {},
)(AllPaymentsPage);

export default ConnectedAllPaymentsPage;
