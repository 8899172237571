import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    display: 'flex',
    margin: '-32px',
    marginTop: '-16px',
  },
  container: {
    backgroundColor: theme.palette.greyC.lightGrey,
    paddingTop: 24,
    width: 360,
    flexDirection: 'column',
    display: 'flex',
    height: '100%',
    overflow: 'none',
  },
  icon: {
    marginLeft: 24,
    color: theme.palette.text.primary,
  },
  headerStr: {
    marginLeft: 16,
    marginBottom: 16,

    fontSize: 16,
    fontWeight: 700,
  },
  children: {
    overflow: 'auto',
    width: '100%',
    padding: '32px',
  },
  // necessary for content to be below app bar
  toolbar: {
    [theme.breakpoints.down('xs')]: {
      ...theme.mixins.toolbar,
    },
  },
  menuList: {
    backgroundColor: theme.palette.primary,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 0,
    height: '100%',
  },
  text: {
    flex: '0 1 auto',
  },
  navigationLink: {
    color: theme.palette.text.primary,
    '&[disabled]': {
      pointerEvents: 'none',
    }
  },
  activeCustomListItemMenu: {
    '& .MuiListItemText-root .MuiTypography-root.MuiListItemText-primary': {
      fontWeight: 'bold',
    },
  },
  customListItemMenuNested: {
    paddingLeft: 30,
  },
  expandIcon: {
    marginLeft: 'auto',
    marginRight: 32,
  },
}));

export default {
  useStyles,
};
