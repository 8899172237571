import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    marginLeft: 0,
  },
  formContainer: {
    width: '100%',
  },
  form: {
    marginTop: 16,
  },
  inputItem: {
    margin: 0
  },
  divider: {
    marginTop: 16,
    backgroundColor: theme.palette.greyC.gray,
  },
  checkbox: {
    marginLeft: 0,
  },
  subTitle: {
    marginLeft: 8,
    marginTop: 24,
    fontSize: 16,
    lineHeight: '20px',
    fontWeight: 700,
  },
  btnSubmit: {
    marginTop: 16,
    width: '100%',
    height: 48,
    display: 'block',
  },
  boxWriteToUS: {
    backgroundColor: theme.palette.greyC.lightGrey,
    borderRadius: 6,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 72,
    marginTop: 25,
    padding: 16,
    width: '100%',
  },
  writeToUsBtn: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    width: 'auto',
  },
}));

export default {
  useStyles,
};
