import { callAPI } from '@parkly/shared/api';
import { cleanPhone, getRightsKey } from '@parkly/shared/helpers';

import { formatRights } from 'helpers/rights';

import { ROLES } from '../config/constants';

function validGetCurrentOperator(result) {
  const { res } = result || {};
  const { data } = res || {};
  const resultData = (data || {}).data || {};

  const {
    id,
    name,
    phone,
    jobTitle,
    isAdmin,
    status,
    rights,
    role,
    comment,
    hasAnotherPartners,
  } = resultData;

  const rightKeys = Object.keys(rights || {});

  const rightsObject = rightKeys.reduce(
    (acc, key = '') => {
      const rightKey = getRightsKey(key);

      return {
        ...acc,
        [rightKey]: (rights || {})[key],
      };
    },
    {},
  );

  return {
    id,
    name,
    phone,
    jobTitle,
    status,
    isAdmin,
    role,
    comment,
    rights: rightsObject,
    hasAnotherPartners,
  };
}

export async function getCurrentOperator() {
  return callAPI('operators/current')
    .then((res) => validGetCurrentOperator({
      res,
    }));
}

function transformOperator(operator) {
  const {
    id,
    isAdmin,
    phone,
    name,
    status,
    comment,
    jobTitle,
    isApiUser,
  } = operator;

  return {
    id,
    isAdmin,
    phone,
    name,
    jobTitle,
    comment,
    status,
    isApiUser,
  };
}

export async function validGetOperators({ res }) {
  const { data } = res || {};

  const dataList = (data || {}).data || [];

  return dataList.map((item) => transformOperator(item));
}

async function validAddNewOperator(result) {
  const { res } = result || {};
  const { data } = res || {};

  const {
    success,
    id,
    token,
  } = data;

  return {
    success,
    operatorId: id,
    token,
  };
}

function getNewOperatorBody({
  name,
  jobTitle,
  phone,
  comment,
  status,
  role,
  rules,
  allFacilitiesData,
}) {
  const body = {
    name,
    jobTitle,
    phone: cleanPhone(phone),
    comment,
    status,
    role,
    rights: [ROLES.admin, ROLES.dealer].includes(role)
      ? null
      : formatRights({
        rules,
        allPlatformsData: allFacilitiesData,
      }),
  };

  return body;
}

function getSetApiOperatorBody({
  name,
  comment,
  status,
  role,
  rules,
  allFacilitiesData,
}) {
  const body = {
    name,
    comment,
    status,
    role,
    rights: [ROLES.admin, ROLES.dealer].includes(role)
      ? null
      : formatRights({
        rules,
        allPlatformsData: allFacilitiesData,
      }),
  };

  return body;
}

function getUpdateOperatorBody({
  id,
  name,
  jobTitle,
  phone,
  comment,
  status,
  rules,
  role,
  allFacilitiesData,
}) {
  // eslint-disable-next-line
  let body = {
    name,
    jobTitle,
    phone,
    comment,
    status,
    role,
    rights: [ROLES.admin, ROLES.dealer].includes(role)
      ? null
      : formatRights({
        rules,
        allPlatformsData: allFacilitiesData,
      }),
  };

  return body;
}

export async function validGetOneOperator({ res }) {
  const { data } = res || {};
  const resultData = (data || {}).data || {};
  const {
    id,
    isAdmin,
    login,
    name,
    jobTitle,
    phone,
    rights,
    role,
    comment,
    status,
    isApiUser,
  } = resultData;

  const rightKeys = Object.keys(rights || {});

  const rightsObject = rightKeys.reduce(
    (acc, key = '') => {
      const rightKey = getRightsKey(key);

      return {
        ...acc,
        [rightKey]: (rights || {})[key],
      };
    },
    {},
  );

  return {
    id,
    isAdmin,
    login,
    comment: comment || '',
    name,
    jobTitle,
    phone,
    role,
    rights: rightsObject,
    status,
    isApiUser,
  };
}

export async function getOperators() {
  return callAPI('operators')
    .then((res) => validGetOperators({
      res,
    }));
}

export async function addNewOperator({
  name,
  jobTitle,
  phone,
  comment,
  status,
  role,
  rules,
  allFacilitiesData,
}) {
  const data = getNewOperatorBody({
    name,
    jobTitle,
    phone,
    comment,
    status,
    rules,
    role,
    allFacilitiesData,
  });

  return callAPI('operators', 'POST', data)
    .then((res) => validAddNewOperator({
      res,
    }));
}

export async function updateOperator({
  name,
  jobTitle,
  phone,
  comment,
  status,
  rules,
  role,
  operatorId,
  allFacilitiesData,
}) {
  const data = getUpdateOperatorBody({
    name,
    jobTitle,
    phone,
    comment,
    status,
    rules,
    role,
    allFacilitiesData,
  });

  return callAPI('operators', 'POST', { ...data, operatorId })
    .then((res) => validAddNewOperator({
      res,
    }));
}

export async function addNewApiOperator({
  name,
  comment,
  status,
  role,
  rules,
  allFacilitiesData,
}) {
  const data = getSetApiOperatorBody({
    name,
    comment,
    status,
    rules,
    role,
    allFacilitiesData,
  });

  return callAPI('operators/api', 'POST', data)
    .then((res) => validAddNewOperator({
      res,
    }));
}

export async function updateApiOperator({
  name,
  comment,
  status,
  rules,
  role,
  operatorId,
  allFacilitiesData,
}) {
  const data = getSetApiOperatorBody({
    name,
    comment,
    status,
    rules,
    role,
    allFacilitiesData,
  });

  return callAPI('operators/api', 'POST', { ...data, operatorId })
    .then((res) => validAddNewOperator({
      res,
    }));
}

export async function newApiOperatorToken({
  operatorId,
}) {
  return callAPI('operators/api/token', 'POST', { operatorId })
    .then((res) => validAddNewOperator({
      res,
    }));
}

export async function removeOperator({
  operatorId,
}) {
  return callAPI(`operators/${operatorId}`, 'DELETE')
    .then((res) => validAddNewOperator({
      res,
    }));
}

export async function getOneOperator({
  id,
}) {
  return callAPI(`operators/${id}`)
    .then((res) => validGetOneOperator({
      res,
    }));
}

export async function validGetActionsOneOperator({ res }) {
  const { data } = res || {};
  const curData = (data || {}).data || [];
  const curMeta = (data || {}).meta || {};

  const {
    current_page,
    last_page,
    per_page,
    total,
  } = curMeta || {};

  const transformedData = (curData || []).map((action) => {
    const {
      id,
      date,
      title,
      oldValues,
      newValues,
      operator,
    } = action || {};

    const {
      id: operatorId,
      name: operatorName,
    } = operator || {};

    return {
      id,
      date,
      title,
      oldValues,
      newValues,
      operatorId,
      operatorName,
    };
  });

  return {
    data: transformedData,
    meta: {
      currentPage: current_page,
      lastPage: last_page,
      perPage: per_page,
      total,
    },
  };
}

export async function getActionsOneOperator({
  id,
  date,
  page,
}) {
  /* eslint-disable */
  const query = '?'
    + (id ? `operatorId=${id}&` : '')
    + (date ? `date=${date}&` : '')
    + (page ? `page=${page}&` : '')
  /* eslint-enable */

  const queryParams = query.slice(0, -1);

  const endpoint = `operators/journal${queryParams}`;

  return callAPI(endpoint)
    .then((res) => validGetActionsOneOperator({
      res,
    }));
}

export async function validGetOperatorRightList({ res }) {
  const { data } = res || {};
  const rightData = (data || {}).rights || {};

  const rights = [];

  const commonRight = (rightData || {}).common || {};
  for (const [id, title] of Object.entries(commonRight)) {
    rights.push({
      id,
      title,
      isCommon: true,
    });
  }

  const facilityRight = (rightData || {}).facility || {};
  for (const [id, title] of Object.entries(facilityRight)) {
    rights.push({
      id,
      title,
    });
  }

  const rolesData = (data || {}).roles || {};

  const modulesData = (data || {}).modules || {};

  return { rights, roles: rolesData, modules: modulesData };
}

export async function getOperatorRightList() {
  return callAPI('operators/rights')
    .then((res) => validGetOperatorRightList({
      res,
    }));
}

export default {
  getCurrentOperator,
};
