import React, { useCallback, useEffect } from 'react';
import moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import { CircularProgress, Grid } from '@material-ui/core';
import HeaderWithBackBtn from '@parkly/shared/components/molecules/HeaderWithBackBtn';
import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';
import {
  cleanPhone,
  formatPhoneNumber,
  formatPhoneNumberInput,
  getApiErrorMsg,
  toNativeTimeFormat,
  useFormFields,
} from '@parkly/shared/helpers';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import Typography from '@material-ui/core/Typography';
import CalendarIcon from '@parkly/shared/components/atoms/icons/CalendarIcon';
import DateTimeInput from '@parkly/shared/components/atoms/DateTimeInput';
import clone from 'lodash/clone';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { PASS_REQ_TYPES, PASS_REQ_TYPES_MAP, PASSES_STATUSES, PATH_PAGES, RIGHT_KEYS } from 'config/constants';
import { useCurrentFacilityMatch } from 'helpers/routerHooks';
import { useCheckFacilityRights } from 'helpers/hooks';
import { MAX_PHONE_NUMBER_LENGTH } from '@parkly/shared/config/constants';
import {
  useActivateTicketMutation,
  useCancelTicketMutation,
  useTicket,
  useUpdateTicketMutation,
} from 'api/query/tickets';
import TicketPlaceSelect from 'components/organisms/TicketPlaceSelect';
import useConfirm from '@parkly/shared/helpers/confirm';
import { useStyles } from './styles';
import CancelIcon from '@material-ui/icons/Cancel';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';


function formatValue(name, value) {
  if (name === 'phone') {
    return formatPhoneNumberInput(value);
  }

  return value;
}

function OneTicketPage({
  match,
}) {
  const { t } = useTranslation();
  const styles = useStyles();
  const confirm = useConfirm();
  const history = useHistory();
  useCurrentFacilityMatch(PATH_PAGES.tickets);
  const { params } = match || {};
  const {
    id: ticketId,
    facilityId,
  } = params || {};

  const [fields, errors, onChange, setErrors, updateState] = useFormFields({
    initValues: {
      customer: '',
      customerId: '',
      passTypeId: '',
      placeId: '',
      startTime: '',
      finishTime: '',
      status: PASSES_STATUSES.draft,
      reqValues: [],
    },
    formatValueFunc: formatValue,
  });

  const hasTicketUpdateRight = useCheckFacilityRights(RIGHT_KEYS.ticketUpdate, facilityId);

  const {
    data: currentTicket,
    isLoading,
    error: currentTicketError,
    refetch: ticketRefetch,
  } = useTicket({ variables: { facilityId, id: ticketId } });

  useEffect(() => {
    if (currentTicket) {
      const reqValues = [];
      currentTicket.requiredData.forEach(({ type, value, ...other }) => {

        let newValue = '';
        if ([PASS_REQ_TYPES.phone, PASS_REQ_TYPES.additionalPhone].includes(type)) {
          newValue = formatPhoneNumber(value);
        } else {
          newValue = value;
        }

        reqValues.push({ type, value: newValue, ...other });
      });

      updateState({
        ...currentTicket,
        reqValues,
        startTime: moment(currentTicket.startTime),
        finishTime: moment(currentTicket.finishTime),
      });
    }
  }, [facilityId, currentTicket, updateState]);

  const updateTicketMutation = useUpdateTicketMutation({
    onSuccess() {

    },
    onError(error) {
      if (error) {
        const { response } = error || {};
        const { data } = response || {};
        const backErrors = (data || {}).errors || {};

        setErrors({
          ...backErrors,
        });
      }
    },
  });

  const { isLoading: isSaveLoading } = updateTicketMutation || {};

  const onSubmit = (e) => {
    e.preventDefault();
    e.nativeEvent.preventDefault();

    if (!hasTicketUpdateRight) {
      return;
    }

    updateTicketMutation.mutate({
      id: ticketId,
      facilityId,
      customerId: fields.customerId,
      ticketTypeId: fields.passTypeId,
      comment: fields.comment,
      placeId: fields.placeId || null,
      startTime: fields.startTime ? fields.startTime : null,
      finishTime: fields.finishTime ? fields.finishTime : null,
      status: fields.status,
      reqValues: fields.reqValues
        .filter(({ value }) => !!value)
        .map(({ type, value, ...other }) => ({
          type,
          value: [PASS_REQ_TYPES.phone, PASS_REQ_TYPES.additionalPhone].includes(type) ? cleanPhone(value) : value,
          ...other,
        }))
        .filter(({ value, type }) => !([PASS_REQ_TYPES.phone, PASS_REQ_TYPES.additionalPhone].includes(type) && value === '7')),
    });
  };

  const activateTicketMutation = useActivateTicketMutation({
    onSuccess({ success }) {
      if (success) {
        ticketRefetch();
      }
    },
  });

  const { isLoading: isActivateLoading } = activateTicketMutation || {};

  const handleActivateTicketClick = useCallback(() => {
    confirm({
      title: 'Вы уверены, что хотите активировать пропуск?',
      agreeTitle: 'Активировать',
      disagreeTitle: 'Отменить',
    }).then(() => {

      activateTicketMutation.mutate({ facilityId, id: ticketId });
    }).catch(() => {});
  }, [activateTicketMutation, confirm, facilityId, ticketId]);


  const cancelTicketMutation = useCancelTicketMutation({
    onSuccess({ success }) {
      if (success) {
        ticketRefetch();
      }
    },
  });

  const { isLoading: isCancelLoading } = cancelTicketMutation || {};

  const handleCancelTicketClick = useCallback(() => {
    confirm({
      title: 'Вы уверены, что хотите отменить пропуск?',
      agreeTitle: 'Да, отменить',
      disagreeTitle: 'Нет',
    }).then(() => {
      cancelTicketMutation.mutate({ facilityId, id: ticketId });
    }).catch(() => {});
  }, [cancelTicketMutation, confirm, facilityId, ticketId]);

  const {
    status,
    url,
    placeId,
    areaId,
    passType,
    canBeCanceled,
  } = currentTicket || {};

  if (isLoading) {
    return (
      <CircularIndeterminate style={{ minHeight: 600 }} />
    );
  }

  if (currentTicketError) {
    const currentTicketErrorMsg = getApiErrorMsg(currentTicketError);
    return (
      <Container className={styles.container}>
        <HeaderWithBackBtn
          title="Пропуск"
        />
        <Typography>{currentTicketErrorMsg}</Typography>
      </Container>
    );
  }

  const updateAvailable = [PASSES_STATUSES.active, PASSES_STATUSES.draft, PASSES_STATUSES.awaitingPayment].includes(status)
    || !hasTicketUpdateRight;

  return (
    <Container className={styles.container} maxWidth="md">
      <HeaderWithBackBtn
        title="Пропуск"
      />
      <form noValidate className={styles.form} onSubmit={onSubmit}>
        <Grid container spacing={2}>
          <Grid item container spacing={2} xs={12}>
            <Grid item xs={12} md={7}>
              <CustomTextInput
                required
                className={styles.inputItem}
                value={fields.passType ? fields.passType.name : ''}
                disabled
                label="Тип пропуска"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DateTimeInput
                className={[
                  styles.datePicker,
                  !updateAvailable ? styles.datePickerDisabled : '',
                  (errors || {}).startTime ? styles.datePickerWithError : '',
                ].join(' ')}
                name="startTime"
                min={moment().startOf('day')}
                max={passType.maxDayToStart
                  ? moment().add(passType.maxDayToStart, 'days').endOf('day')
                  : undefined}
                label="Время начала"
                placeholder="Не задано"
                rightIcon={(<CalendarIcon />)}
                disabled={!updateAvailable}
                onChange={({ value }) => updateState({ startTime: moment(value) })}
                value={toNativeTimeFormat(fields.startTime)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DateTimeInput
                disabled={!updateAvailable}
                className={[
                  styles.datePicker,
                  !updateAvailable ? styles.datePickerDisabled : '',
                  (errors || {}).finishTime ? styles.datePickerWithError : '',
                ].join(' ')}
                name="finishTime"
                min={moment(fields.startTime).clone().startOf('day')}
                max={moment(fields.startTime).clone().add(passType.duration, 'hours').endOf('day')}
                label="Время окончания"
                placeholder="Не задано"
                rightIcon={(<CalendarIcon />)}
                onChange={({ value }) => updateState({ finishTime: moment(value) })}
                value={toNativeTimeFormat(fields.finishTime)}
              />
            </Grid>
            {fields.passType && fields.passType.isBookPlace && (
              <Grid item xs={12} md={10}>
                <TicketPlaceSelect
                  disabled={!updateAvailable}
                  facilityId={facilityId}
                  ticketTypeId={fields.passTypeId}
                  initPlaceId={placeId}
                  initAreaId={areaId}
                  selTitle={fields.placeTitle}
                  onChange={(id, title) => updateState({ placeId: id, placeTitle: title })}
                />
              </Grid>
            )}
            {/*<Grid item xs={12} md={6}>*/}
            {/*  <CustomTextInput*/}
            {/*    className={styles.inputItem}*/}
            {/*    value={PASSES_STATUSES_MAP[fields.status]}*/}
            {/*    name="status"*/}
            {/*    disabled*/}
            {/*    label="Статус"*/}
            {/*  />*/}
            {/*</Grid>*/}
            <Grid item xs={12}>
              <CustomTextInput
                className={styles.inputItem}
                error={!!errors.comment}
                name="comment"
                label="Комментарий"
                value={fields.comment}
                autoComplete="off"
                disabled={!hasTicketUpdateRight}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={12} md={7}>
              <CustomTextInput
                required
                className={styles.inputItem}
                value={fields.customerName || ''}
                name="customerName"
                error={!!errors.customerId}
                disabled
                label="Клиент"
              />
            </Grid>
            <Grid item xs={12} container spacing={2} style={{ padding: 0, margin: 0 }}>
              {fields && (fields.reqValues || []).map(({ value, type }, reqIndex) => (
                <Grid key={`req_value_${reqIndex}`} item xs={12} sm={6} md={4}>
                  <CustomTextInput
                    // required={!type.includes('additional')}
                    className={styles.inputItem}
                    error={errors[`requiredData.${reqIndex}.value`] || errors[`requiredData.${type}`] || false}
                    label={PASS_REQ_TYPES_MAP[type]}
                    value={value || ''}
                    autoComplete="off"
                    disabled={!updateAvailable}
                    inputProps={{
                      maxLength: [PASS_REQ_TYPES.phone, PASS_REQ_TYPES.additionalPhone].includes(type)
                        ? MAX_PHONE_NUMBER_LENGTH : 250,
                    }}
                    InputProps={{
                      endAdornment: type.includes('additional') ? (
                        <InputAdornment position="end">
                          <IconButton
                            disabled={!updateAvailable}
                            size="small"
                            onClick={() => {
                              const newReqValues = clone(fields.reqValues);
                              newReqValues[reqIndex].value = '';
                              updateState({
                                reqValues: newReqValues,
                              });
                            }}
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </InputAdornment>
                      ) : null,
                    }}
                    onChange={(e) => {
                      const { target } = e || {};
                      const {
                        value: newValue,
                      } = target || {};

                      const newReqValues = clone(fields.reqValues);

                      if ([PASS_REQ_TYPES.phone, PASS_REQ_TYPES.additionalPhone].includes(type)) {
                        newReqValues[reqIndex].value = formatPhoneNumberInput(newValue);
                      } else {
                        newReqValues[reqIndex].value = newValue;
                      }

                      updateState({
                        reqValues: newReqValues,
                      });
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid container className={styles.btnsContainer} spacing={2} item xs={12}>
            <Grid item>
              <CustomBtn
                type="submit"
                className={styles.btnSubmit}
                btnType="primaryBlue"
                disabled={!updateAvailable || isSaveLoading || isActivateLoading || isCancelLoading}
              >
                Сохранить
                {isSaveLoading && (
                  <CircularProgress
                    style={{ marginLeft: 5 }}
                    size={20}
                    color="inherit"
                  />
                )}
              </CustomBtn>
            </Grid>
            {hasTicketUpdateRight && status === PASSES_STATUSES.draft && (
              <Grid item>
                <CustomBtn
                  className={styles.btnSubmit}
                  btnType="primaryGreen"
                  onClick={handleActivateTicketClick}
                  disabled={isActivateLoading || isCancelLoading}
                >
                  <PlayArrowIcon style={{ marginRight: 5 }} fontSize="small" />
                  Активировать
                  {isActivateLoading && (
                    <CircularProgress
                      style={{ marginLeft: 5 }}
                      size={20}
                      color="inherit"
                    />
                  )}
                </CustomBtn>
              </Grid>
            )}
            {hasTicketUpdateRight && canBeCanceled && (
              <Grid item>
                <CustomBtn
                  className={styles.btnSubmit}
                  btnType="primaryRed"
                  onClick={handleCancelTicketClick}
                  disabled={isCancelLoading || isActivateLoading}
                >
                  <CancelIcon style={{ marginRight: 5 }} fontSize="small" />
                  Отменить
                  {isCancelLoading && (
                    <CircularProgress
                      style={{ marginLeft: 5 }}
                      size={20}
                      color="inherit"
                    />
                  )}
                </CustomBtn>
              </Grid>
            )}
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}

export default OneTicketPage;
