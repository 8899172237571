import React from 'react';
import { connect } from 'react-redux';

import Container from '@material-ui/core/Container';
import HeaderWithNavigationLinks from 'components/templates/HeaderWithNavigationLinks';

import { PATH_PAGES, RIGHT_KEYS } from 'config/constants';

import { useCurrentFacilityMatch } from 'helpers/routerHooks';
import { useCheckFacilityRights, useCommonRights } from 'helpers/hooks';
import Passes from 'components/organisms/Passes';
import { useStyles } from './styles';

function AllPassesPage({
  match,
}) {
  const styles = useStyles();
  useCurrentFacilityMatch();

  const { params: matchParams } = match || {};
  const {
    facilityId: currentFacilityId,
  } = matchParams || {};

  const hasCustomerShowRight = useCheckFacilityRights(RIGHT_KEYS.customerShow, currentFacilityId);
  const hasTicketShowRight = useCheckFacilityRights(RIGHT_KEYS.ticketUpdate, currentFacilityId);
  const hasBlackListShowRight = useCommonRights(RIGHT_KEYS.blackListShow);

  return (
    <Container className={styles.container}>
      <HeaderWithNavigationLinks
        links={[
          hasCustomerShowRight
            ? { to: PATH_PAGES.customers.replace(':facilityId', currentFacilityId), label: 'Клиенты' }
            : null,
          hasTicketShowRight
            ? { to: PATH_PAGES.tickets.replace(':facilityId', currentFacilityId), label: 'Пропуска' }
            : null,
          { to: PATH_PAGES.passes.replace(':facilityId', currentFacilityId), label: 'Абонементы' },
          hasBlackListShowRight
            ? { to: PATH_PAGES.blackList, label: 'Черный список' }
            : null,
        ]}
      />
      <div className={styles.pageBody}>
        <Passes
          facilityId={currentFacilityId}
          showControls
          showPagination
        />
      </div>
    </Container>
  );
}

function mapStateToProps(state) {
  const { currentFacility } = state || {};
  const { operators } = state || {};
  const { currentFacility: currentFacilityState } = currentFacility || {};
  const { currentOperator: currentOperatorState } = operators || {};

  return {
    currentFacilityState,
    currentOperatorState,
  };
}

const ConnectedAllCustomersPage = connect(
  mapStateToProps,
  {},
)(AllPassesPage);

export default ConnectedAllCustomersPage;
