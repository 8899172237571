import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import PlusIcon from '@parkly/shared/components/atoms/icons/PlusIcon';
import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import { formatPhoneNumber, useModal } from '@parkly/shared/helpers';
import { CONTROLLER_TYPES, RIGHT_KEYS } from 'config/constants';
import CustomLink from '@parkly/shared/components/atoms/CustomLink';
import Typography from '@material-ui/core/Typography';
import CameConnectIcon from '@parkly/shared/components/atoms/icons/CameConnectIcon';
import GSMIcon from '@parkly/shared/components/atoms/icons/GSMIcon';
import VideocamIcon from '@material-ui/icons/Videocam';
import LaurentIcon from '@parkly/shared/components/atoms/icons/LaurentIcon';
import LedMatrixIcon from '@parkly/shared/components/atoms/icons/LedMatrixIcon';
import ZKTecoIcon from '@parkly/shared/components/atoms/icons/ZKTecoIcon';
import CustomDialog from '@parkly/shared/components/templates/CustomDialog';
import Divider from '@material-ui/core/Divider';
import OpenVpnList from 'components/organisms/OpenVpnList';

import { useCheckFacilityRights } from 'helpers/hooks';
import { getControllersAction, removeControllerAction } from 'actions/controllers';
import { useStyles } from './styles';

import MakeGSMControllerModalContent from './MakeControllersModalContent/MakeGSMControllerModalContent';
import MakeCameConnectControllerModalContent from './MakeControllersModalContent/MakeCameConnectControllerModalContent';
import MakeBewardCameraControllerModalContent from './MakeControllersModalContent/MakeBewardCameraControllerModalContent';
import MakeLaurentControllerModalContent from './MakeControllersModalContent/MakeLaurentControllerModalContent';
import MakeLedMatrixControllerModalContent from './MakeControllersModalContent/MakeLedMatrixControllerModalContent';
import MakeZKTecoControllerModalContent from './MakeControllersModalContent/MakeZKTecoCameraControllerModalContent';

function getCurrentControllers({
  match,
  allControllersState,
}) {
  const { facilityId } = (match || {}).params || {};

  const {
    loading: isLoading,
    list: controllersList,
    error: allControllersError,
  } = allControllersState || {};

  const currentStrParam = JSON.stringify({
    facilityId,
  });

  const currentControllers = controllersList.find((item) => {
    const { strParam } = item || {};
    const isCurrent = strParam === currentStrParam;

    return isCurrent;
  });

  return {
    isLoading,
    currentControllers,
    allControllersError,
  };
}

function ControllersIntegrationSetting({
  match,

  getControllersReg,
  allControllersState,

  removeControllerReg,
}) {
  const styles = useStyles();
  const { facilityId } = (match || {}).params || {};

  const [selController, setSelController] = useState(null);

  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState(false);
  const [selForRemove, setSelForRemove] = useState(null);

  const hasAutomationUpdateRight = useCheckFacilityRights(RIGHT_KEYS.automationUpdate, facilityId);

  useEffect(() => {
    if (!facilityId) {
      return;
    }

    const {
      isLoading,
      currentControllers,
      allControllersError,
    } = getCurrentControllers({
      match,
      allControllersState,
    });

    if (isLoading || currentControllers || allControllersError) {
      return;
    }

    getControllersReg({ facilityId });
  }, [allControllersState, facilityId, getControllersReg, match]);

  const [
    makeGSMControllerModal,
    onOpenMakeGSMControllerModal,
    onHideMakeGSMControllerModal,
  ] = useModal({
    content: (
      <MakeGSMControllerModalContent
        facilityId={facilityId}
        id={selController}
        onClose={() => {
          onHideMakeGSMControllerModal();
        }}
      />
    ),
  });

  const [
    makeCameConnectControllerModal,
    onOpenMakeCameConnectControllerModal,
    onHideMakeCameConnectControllerModal,
  ] = useModal({
    content: (
      <MakeCameConnectControllerModalContent
        facilityId={facilityId}
        id={selController}
        onClose={() => {
          onHideMakeCameConnectControllerModal();
          getControllersReg({ facilityId });
        }}
      />
    ),
  });

  const [
    makeBewardControllerModal,
    onOpenMakeBewardControllerModal,
    onHideMakeBewardControllerModal,
  ] = useModal({
    content: (
      <MakeBewardCameraControllerModalContent
        facilityId={facilityId}
        id={selController}
        onClose={() => {
          onHideMakeBewardControllerModal();
          getControllersReg({ facilityId });
        }}
      />
    ),
  });

  const [
    makeLaurentControllerModal,
    onOpenMakeLaurentControllerModal,
    onHideLaurentControllerModal,
  ] = useModal({
    content: (
      <MakeLaurentControllerModalContent
        facilityId={facilityId}
        id={selController}
        onClose={() => {
          onHideLaurentControllerModal();
          getControllersReg({ facilityId });
        }}
      />
    ),
  });

  const [
    makeLedMatrixControllerModal,
    onOpenMakeLedMatrixControllerModal,
    onHideLedMatrixControllerModal,
  ] = useModal({
    content: (
      <MakeLedMatrixControllerModalContent
        facilityId={facilityId}
        id={selController}
        onClose={() => {
          onHideLedMatrixControllerModal();
          getControllersReg({ facilityId });
        }}
      />
    ),
  });

  const [
    makeZKTecoControllerModal,
    onOpenMakeZKTecoControllerModal,
    onHideZKTecoControllerModal,
  ] = useModal({
    content: (
      <MakeZKTecoControllerModalContent
        facilityId={facilityId}
        id={selController}
        onClose={() => {
          onHideZKTecoControllerModal();
          getControllersReg({ facilityId });
        }}
      />
    ),
  });

  const {
    currentControllers,
    isLoading,
  } = getCurrentControllers({
    match,
    allControllersState,
  });

  const {
    data: controllersData,
  } = currentControllers || {};

  const createUpdateControllerHandler = (id, type) => {
    if (!hasAutomationUpdateRight) {
      return () => {};
    }

    return () => {
      setSelController(id);

      if (type === CONTROLLER_TYPES.gsm) {
        onOpenMakeGSMControllerModal();
      }

      if (type === CONTROLLER_TYPES.cameConnect) {
        onOpenMakeCameConnectControllerModal();
      }

      if (type === CONTROLLER_TYPES.bewardCamera) {
        onOpenMakeBewardControllerModal();
      }

      if (type === CONTROLLER_TYPES.laurent) {
        onOpenMakeLaurentControllerModal();
      }

      if (type === CONTROLLER_TYPES.ledMatrix) {
        onOpenMakeLedMatrixControllerModal();
      }

      if (type === CONTROLLER_TYPES.zktecoCamera) {
        onOpenMakeZKTecoControllerModal();
      }
    };
  };

  const createMakeControllerHandler = (type) => {
    if (!hasAutomationUpdateRight) {
      return () => {};
    }

    return () => {
      setSelController(null);

      if (type === CONTROLLER_TYPES.gsm) {
        onOpenMakeGSMControllerModal();
      }

      if (type === CONTROLLER_TYPES.cameConnect) {
        onOpenMakeCameConnectControllerModal();
      }

      if (type === CONTROLLER_TYPES.bewardCamera) {
        onOpenMakeBewardControllerModal();
      }

      if (type === CONTROLLER_TYPES.laurent) {
        onOpenMakeLaurentControllerModal();
      }

      if (type === CONTROLLER_TYPES.ledMatrix) {
        onOpenMakeLedMatrixControllerModal();
      }

      if (type === CONTROLLER_TYPES.zktecoCamera) {
        onOpenMakeZKTecoControllerModal();
      }
    };
  };

  const createOpenRemoveDialogHandler = (id) => {
    if (!hasAutomationUpdateRight) {
      return () => {};
    }

    return () => {
      setSelForRemove(id);
      setIsRemoveDialogOpen(true);
    };
  };

  const handleAgreeRemoveDialog = useCallback(() => {
    removeControllerReg({
      id: selForRemove,
      facilityId,
    });
    setSelForRemove(null);
    setIsRemoveDialogOpen(false);
  }, [facilityId, removeControllerReg, selForRemove]);

  const handleCloseRemoveDialog = useCallback(() => {
    setSelForRemove(null);
    setIsRemoveDialogOpen(false);
  }, []);

  if (isLoading) {
    return (
      <CircularIndeterminate style={{ minHeight: 600 }} />
    );
  }
  const ControllerComponent = hasAutomationUpdateRight ? CustomLink : Typography;

  return (
    <Grid container spacing={2} direction="column">
      {hasAutomationUpdateRight && (
        <Grid container item spacing={2}>
          <Grid item>
            <CustomBtn
              btnType="primaryGreen"
              endIcon={<PlusIcon stroke="white" />}
              onClick={createMakeControllerHandler(CONTROLLER_TYPES.gsm)}
            >
              GSM-реле
            </CustomBtn>
          </Grid>
          <Grid item>
            <CustomBtn
              btnType="primaryGreen"
              endIcon={<PlusIcon stroke="white" />}
              onClick={createMakeControllerHandler(CONTROLLER_TYPES.cameConnect)}
            >
              CAME Connect
            </CustomBtn>
          </Grid>
          <Grid item>
            <CustomBtn
              btnType="primaryGreen"
              endIcon={<PlusIcon stroke="white" />}
              onClick={createMakeControllerHandler(CONTROLLER_TYPES.bewardCamera)}
            >
              Beward IP camera
            </CustomBtn>
          </Grid>
          <Grid item>
            <CustomBtn
              btnType="primaryGreen"
              endIcon={<PlusIcon stroke="white" />}
              onClick={createMakeControllerHandler(CONTROLLER_TYPES.laurent)}
            >
              Laurent
            </CustomBtn>
          </Grid>
          <Grid item>
            <CustomBtn
              btnType="primaryGreen"
              endIcon={<PlusIcon stroke="white" />}
              onClick={createMakeControllerHandler(CONTROLLER_TYPES.ledMatrix)}
            >
              LED Matrix
            </CustomBtn>
          </Grid>
          <Grid item>
            <CustomBtn
              btnType="primaryGreen"
              endIcon={<PlusIcon stroke="white" />}
              onClick={createMakeControllerHandler(CONTROLLER_TYPES.zktecoCamera)}
            >
              ZKTeco
            </CustomBtn>
          </Grid>
        </Grid>
      )}
      <Grid container item spacing={2} direction="column">
        {(controllersData || []).map(({
          id, name, type, settings,
        }) => (
          <Grid item key={id} className={styles.controllerItem}>
            <ControllerComponent
              className={styles.controllerLink}
              onClick={createUpdateControllerHandler(id, type)}
            >
              {type === CONTROLLER_TYPES.cameConnect && (
                <>
                  <CameConnectIcon />
                  {name}
                </>
              )}
              {type === CONTROLLER_TYPES.gsm && (
                <>
                  <GSMIcon />
                  {name}
                  {`  ${formatPhoneNumber(settings.phoneNumber)}`}
                </>
              )}
              {type === CONTROLLER_TYPES.laurent && (
                <>
                  <LaurentIcon />
                  {name}
                </>
              )}
              {type === CONTROLLER_TYPES.ledMatrix && (
                <>
                  <LedMatrixIcon />
                  {name}
                </>
              )}
              {type === CONTROLLER_TYPES.zktecoCamera && (
                <>
                  <ZKTecoIcon />
                  {name}
                </>
              )}
              {type === CONTROLLER_TYPES.bewardCamera && (
                <>
                  <VideocamIcon htmlColor="black" />
                  {name}
                </>
              )}
            </ControllerComponent>
            {hasAutomationUpdateRight && (
              <IconButton
                size="small"
                className={styles.iconButton}
                onClick={createOpenRemoveDialogHandler(id)}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            )}
          </Grid>
        ))}
      </Grid>
      <Divider />
      <Grid container item spacing={2}>
        <OpenVpnList facilityId={facilityId} />
      </Grid>
      {makeGSMControllerModal}
      {makeCameConnectControllerModal}
      {makeBewardControllerModal}
      {makeLaurentControllerModal}
      {makeLedMatrixControllerModal}
      {makeZKTecoControllerModal}
      <CustomDialog
        open={isRemoveDialogOpen}
        handleAgree={handleAgreeRemoveDialog}
        handleClose={handleCloseRemoveDialog}
        dialogContent="Вы уверены что хотите удалить контроллер?"
        agreeTitle="Удалить"
        disagreeTitle="Отмена"
      />
    </Grid>
  );
}

function mapStateToProps(state) {
  const { controllers, operators } = state || {};
  const { allControllers } = controllers || {};
  const { currentOperator: currentOperatorState } = operators || {};

  return {
    allControllersState: allControllers,
    currentOperatorState,
  };
}

const ConnectedControllersIntegrationSetting = connect(
  mapStateToProps,
  {
    getControllersReg: getControllersAction,
    removeControllerReg: removeControllerAction,
  },
)(ControllersIntegrationSetting);

export default ConnectedControllersIntegrationSetting;
