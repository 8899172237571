import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 8,
  },
  title: {
    fontWeight: 700,
    fontSize: 18,
    paddingLeft: 16,
  },
}));

export default {
  useStyles,
};
