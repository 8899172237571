import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, CircularProgress, Grid } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import FastForwardIcon from '@material-ui/icons/FastForward';
import PaymentIcon from '@material-ui/icons/Payment';
import { useQueryClient } from '@tanstack/react-query';
import moment from 'moment-timezone';

import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import CustomLink from '@parkly/shared/components/atoms/CustomLink';
import CircleIcon from '@parkly/shared/components/atoms/icons/CircleIcon';
import GridTitleBase from '@parkly/shared/components/molecules/GridTitleBase';
import HeaderWithBackBtn from '@parkly/shared/components/molecules/HeaderWithBackBtn';
import { formatPhoneNumber, getApiErrorMsg, useModal } from '@parkly/shared/helpers';

import {
  cancelPassAction,
  clearPassesCacheAction,
  getOnePassInfoAction,
  payPassAction,
  sendPassLinkToCustomerAction,
} from 'actions/passes';
import CancelPassModalContent from 'components/organisms/CancelPassModalContent';
import ManualPayPassModalContent from 'components/organisms/ManualPayPassModalContent';
import PaymentsContent from 'components/organisms/Payments/PaymentsContent';
import RenewPassModalContent from 'components/organisms/RenewPassModalContent';
import SessionsContent from 'components/organisms/Sessions/SessionsContent';
import {
  PASS_REQ_TYPES,
  PASSES_STATUSES,
  PASSES_STATUSES_COLOR_MAP,
  PASSES_STATUSES_MAP,
  PATH_PAGES,
  RIGHT_KEYS,
} from 'config/constants';
import { useCheckFacilityRights } from 'helpers/hooks';
import { useCurrentFacilityMatch } from 'helpers/routerHooks';

import { useStyles } from './styles';

function getOnePass({
  onePassState,
  match,
}) {
  const passId = ((match || {}).params || {}).id;

  const { list } = onePassState || {};
  const currentPass = (list || []).find(({ id } = {}) => {
    // eslint-disable-next-line
    const isNeeded = ('' + id) === ('' + passId);

    return isNeeded;
  });

  return {
    passId,
    currentPass,
  };
}

function OnePassInfoPage({
  match,

  getOnePassReg,
  onePassState,

  cancelPassReg,
  cancelPassState,

  sendPassLinkToCustomerReg,

  clearPassesCacheReg,
}) {
  const { t } = useTranslation();
  const styles = useStyles();
  const history = useHistory();
  const queryClient = useQueryClient();
  useCurrentFacilityMatch(PATH_PAGES.passes);
  const { params, path } = match || {};
  const {
    id: passId,
    facilityId,
  } = params || {};

  const hasPassUpdateRight = useCheckFacilityRights(RIGHT_KEYS.passUpdate, facilityId);
  const hasPassCancelRight = useCheckFacilityRights(RIGHT_KEYS.passCancel, facilityId);
  const hasPassManualPaymentRight = useCheckFacilityRights(RIGHT_KEYS.passManualPayment, facilityId);
  const hasPassTypeShowRight = useCheckFacilityRights(RIGHT_KEYS.passTypeShow, facilityId);
  const hasCustomerShowRight = useCheckFacilityRights(RIGHT_KEYS.customerShow, facilityId);
  const hasSessionShowRight = useCheckFacilityRights(RIGHT_KEYS.sessionShow, facilityId);
  const hasPaymentsShowRight = useCheckFacilityRights(RIGHT_KEYS.paymentsShow, facilityId);

  useEffect(() => {
    const {
      currentPass,
    } = getOnePass({
      onePassState,
      match,
    });

    const { loading: isOneCustomerLoading } = onePassState || {};
    if (!isOneCustomerLoading && !currentPass) {
      getOnePassReg({
        id: passId,
        facilityId,
      });
    }
  }, [facilityId, getOnePassReg, match, onePassState, passId]);

  const {
    currentPass,
  } = getOnePass({
    onePassState,
    match,
  });

  const {
    number,
    status,
    customer,
    passType,
    place,
    startTime,
    finishTime,
    url,
    price,
    requiredData,
    sessions,
    payments,
    canBeRenewal,
  } = currentPass || {};

  const {
    id: customerId,
    name: customerName,
  } = customer || {};

  const {
    name: placeName,
    areaName,
  } = place || {};

  const {
    id: passTypeId,
    name: passTypeName,
  } = passType || {};

  const [payModalNode, openPayModal, hidePayModal] = useModal({
    content: <ManualPayPassModalContent
      facilityId={facilityId}
      passId={passId}
      price={price}
      onComplete={() => {
        hidePayModal();
        clearPassesCacheReg();
      }}
      onClose={() => {
        hidePayModal();
      }}
    />,
  });

  const [renewModalNode, openRenewModal, hideRenewModal] = useModal({
    content: <RenewPassModalContent
      facilityId={facilityId}
      passId={passId}
      onComplete={() => {
        hideRenewModal();
        clearPassesCacheReg();
      }}
      onClose={() => {
        hideRenewModal();
      }}
    />,
  });

  const [cancelModalNode, openCancelModal, hideCancelModal] = useModal({
    content: <CancelPassModalContent
      facilityId={facilityId}
      passId={passId}
      availableCancelPayment={status === PASSES_STATUSES.active}
      onComplete={() => {
        hideCancelModal();
        clearPassesCacheReg();
      }}
      onClose={() => {
        hideCancelModal();
      }}
    />,
  });

  const reqData = useMemo(() => {
    const result = {};
    (requiredData || []).forEach(({ type, value }) => {
      if (type.includes('additional')) {
        return;
      }

      result[type] = value;
    });

    return result;
  }, [requiredData]);

  const additionalPhones = useMemo(() => (requiredData || []).filter(({ type }) => type === PASS_REQ_TYPES.additionalPhone), [requiredData]);

  const additionalPlateNumbers = useMemo(() => (requiredData || []).filter(({ type }) => type === PASS_REQ_TYPES.additionalPlateNumber), [requiredData]);

  const {
    phone,
    fio,
    carModel,
    plateNumber,
    stsNumber,
    idNumber,
  } = reqData || {};

  const { loading: isCancelLoading } = cancelPassState || {};

  if (
    !currentPass
    && (onePassState || {}).loading
  ) {
    return (
      <CircularIndeterminate style={{ minHeight: 600 }} />
    );
  }

  const { error: currentPassError } = currentPass || {};

  if (currentPassError) {
    const currentPassErrorMsg = getApiErrorMsg(currentPassError);
    return (
      <Container className={styles.container}>
        <HeaderWithBackBtn
          title="Абонемент"
        />
        <Typography>{currentPassErrorMsg}</Typography>
      </Container>
    );
  }

  return (
    <Container className={styles.container} maxWidth="md">
      <HeaderWithBackBtn
        title={`Абонемент #${number ? number.substring(9) : number} `}
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid className={styles.gridContainer} container item spacing={3} xs={12} md={7}>
            <GridTitleBase
              classNameContainer={styles.itemContainer}
              title="Тип абонемента"
              titleSm={5}
            >
              {hasPassTypeShowRight ? (
                <CustomLink
                  href={PATH_PAGES.onePassSettings.replace(':facilityId', facilityId).replace(':passId', passTypeId)}
                  onClick={(e) => {
                    e.preventDefault();
                    history.push(PATH_PAGES.onePassSettings.replace(':facilityId', facilityId).replace(':passId', passTypeId));
                  }}
                >
                  {passTypeName || '-'}
                </CustomLink>
              ) : (
                <Typography>
                  {passTypeName || '-'}
                </Typography>
              )}
            </GridTitleBase>
            <GridTitleBase
              classNameContainer={styles.itemContainer}
              title="Статус"
              titleSm={5}
            >
              <Typography className={styles.statusStr}>
                <CircleIcon color={PASSES_STATUSES_COLOR_MAP[status] || 'grey'} />
                {PASSES_STATUSES_MAP[status] || '-'}
              </Typography>
            </GridTitleBase>
            <GridTitleBase
              classNameContainer={styles.itemContainer}
              title="Клиент"
              titleSm={5}
            >
              {hasCustomerShowRight ? (
                <CustomLink
                  href={PATH_PAGES.oneCustomerInfo.replace(':facilityId', facilityId).replace(':id', customerId)}
                  onClick={(e) => {
                    e.preventDefault();
                    history.push(PATH_PAGES.oneCustomerInfo.replace(':facilityId', facilityId).replace(':id', customerId));
                  }}
                >
                  {customerName || '-'}
                </CustomLink>
              ) : (
                <Typography>
                  {customerName || '-'}
                </Typography>
              )}
            </GridTitleBase>
            <GridTitleBase
              classNameContainer={styles.itemContainer}
              title={(
                <div className={styles.colFlex}>
                  <Typography className={styles.bold}>Начало</Typography>
                  <Typography>{moment(startTime).format('DD.MM.YYYY HH:mm')}</Typography>
                </div>
              )}
              titleSm={5}
            >
              <div className={styles.colFlex}>
                <Typography className={styles.bold}>Окончание</Typography>
                <Typography>{moment(finishTime).format('DD.MM.YYYY HH:mm')}</Typography>
              </div>
            </GridTitleBase>
            {placeName && (
              <GridTitleBase
                classNameContainer={styles.itemContainer}
                title="Машиноместо"
                titleSm={5}
              >
                <Typography>
                  {placeName}
                </Typography>
              </GridTitleBase>
            )}
            <GridTitleBase
              classNameContainer={styles.itemContainer}
              title="Стоимость"
              titleSm={5}
            >
              <Typography>
                {`${(+price || 0).toLocaleString()} ₽`}
              </Typography>
            </GridTitleBase>
            <GridTitleBase
              classNameContainer={styles.itemContainer}
              title={(
                <div className={styles.colFlex}>
                  {phone && <Typography>Номер телефона</Typography>}
                  {(additionalPhones || []).map(({}, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Typography key={`add-phone-title-${index}`}>Доп. номер телефона</Typography>
                  ))}
                  {fio && <Typography>ФИО</Typography>}
                  {plateNumber && <Typography>Госномер авто</Typography>}
                  {(additionalPlateNumbers || []).map(({}, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Typography key={`add-plate-title-${index}`}>Доп. госномер авто</Typography>
                  ))}
                  {carModel && <Typography>Марка и модель авто</Typography>}
                  {stsNumber && <Typography>Номер СТС</Typography>}
                  {idNumber && <Typography>Номер паспорта</Typography>}
                </div>
              )}
              titleSm={5}
            >
              <div className={styles.colFlex}>
                {phone && <Typography>{formatPhoneNumber(phone)}</Typography>}
                {(additionalPhones || []).map(({ value }, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Typography key={`add-phone-value-${index}`}>{formatPhoneNumber(value)}</Typography>
                ))}
                {fio && <Typography>{fio}</Typography>}
                {plateNumber && <Typography>{plateNumber}</Typography>}
                {(additionalPlateNumbers || []).map(({ value }, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Typography key={`add-plate-value-${index}`}>{value}</Typography>
                ))}
                {carModel && <Typography>{carModel}</Typography>}
                {stsNumber && <Typography>{stsNumber}</Typography>}
                {idNumber && <Typography>{idNumber}</Typography>}
              </div>
            </GridTitleBase>
            <GridTitleBase
              classNameContainer={styles.itemContainer}
              classNameTitleContainer={styles.itemTitleContainer}
              title="Ссылка"
              titleSm={5}
            >
              <div className={styles.rowFlex}>
                <CustomLink href={url}>
                  Для оплаты/продления
                </CustomLink>
                <Button
                  style={{ textTransform: 'none' }}
                  size="small"
                  variant="outlined"
                  onClick={() => {
                    sendPassLinkToCustomerReg({ facilityId, id: passId });
                  }}
                >
                  Отправить клиенту
                </Button>
              </div>

            </GridTitleBase>
          </Grid>
        </Grid>
        <Grid container spacing={2} item xs={12}>
          {hasPassUpdateRight && [PASSES_STATUSES.active, PASSES_STATUSES.draft, PASSES_STATUSES.awaitingPayment].includes(status) && (
            <Grid item>
              <CustomBtn
                className={styles.btn}
                onClick={() => {
                  history.push(PATH_PAGES.onePass.replace(':facilityId', facilityId).replace(':id', passId));
                }}
              >
                <EditIcon style={{ marginRight: 5 }} fontSize="small" />
                Редактировать
              </CustomBtn>
            </Grid>
          )}
          {hasPassCancelRight && [PASSES_STATUSES.draft, PASSES_STATUSES.awaitingPayment, PASSES_STATUSES.active].includes(status) && (
            <Grid item>
              <CustomBtn
                className={styles.btn}
                btnType="primaryRed"
                onClick={openCancelModal}
                disabled={isCancelLoading}
              >
                <CancelIcon style={{ marginRight: 5 }} fontSize="small" />
                Отменить
                {isCancelLoading && (
                  <CircularProgress
                    style={{ marginLeft: 5 }}
                    size={20}
                    color="inherit"
                  />
                )}
              </CustomBtn>
            </Grid>
          )}
          {hasPassManualPaymentRight && status === PASSES_STATUSES.awaitingPayment && (
            <Grid item>
              <CustomBtn
                className={styles.btn}
                btnType="primaryBlue"
                onClick={openPayModal}
              >
                <PaymentIcon style={{ marginRight: 5 }} fontSize="small" />
                Добавить оплату
              </CustomBtn>
            </Grid>
          )}
          {hasPassManualPaymentRight && canBeRenewal && (
            <Grid item>
              <CustomBtn
                className={styles.btn}
                btnType="primaryBlue"
                onClick={openRenewModal}
              >
                <FastForwardIcon style={{ marginRight: 5 }} fontSize="small" />
                Продлить
              </CustomBtn>
            </Grid>
          )}
        </Grid>
        {hasSessionShowRight && (
          <Grid container spacing={2} item xs={12}>
            <Grid item xs={12}>
              <Typography className={styles.subTitle}>
                Последние сесcии
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <SessionsContent
                facilityId={facilityId}
                sessionsData={sessions || []}
                hideCheckbox
                hideCustomer
                onNeedUpdate={() => {
                  queryClient.invalidateQueries({ queryKey: ['customers'] });
                  queryClient.invalidateQueries({ queryKey: ['sessions'] });
                  queryClient.invalidateQueries({ queryKey: ['payments'] });
                }}
              />
            </Grid>
          </Grid>
        )}
        {hasPaymentsShowRight && (
          <Grid container spacing={2} item xs={12}>
            <Grid item xs={12}>
              <Typography className={styles.subTitle}>
                Последние оплаты
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <PaymentsContent
                facilityId={facilityId}
                paymentsData={payments || []}
                hideCustomer
                onNeedUpdate={() => {
                  queryClient.invalidateQueries({ queryKey: ['customers'] });
                  queryClient.invalidateQueries({ queryKey: ['sessions'] });
                  queryClient.invalidateQueries({ queryKey: ['payments'] });
                }}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      {payModalNode}
      {renewModalNode}
      {cancelModalNode}
    </Container>
  );
}

function mapStateToProps(state) {
  const { passes } = state || {};
  const {
    onePassInfo,
    setPass,
    activePassSettings,
    cancelPass,
    payPass,
  } = passes || {};

  return {
    onePassState: onePassInfo,
    setPassState: setPass,
    cancelPassState: cancelPass,
    payPassState: payPass,
    activePassSettingsState: activePassSettings,
  };
}

const ConnectedOneCustomerPage = connect(
  mapStateToProps,
  {
    getOnePassReg: getOnePassInfoAction,
    cancelPassReg: cancelPassAction,
    payPassReg: payPassAction,
    clearPassesCacheReg: clearPassesCacheAction,
    sendPassLinkToCustomerReg: sendPassLinkToCustomerAction,
  },
)(OnePassInfoPage);

export default ConnectedOneCustomerPage;
