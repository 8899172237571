/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { connect, useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import camelCase from 'lodash/camelCase';
import PropTypes from 'prop-types';

import { DEFAULT_PATH_PAGES } from '@parkly/shared/config/constants';

import { PATH_PAGES, RIGHT_KEYS } from 'config/constants';

const PAGES_RIGHTS_MAP = {
  [PATH_PAGES.operators]: RIGHT_KEYS.operatorUpdate,
  [PATH_PAGES.operatorsAll]: RIGHT_KEYS.operatorUpdate,
  [PATH_PAGES.operatorsActions]: RIGHT_KEYS.operatorUpdate,
  [PATH_PAGES.oneOperator]: RIGHT_KEYS.operatorUpdate,
  [PATH_PAGES.addNewOperator]: RIGHT_KEYS.operatorUpdate,
  [PATH_PAGES.account]: RIGHT_KEYS.accountUpdate,
  [PATH_PAGES.newFacilitySettings]: RIGHT_KEYS.admin,
  [PATH_PAGES.companySettings]: RIGHT_KEYS.facilityShow,
  [PATH_PAGES.facilitySettings]: RIGHT_KEYS.facilityShow,
  [PATH_PAGES.scheduleSettings]: RIGHT_KEYS.facilityShow,
  [PATH_PAGES.placesSettings]: RIGHT_KEYS.placesShow,
  [PATH_PAGES.integrationSettings]: RIGHT_KEYS.automationShow,
  [PATH_PAGES.passesSettings]: RIGHT_KEYS.passTypeShow,
  [PATH_PAGES.newPassSettings]: RIGHT_KEYS.passTypeUpdate,
  [PATH_PAGES.onePassSettings]: RIGHT_KEYS.passTypeShow,
  [PATH_PAGES.allPassesSettings]: RIGHT_KEYS.passTypeShow,
  [PATH_PAGES.ticketsSettings]: RIGHT_KEYS.ticketTypeShow,
  [PATH_PAGES.newTicketSettings]: RIGHT_KEYS.ticketTypeUpdate,
  [PATH_PAGES.oneTicketSettings]: RIGHT_KEYS.ticketTypeShow,
  [PATH_PAGES.allTicketsSettings]: RIGHT_KEYS.ticketTypeShow,
  [PATH_PAGES.allCustomers]: RIGHT_KEYS.customerShow,
  [PATH_PAGES.oneCustomer]: RIGHT_KEYS.customerUpdate,
  [PATH_PAGES.oneCustomerInfo]: RIGHT_KEYS.customerShow,
  [PATH_PAGES.addCustomer]: RIGHT_KEYS.customerUpdate,
  [PATH_PAGES.allSessions]: RIGHT_KEYS.sessionShow,
  [PATH_PAGES.allBlackList]: RIGHT_KEYS.blackListShow,
  [PATH_PAGES.oneBlackList]: RIGHT_KEYS.blackListUpdate,
  [PATH_PAGES.newBlackList]: RIGHT_KEYS.blackListUpdate,
  [PATH_PAGES.allTariffSettings]: RIGHT_KEYS.tariffShow,
  [PATH_PAGES.allTariffPlansSettings]: RIGHT_KEYS.tariffShow,
  [PATH_PAGES.tariffPlanSettings]: RIGHT_KEYS.tariffShow,
  [PATH_PAGES.tariffSettings]: RIGHT_KEYS.tariffShow,
  [PATH_PAGES.newTariffPlanSettings]: RIGHT_KEYS.tariffUpdate,
  [PATH_PAGES.newTariffSettings]: RIGHT_KEYS.tariffUpdate,
  [PATH_PAGES.oneTariffPlanSettings]: RIGHT_KEYS.tariffShow,
  [PATH_PAGES.oneTariffSettings]: RIGHT_KEYS.tariffShow,
  [PATH_PAGES.analytics]: RIGHT_KEYS.analyticsShow,
  [PATH_PAGES.analyticsv2]: RIGHT_KEYS.analyticsShow,
  [PATH_PAGES.allPasses]: RIGHT_KEYS.passShow,
  [PATH_PAGES.onePass]: RIGHT_KEYS.passShow,
  [PATH_PAGES.onePassInfo]: RIGHT_KEYS.passShow,
  [PATH_PAGES.addPass]: RIGHT_KEYS.passUpdate,
  [PATH_PAGES.allTickets]: RIGHT_KEYS.ticketUpdate,
  [PATH_PAGES.oneTicket]: RIGHT_KEYS.ticketUpdate,
  [PATH_PAGES.oneTicketInfo]: RIGHT_KEYS.ticketUpdate,
  [PATH_PAGES.addTicket]: RIGHT_KEYS.ticketUpdate,
  [PATH_PAGES.allGatewayEvents]: RIGHT_KEYS.gatewayLogShow,
};

function comparePath({ path, targetPath }) {
  if (Array.isArray(path)) {
    return path.includes(targetPath);
  }

  return path === targetPath;
}

function checkIsEnoughRights({ path, userRights = {} }) {
  if (typeof path === 'string') {
    const rightName = PAGES_RIGHTS_MAP[path];

    if (rightName) {
      const isRightPropName = camelCase(`is ${rightName}`);
      return userRights[isRightPropName];
    }
  }

  return true;
}

const propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  component: PropTypes.any.isRequired,
  isAuth: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  currentOperator: PropTypes.object,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

const PrivateRoute = ({
  component: Component, isAuth = false, currentOperatorState, path, computedMatch, ...rest
}) => {
  const { params, path: matchPath } = computedMatch || {};
  const { facilityId: matchFacilityId } = params || {};
  const currentStateFacilityId = useSelector((state) => (state.currentFacility.currentFacility.data
    ? state.currentFacility.currentFacility.data.id
    : null
  ));
  if (matchFacilityId && currentStateFacilityId && parseInt(matchFacilityId, 10) === 0) {
    return <Redirect to={{ pathname: matchPath.replace(':facilityId', currentStateFacilityId) }} />;
  }

  const { userRights } = currentOperatorState || {};
  const { isAdmin } = userRights || {};

  return (
    <Route
      path={path}
      computedMatch={computedMatch}
      {...rest}
      render={(props) => {
        if (!isAuth) {
          return (
            <Redirect
              to={{
                pathname: DEFAULT_PATH_PAGES.login,
                // eslint-disable-next-line react/prop-types
                state: { from: props.location },
              }}
            />
          );
        }

        const isEnoughRights = isAdmin || !!checkIsEnoughRights({
          path,
          userRights,
        });

        if (!isEnoughRights) {
          return <Redirect to={{ pathname: PATH_PAGES.notEnoughRights }} />;
        }

        return <Component {...props} />;
      }}
    />
  );
};

PrivateRoute.propTypes = propTypes;

function mapStateToProps(state) {
  const { loggedIn } = state.auth || {};
  const { operators } = state || {};
  const { currentOperator: currentOperatorState } = operators || {};

  return {
    isAuth: !!loggedIn,
    currentOperatorState,
  };
}

const connectedPrivateRoute = connect(mapStateToProps)(PrivateRoute);

export default connectedPrivateRoute;
