import React, { useCallback, useEffect, useState } from 'react';
import clone from 'lodash/clone';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Grid, Table, TableBody, TableContainer } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import TableCell from '@material-ui/core/TableCell';
import HeaderWithBackBtn from '@parkly/shared/components/molecules/HeaderWithBackBtn';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import { clearFacilitiesCacheAction, getFacilityScheduleAction, setFacilityScheduleAction } from 'actions/facilities';
import { useCurrentFacilityMatch } from 'helpers/routerHooks';

import { useStyles } from './styles';
import { useCheckFacilityRights } from '../../../../helpers/hooks';
import { RIGHT_KEYS } from '../../../../config/constants';

const DEFAULT_MATRIX = [
  [true, true, true, true, true, true, true],
  [true, true, true, true, true, true, true],
  [true, true, true, true, true, true, true],
  [true, true, true, true, true, true, true],
  [true, true, true, true, true, true, true],
  [true, true, true, true, true, true, true],
  [true, true, true, true, true, true, true],
  [true, true, true, true, true, true, true],
  [true, true, true, true, true, true, true],
  [true, true, true, true, true, true, true],
  [true, true, true, true, true, true, true],
  [true, true, true, true, true, true, true],
  [true, true, true, true, true, true, true],
  [true, true, true, true, true, true, true],
  [true, true, true, true, true, true, true],
  [true, true, true, true, true, true, true],
  [true, true, true, true, true, true, true],
  [true, true, true, true, true, true, true],
  [true, true, true, true, true, true, true],
  [true, true, true, true, true, true, true],
  [true, true, true, true, true, true, true],
  [true, true, true, true, true, true, true],
  [true, true, true, true, true, true, true],
  [true, true, true, true, true, true, true],
];

function getOneFacilitySchedule({
  oneFacilityScheduleState,
  match,
}) {
  const facilityId = ((match || {}).params || {}).facilityId;

  const { list } = oneFacilityScheduleState || {};
  const currentFacilitySchedule = (list || []).find(({ id } = {}) => {
    // eslint-disable-next-line
    const isNeeded = ('' + id) === ('' + facilityId);

    return isNeeded;
  });

  return {
    facilityId,
    currentFacilitySchedule,
  };
}

function ScheduleSettingsPage({
  match,

  getFacilityScheduleReg,
  oneFacilityScheduleState,

  setFacilityScheduleReg,
  setFacilityScheduleState,

  registrationStatusData,
}) {
  const styles = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  useCurrentFacilityMatch();

  const [matrix, setMatrix] = useState(clone(DEFAULT_MATRIX));

  const id = ((match || {}).params || {}).facilityId;

  const { isRegistrationCompleted } = registrationStatusData || {};

  const hasFacilityUpdateRight = useCheckFacilityRights(RIGHT_KEYS.facilityUpdate, id);

  useEffect(() => {
    const {
      loading: isLoading,
    } = oneFacilityScheduleState || {};

    if (isLoading) {
      return;
    }

    const {
      currentFacilitySchedule,
    } = getOneFacilitySchedule({
      oneFacilityScheduleState,
      match,
    });

    if (currentFacilitySchedule) {
      const {
        matrix,
      } = currentFacilitySchedule || {};

      if (!isEmpty(matrix)) {
        setMatrix(matrix);
      } else {
        setMatrix(clone(DEFAULT_MATRIX));
      }
      return;
    }

    getFacilityScheduleReg({ id });
  }, [getFacilityScheduleReg, id, match, oneFacilityScheduleState]);

  const handleSaveClick = useCallback(() => {
    if (!hasFacilityUpdateRight) {
      return;
    }

    setFacilityScheduleReg({ id, matrix }, history, !isRegistrationCompleted);
  }, [hasFacilityUpdateRight, setFacilityScheduleReg, id, matrix, history, isRegistrationCompleted]);

  function createWeekDayClickHandler(index) {
    if (!hasFacilityUpdateRight) {
      return () => {};
    }

    return () => {
      if (matrix[0][index] === true) {
        matrix.forEach((_, timeIndex) => {
          matrix[timeIndex][index] = false;
        });
      } else {
        matrix.forEach((_, timeIndex) => {
          matrix[timeIndex][index] = true;
        });
      }
      setMatrix(clone(matrix));
    };
  }

  function createTimeClickHandler(index) {
    if (!hasFacilityUpdateRight) {
      return () => {};
    }

    return () => {
      if (matrix[index][0] === true) {
        matrix[index] = [false, false, false, false, false, false, false];
      } else {
        matrix[index] = [true, true, true, true, true, true, true];
      }

      setMatrix(clone(matrix));
    };
  }

  function createItemClickHandler(timeIndex, weekDayIndex) {
    if (!hasFacilityUpdateRight) {
      return () => {};
    }

    return () => {
      matrix[timeIndex][weekDayIndex] = !matrix[timeIndex][weekDayIndex];

      setMatrix(clone(matrix));
    };
  }

  const handleAllClick = () => {
    if (!hasFacilityUpdateRight) {
      return () => {};
    }

    if (matrix[0][0] === false) {
      matrix.forEach((_, timeIndex) => {
        matrix[timeIndex] = [true, true, true, true, true, true, true];
      });
    } else {
      matrix.forEach((_, timeIndex) => {
        matrix[timeIndex] = [false, false, false, false, false, false, false];
      });
    }

    setMatrix(clone(matrix));
  };

  const {
    loading: isLoading,
  } = oneFacilityScheduleState || {};

  const {
    loading: isSaveLoading,
  } = setFacilityScheduleState || {};

  if (isLoading) {
    return (
      <div className={styles.container}>
        <HeaderWithBackBtn
          title={t('schedule.scheduleSetting')}
          isBackBtn={false}
        />
        <CircularIndeterminate style={{ minHeight: 600 }} />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <HeaderWithBackBtn
        title={t('schedule.scheduleSetting')}
        isBackBtn={false}
      />
      <Grid container item spacing={2} xs={9}>
        <Grid item xs={12} direction="column">
          <TableContainer className={styles.tableContainer}>
            <Table
              size="small"
              stickyHeader
              padding="checkbox"
              aria-label="sticky table dense"
            >
              <TableHead>
                <TableRow>
                  <TableCell className={styles.head} align="center" onClick={handleAllClick} />
                  <TableCell className={styles.head} align="center" onClick={createWeekDayClickHandler(0)}>
                    ПН
                  </TableCell>
                  <TableCell className={styles.head} align="center" onClick={createWeekDayClickHandler(1)}>
                    ВТ
                  </TableCell>
                  <TableCell className={styles.head} align="center" onClick={createWeekDayClickHandler(2)}>
                    СР
                  </TableCell>
                  <TableCell className={styles.head} align="center" onClick={createWeekDayClickHandler(3)}>
                    ЧТ
                  </TableCell>
                  <TableCell className={styles.head} align="center" onClick={createWeekDayClickHandler(4)}>
                    ПТ
                  </TableCell>
                  <TableCell className={styles.head} align="center" onClick={createWeekDayClickHandler(5)}>
                    СБ
                  </TableCell>
                  <TableCell className={styles.head} align="center" onClick={createWeekDayClickHandler(6)}>
                    ВС
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {matrix.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell className={styles.head} align="center" onClick={createTimeClickHandler(index)}>
                      {('' + index).padStart(2, '0') + ':00'}
                    </TableCell>
                    <TableCell onClick={createItemClickHandler(index, 0)} className={[styles.body, item[0] ? styles.selected : ''].join(' ')} />
                    <TableCell onClick={createItemClickHandler(index, 1)} className={[styles.body, item[1] ? styles.selected : ''].join(' ')} />
                    <TableCell onClick={createItemClickHandler(index, 2)} className={[styles.body, item[2] ? styles.selected : ''].join(' ')} />
                    <TableCell onClick={createItemClickHandler(index, 3)} className={[styles.body, item[3] ? styles.selected : ''].join(' ')} />
                    <TableCell onClick={createItemClickHandler(index, 4)} className={[styles.body, item[4] ? styles.selected : ''].join(' ')} />
                    <TableCell onClick={createItemClickHandler(index, 5)} className={[styles.body, item[5] ? styles.selected : ''].join(' ')} />
                    <TableCell onClick={createItemClickHandler(index, 6)} className={[styles.body, item[6] ? styles.selected : ''].join(' ')} />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {hasFacilityUpdateRight && (
          <Grid item xs={3}>
            <CustomBtn
              className={styles.btnSubmit}
              btnType="primaryBlue"
              onClick={handleSaveClick}
              disabled={isSaveLoading}
            >
              Сохранить
            </CustomBtn>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

function mapStateToProps(state) {
  const { facilities, companies } = state || {};
  const { oneFacilitySchedule, setFacilitySchedule } = facilities || {};
  const { registrationStatus } = companies || {};

  const { data: registrationStatusData } = registrationStatus || {};

  return {
    registrationStatusData,
    oneFacilityScheduleState: oneFacilitySchedule,
    setFacilityScheduleState: setFacilitySchedule,
  };
}

const ConnectedScheduleSettingsPage = connect(
  mapStateToProps,
  {
    getFacilityScheduleReg: getFacilityScheduleAction,
    setFacilityScheduleReg: setFacilityScheduleAction,
    clearFacilitiesCache: clearFacilitiesCacheAction,
  },
)(ScheduleSettingsPage);

export default ConnectedScheduleSettingsPage;
