import React from 'react';
import { createPortal } from 'react-dom';
import { SECTIONS } from 'config/constants';
import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import Container from '@material-ui/core/Container';

export default function FiltersContainer({
  children,
}) {
  const theme = useTheme();
  const matchDownXs = useMediaQuery(theme.breakpoints.down('xs'), { defaultMatches: false });

  if (matchDownXs) {
    return children;
  }

  const content = (
    <div style={{ padding: '16px 32px 0 32px', marginBottom: 8 }}>
      <Container style={{ padding: 0, marginLeft: 0 }}>
        {children}
      </Container>
    </div>

  );

  return createPortal(content, document.getElementById(SECTIONS.filters));
}
