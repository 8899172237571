import { createQuery } from 'react-query-kit';
import { getAnalytic } from 'api/analytics';

export const useAnalytic = createQuery({
  primaryKey: 'analytics',
  queryFn: ({ queryKey: [_, params] }) => {
    return getAnalytic(params);
  },
  retry: false,
});
