import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import { Autocomplete } from '@material-ui/lab';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import HeaderWithBackBtn from 'components/molecules/HeaderWithBackBtn';
import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';
import CustomLink from '@parkly/shared/components/atoms/CustomLink';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import {
  checkIsPhoneValid,
  cleanPhone,
  formatPhoneNumber,
  formatPhoneNumberInput,
  useFormFields,
  useModal,
} from '@parkly/shared/helpers';
import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import YandexMapSelectCoordsModalContent from 'components/organisms/YandexMapSelectCoordsModalContent';

import {
  clearFacilitiesCacheAction,
  createFacilityAction,
  getFacilitiesAction,
  getFacilityAction,
  updateFacilityAction,
} from 'actions/facilities';
import { getAvailableLicensesAction } from 'actions/licenses';

import { LICENSE_TARIFF_TYPES_MAP, PATH_PAGES, RIGHT_KEYS } from 'config/constants';
import { formatTimezoneName } from 'helpers/timezones';
import { useCurrentFacilityMatch } from 'helpers/routerHooks';
import isEmpty from 'lodash/isEmpty';
import { useCheckFacilityRights, useCommonRights } from 'helpers/hooks';
import { useStyles } from './styles';
import { MAX_PHONE_NUMBER_LENGTH } from '@parkly/shared/config/constants';


const AVAILABLE_BADGES = [
  { title: 'Наземная' },
  { title: 'Наземная крытая' },
  { title: 'Подземная' },
];

function formatValue(name, value) {
  if (name === 'phone') {
    return formatPhoneNumberInput(value);
  }

  return value;
}

function getOneFacility({
  oneFacilityState,
  match,
}) {
  const facilityId = ((match || {}).params || {}).facilityId;

  const { list } = oneFacilityState || {};
  const currentFacility = (list || []).find(({ id } = {}) => {
    // eslint-disable-next-line
    const isNeeded = ('' + id) === ('' + facilityId);

    return isNeeded;
  });

  return {
    facilityId,
    currentFacility,
  };
}

function getAvailableLicenses({
  availableLicensesState,
}) {
  const {
    list,
    loading,
    error,
  } = availableLicensesState || {};

  const currentStrParam = JSON.stringify({});
  const availableLicenses = (list || []).find((item) => {
    const { strParam } = item || {};
    const isCurrent = strParam === currentStrParam;

    return isCurrent;
  });

  return {
    availableLicenses,
    isAvailableLicensesLoading: loading,
    availableLicensesError: error,
  };
}

function FacilitySettingsPage({
  match,

  createFacilityReq,
  createFacilityState,
  updateFacilityReq,
  getFacilityReg,
  oneFacilityState,

  availableLicensesState,
  getAvailableLicensesReq,

  registrationStatusData,

  currentOperatorState,
}) {
  const styles = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  useCurrentFacilityMatch();

  const [fields, errors, onChange, setErrors, updateState] = useFormFields({
    initValues: {
      title: '',
      address: '',
      officeNumber: '',
      lat: 0,
      lon: 0,
      license: null,
      timezone: {
        id: 'Europe/Moscow',
        title: formatTimezoneName({ name: 'Europe/Moscow' }),
      },
      badges: [],
      maxHeight: '',
      maxWidth: '',
      maxLength: '',
      maxWeight: '',
      rulesText: '',
      phone: '+7',
    },
    formatValueFunc: formatValue,
  });
  const [country, setCountry] = useState('RU');

  const { params, path } = match || {};
  const {
    facilityId: id,
  } = params || {};

  const isNew = !id && path === PATH_PAGES.newFacilitySettings;

  const { isRegistrationCompleted } = registrationStatusData || {};

  const hasOldFacilityUpdateRight = useCheckFacilityRights(RIGHT_KEYS.facilityUpdate, id);
  const isAdmin = useCommonRights(RIGHT_KEYS.admin);

  const hasFacilityUpdateRight = isNew ? isAdmin : hasOldFacilityUpdateRight;

  useEffect(() => {
    if (isNew) {
      return;
    }

    const {
      loading: isLoading,
    } = oneFacilityState || {};

    if (isLoading) {
      return;
    }

    const {
      currentFacility,
    } = getOneFacility({
      oneFacilityState,
      match,
    });

    if (currentFacility && currentFacility.error) {
      return;
    }

    if (currentFacility) {
      const badges = currentFacility.badges.map((item) => ({
        title: item,
      }));

      updateState({
        title: currentFacility.title,
        address: currentFacility.address,
        lat: currentFacility.lat,
        lon: currentFacility.lon,
        badges,
        maxHeight: currentFacility.maxHeight || '',
        maxWidth: currentFacility.maxWidth || '',
        maxLength: currentFacility.maxLength || '',
        maxWeight: currentFacility.maxWeight || '',
        rulesText: currentFacility.rulesText || '',
        phone: formatPhoneNumber(currentFacility.phone),
        timezone: {
          id: currentFacility.timezone,
          title: formatTimezoneName({ name: currentFacility.timezone }),
        },
      });
      return;
    }

    getFacilityReg({ id });
  }, [getFacilityReg, id, isNew, match, oneFacilityState, updateState]);

  useEffect(() => {
    if (!isNew) {
      return;
    }

    const {
      availableLicenses,
      isAvailableLicensesLoading,
      availableLicensesError,
    } = getAvailableLicenses({
      availableLicensesState,
    });

    if (availableLicenses || isAvailableLicensesLoading || availableLicensesError) {
      return;
    }

    getAvailableLicensesReq({});
  }, [isNew, availableLicensesState, getAvailableLicensesReq]);

  const handleChangeBadges = useCallback((e, value) => {
    updateState({
      badges: value,
    });
    setErrors({
      badges: false,
    });
  }, []);

  const handleChangeTimezone = useCallback((e, value) => {
    updateState({
      timezone: value,
    });
    setErrors({
      timezone: false,
    });
  }, []);

  const handleChangeLicense = useCallback((e, value) => {
    updateState({
      license: value,
    });
    setErrors({
      license: false,
    });
  }, []);

  const [
    yaMapModalNode,
    onOpenYaMapModal,
    onHideYaMapModal,
  ] = useModal({
    content: (
      <YandexMapSelectCoordsModalContent
        initCoords={[fields.lat, fields.lon]}
        initAddress={fields.address}
        defaultCenter={fields.lat && fields.lon ? [fields.lat, fields.lon] : undefined}
        onClose={() => {
          onHideYaMapModal();
        }}
        onSubmit={({
          coords, address, country: c,
        }) => {
          if (coords && address) {
            const lat = coords[0];
            const lon = coords[1];

            updateState({
              address,
              lat,
              lon,
            });
            setErrors({
              address: false,
            });

            if (c !== country) {
              updateState({
                timezone: null,
              });
              setCountry(c);
            }
          }

          onHideYaMapModal();
        }}
      />
    ),
  });

  const onAddressClick = useCallback(() => {
    if (hasFacilityUpdateRight) {
      onOpenYaMapModal();
    }
  }, [onOpenYaMapModal, hasFacilityUpdateRight]);

  const onSubmit = useCallback((e) => {
    e.preventDefault();
    e.nativeEvent.preventDefault();

    if (!hasFacilityUpdateRight) {
      return;
    }

    const isTitleValid = fields.title.length > 0;
    const isAddressValid = fields.address.length > 0;
    const isLatValid = fields.lat !== 0;
    const isLonValid = fields.lon !== 0;
    const isLicenseValid = !!fields.license || !isNew;
    const isTimezoneValid = !!fields.timezone;
    const trimmedPhone = cleanPhone(fields.phone);
    const isPhoneValid = checkIsPhoneValid(trimmedPhone);

    if (
      !isTitleValid || !isAddressValid
      || !isLatValid || !isLonValid
      || !isPhoneValid || !isTimezoneValid
      || !isLicenseValid
    ) {
      setErrors({
        title: !isTitleValid,
        address: !isAddressValid || !isLatValid || !isLonValid,
        phone: !isPhoneValid,
        timezone: !isTimezoneValid,
        license: !isLicenseValid,
      });
      return;
    }

    const badgesArr = fields.badges.map((item) => item.title);

    if (isNew) {
      createFacilityReq({
        facilityData: {
          title: fields.title,
          address: fields.address,
          lat: fields.lat,
          lon: fields.lon,
          phone: trimmedPhone,
          badges: badgesArr,
          parkingRules: fields.rulesText,
          maxHeight: fields.maxHeight,
          maxWidth: fields.maxWidth,
          maxLength: fields.maxLength,
          maxWeight: fields.maxWeight,
          timezone: fields.timezone.id,
          licenseId: fields.license.id,
        },
        history,
      }, true);
      return;
    }

    updateFacilityReq({
      id,
      title: fields.title,
      address: fields.address,
      lat: fields.lat,
      lon: fields.lon,
      phone: trimmedPhone,
      badges: badgesArr,
      parkingRules: fields.rulesText,
      maxHeight: fields.maxHeight,
      maxWidth: fields.maxWidth,
      maxLength: fields.maxLength,
      maxWeight: fields.maxWeight,
      timezone: fields.timezone.id,
    });
  }, [createFacilityReq, fields, history, id, hasFacilityUpdateRight, isNew, setErrors, updateFacilityReq]);

  const timezones = useMemo(() => moment.tz.zonesForCountry(country, true)
    .sort(({ offset: a }, { offset: b }) => b - a)
    .map(({ name, offset }) => ({ id: name, title: formatTimezoneName({ name, offset }) })), [country]);

  const {
    availableLicenses,
    isAvailableLicensesLoading,
  } = getAvailableLicenses({ availableLicensesState });

  const {
    data: availableLicensesData,
  } = availableLicenses || {};

  const availableLicensesItems = useMemo(() => {

    return (availableLicensesData || []).map(({ id, number, tariffType }) => ({
      id,
      title: `Тариф ${LICENSE_TARIFF_TYPES_MAP[tariffType]} №${number}`,
    }));
  }, [availableLicensesData]);
  const {
    loading: isCreateLoading,
  } = createFacilityState || {};

  const addressStr = fields.address ? `${fields.address}` : '';

  const {
    loading: isLoading,
  } = oneFacilityState || {};

  if ((isLoading && !isNew) || isAvailableLicensesLoading) {
    return (
      <div className={styles.container}>
        <HeaderWithBackBtn
          title={isNew ? t('facility.newFacilitySetting') : t('facility.facilitySetting')}
          isBackBtn={false}
        />
        <CircularIndeterminate style={{ minHeight: 600 }} />
      </div>
    );
  }

  if (isNew && isEmpty(availableLicensesItems)) {
    return (
      <div className={styles.container}>
        <HeaderWithBackBtn
          title={isNew ? t('facility.newFacilitySetting') : t('facility.facilitySetting')}
          isBackBtn={false}
        />
        <Typography>
          Не удалось получить доступные лицензии. Обратитесь к своему менеджеру
        </Typography>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <HeaderWithBackBtn
        title={isNew ? t('facility.newFacilitySetting') : t('facility.facilitySetting')}
        isBackBtn={false}
      />
      <form
        className={styles.form}
        autoComplete="off"
        onSubmit={onSubmit}
        noValidate
      >
        <Grid container item direction="column" spacing={2} xs={12} lg={9}>
          <Grid container item spacing={2} alignItems="center">
            <Grid item xs={12}>
              <CustomTextInput
                className={styles.inputItem}
                error={errors.title}
                name="title"
                label={t('facility.title')}
                value={fields.title}
                autoComplete="off"
                required
                inputProps={{
                  maxLength: 250,
                }}
                disabled={!hasFacilityUpdateRight}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={9}>
              <CustomTextInput
                className={styles.inputItem}
                error={errors.address}
                name="address"
                label={t('facility.address')}
                value={addressStr}
                autoComplete="off"
                onClick={onAddressClick}
                required
              />
            </Grid>
            <Grid item xs={3}>
              {hasFacilityUpdateRight && (
                <CustomLink
                  className={styles.linkItem}
                  onClick={onAddressClick}
                >
                  Указать на карте
                </CustomLink>
              )}
            </Grid>
            {isNew && (
              <Grid item xs={8}>
                <Autocomplete
                  getOptionLabel={(option) => option.title}
                  getOptionSelected={(option, value) => option.id === value.id}
                  options={availableLicensesItems}
                  value={fields.license}
                  disabled={!hasFacilityUpdateRight}
                  onChange={handleChangeLicense}
                  renderInput={(params) => (
                    <CustomTextInput
                      /* eslint-disable-next-line react/jsx-props-no-spreading */
                      {...params}
                      className={styles.inputItem}
                      error={errors.license}
                      required
                      disabled={!hasFacilityUpdateRight}
                      label="Лицензия"
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Autocomplete
                getOptionLabel={(option) => option.title}
                getOptionSelected={(option, value) => option.id === value.id}
                options={timezones}
                value={fields.timezone}
                disabled={!hasFacilityUpdateRight}
                onChange={handleChangeTimezone}
                renderInput={(params) => (
                  <CustomTextInput
                    /* eslint-disable-next-line react/jsx-props-no-spreading */
                    {...params}
                    className={styles.inputItem}
                    error={errors.timezone}
                    disabled={!hasFacilityUpdateRight}
                    label="Часовой пояс"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                multiple
                getOptionLabel={(option) => option.title}
                getOptionSelected={(option, value) => option.title === value.title}
                options={AVAILABLE_BADGES}
                value={fields.badges}
                disabled={!hasFacilityUpdateRight}
                onChange={handleChangeBadges}
                renderInput={(params) => (
                  <CustomTextInput
                    /* eslint-disable-next-line react/jsx-props-no-spreading */
                    {...params}
                    className={styles.inputItem}
                    error={errors.badges}
                    disabled={!hasFacilityUpdateRight}
                    label="Типы"
                  />
                )}
              />
            </Grid>
          </Grid>
          <Typography className={styles.subTitle}>
            {t('facility.dimensions')}
          </Typography>
          <Grid container item spacing={2}>
            <Grid item xs={3}>
              <CustomTextInput
                className={styles.inputItem}
                error={errors.maxHeight}
                name="maxHeight"
                label={t('facility.maxHeight')}
                value={fields.maxHeight}
                autoComplete="off"
                inputProps={{
                  type: 'number',
                }}
                disabled={!hasFacilityUpdateRight}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={3}>
              <CustomTextInput
                className={styles.inputItem}
                error={errors.maxWidth}
                name="maxWidth"
                label={t('facility.maxWidth')}
                value={fields.maxWidth}
                autoComplete="off"
                inputProps={{
                  type: 'number',
                }}
                disabled={!hasFacilityUpdateRight}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={3}>
              <CustomTextInput
                className={styles.inputItem}
                error={errors.maxLength}
                name="maxLength"
                label={t('facility.maxLength')}
                value={fields.maxLength}
                autoComplete="off"
                inputProps={{
                  type: 'number',
                }}
                disabled={!hasFacilityUpdateRight}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={3}>
              <CustomTextInput
                className={styles.inputItem}
                error={errors.maxWeight}
                name="maxWeight"
                label={t('facility.maxWeight')}
                value={fields.maxWeight}
                autoComplete="off"
                inputProps={{
                  type: 'number',
                }}
                disabled={!hasFacilityUpdateRight}
                onChange={onChange}
              />
            </Grid>
          </Grid>
          <Typography className={styles.subTitle}>
            {t('facility.parkingRules')}
          </Typography>
          <Grid container item direction="row" spacing={2}>
            <Grid item xs={12}>
              <CustomTextInput
                multiline
                minRows={8}
                className={styles.inputItem}
                error={errors.rulesText}
                name="rulesText"
                value={fields.rulesText}
                autoComplete="off"
                disabled={!hasFacilityUpdateRight}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomTextInput
                className={styles.inputItem}
                error={errors.phone}
                name="phone"
                required
                value={fields.phone}
                label={t('facility.phone')}
                autoComplete="off"
                inputProps={{
                  maxLength: MAX_PHONE_NUMBER_LENGTH,
                }}
                disabled={!hasFacilityUpdateRight}
                onChange={onChange}
              />
            </Grid>
          </Grid>
          {hasFacilityUpdateRight && (
            <Grid item xs={4}>
              <CustomBtn
                type="submit"
                className={styles.btnSubmit}
                btnType="primaryBlue"
                disabled={isCreateLoading}
              >
                Сохранить
              </CustomBtn>
            </Grid>
          )}
        </Grid>
      </form>
      {yaMapModalNode}
    </div>
  );
}

function mapStateToProps(state) {
  const { facilities, companies, licenses } = state || {};
  const { oneFacility, createFacility } = facilities || {};
  const { registrationStatus } = companies || {};
  const { availableLicenses: availableLicensesState } = licenses || {};

  const { data: registrationStatusData } = registrationStatus || {};

  return {
    registrationStatusData,
    oneFacilityState: oneFacility,
    createFacilityState: createFacility,
    availableLicensesState,
  };
}

const ConnectedFacilitySettingsPage = connect(
  mapStateToProps,
  {
    getFacilityReg: getFacilityAction,
    getFacilitiesReg: getFacilitiesAction,
    clearFacilitiesCache: clearFacilitiesCacheAction,
    createFacilityReq: createFacilityAction,
    updateFacilityReq: updateFacilityAction,
    getAvailableLicensesReq: getAvailableLicensesAction,
  },
)(FacilitySettingsPage);

export default ConnectedFacilitySettingsPage;
