import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import { callAPI } from '@parkly/shared/api';
import moment from 'moment-timezone';

async function validGetGatewayEvents({
  res,
}) {
  const { data } = res || {};
  const curData = (data || {}).data || [];
  const curMeta = (data || {}).meta || {};

  const {
    current_page,
    from,
    last_page,
    path,
    per_page,
    to,
    total,
  } = curMeta || {};

  const gatewayEventsMeta = {
    currentPage: current_page,
    from,
    lastPage: last_page,
    path,
    perPage: per_page,
    to,
    total,
  };

  const gatewayEventsData = curData.map((item) => {
    const {
      id,
      status,
      type,
      eventAt,
      gates,
      accessToken,
      gatewayEventId,
      photo,
      customerName,
      sessionData,
      gatewayToken,
      operator,
      fastActions,
      description,
      controller,
      port,
      isFakeSession,
    } = item;

    return {
      id,
      status,
      type,
      eventAt,
      gates,
      gatewayEventId,
      photo,
      accessToken,
      customerName,
      sessionData,
      gatewayToken,
      operator,
      fastActions,
      description,
      controller,
      port,
      isFakeSession,
    };
  });

  return {
    gatewayEventsData,
    gatewayEventsMeta,
  };
}

export async function getGatewayEvents({
  facilityId,
  status,
  failStatus,
  search,
  type,
  gateIds,
  dateFrom,
  dateTo,
  needPhoto,
  page,
  perPage,
  sortBy,
  sortDirection,
}) {
  const query = new URLSearchParams(pickBy({
    status: status.filter((i) => i !== '0').concat(failStatus).join(','),
    needPhoto: needPhoto ? 1 : 0,
    type,
    gate: gateIds.join(','),
    facilityId,
    datetimeFrom: dateFrom && moment(dateFrom).utc().toISOString(),
    datetimeTo: dateTo && moment(dateTo).utc().toISOString(),
    page,
    perPage,
    sortBy,
    sortDirection,
    search,
  }, identity));

  const queryParams = query.toString();

  const endpoint = `facilities/${facilityId}/gateway-logs?${queryParams}`;

  return callAPI(endpoint)
    .then((res) => validGetGatewayEvents({
      res,
    }));
}

async function validGetLastErrorsGatewayEvents({
  res,
}) {
  const { data } = res || {};
  const curData = (data || {}).data || [];

  const gatewayEventsData = curData.map((item) => {
    const {
      id,
      status,
      type,
      eventAt,
      gates,
      accessToken,
      gatewayEventId,
      hasPhoto,
      photoUrl,
      customerName,
      sessionData,
      gatewayToken,
      operator,
      fastActions,
    } = item;

    return {
      id,
      status,
      type,
      eventAt,
      gates,
      gatewayEventId,
      hasPhoto,
      photoUrl,
      accessToken,
      customerName,
      sessionData,
      gatewayToken,
      operator,
      fastActions,
    };
  });

  return gatewayEventsData;
}

export async function getLastErrorsGatewayEvents({
  facilityId,
  limit,
}) {
  const query = new URLSearchParams(pickBy({
    limit,
  }, identity));

  const queryParams = query.toString();

  const endpoint = `facilities/${facilityId}/gateway-logs/last-errors?${queryParams}`;

  return callAPI(endpoint)
    .then((res) => validGetLastErrorsGatewayEvents({
      res,
    }));
}
