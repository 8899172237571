import React, { useCallback, useEffect } from 'react';
import moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import { Card, CircularProgress, Grid } from '@material-ui/core';
import HeaderWithBackBtn from '@parkly/shared/components/molecules/HeaderWithBackBtn';
import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';
import {
  cleanPhone,
  formatPhoneNumber,
  formatPhoneNumberInput,
  plural,
  toNativeTimeFormat,
  useFormFields,
} from '@parkly/shared/helpers';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import Typography from '@material-ui/core/Typography';
import {
  PASS_REQ_TYPES,
  PASS_REQ_TYPES_MAP,
  PASSES_STATUSES,
  PATH_PAGES,
  RIGHT_KEYS,
  UNITS_PLURAL_MAP,
} from 'config/constants';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useCurrentFacilityMatch } from 'helpers/routerHooks';
import CustomerAsyncSelect from 'components/organisms/CustomerAsyncSelect';
import CalendarIcon from '@parkly/shared/components/atoms/icons/CalendarIcon';
import DateTimeInput from '@parkly/shared/components/atoms/DateTimeInput';
import clone from 'lodash/clone';
import { useActiveTicketTypes, useCreateTicketMutation } from 'api/query/tickets';
import TicketPlaceSelect from 'components/organisms/TicketPlaceSelect';
import { useCheckFacilityRights } from 'helpers/hooks';
import { MAX_PHONE_NUMBER_LENGTH } from '@parkly/shared/config/constants';
import { useStyles } from './styles';

function formatValue(name, value) {
  if (name === 'phone') {
    return formatPhoneNumberInput(value);
  }

  if (name === 'additionalPhone') {
    return formatPhoneNumberInput(value);
  }

  return value;
}

function NewOneTicketPage({
  match,
}) {
  const styles = useStyles();
  const history = useHistory();
  useCurrentFacilityMatch(PATH_PAGES.tickets);
  const { params, path } = match || {};
  const {
    id: ticketId,
    facilityId,
  } = params || {};

  const isNewMode = !ticketId && path === PATH_PAGES.addTicket;

  const [fields, errors, onChange, setErrors, updateState] = useFormFields({
    initValues: {
      customer: '',
      customerId: '',
      ticketTypeId: '',
      placeId: '',
      placeTitle: '',
      comment: '',
      startTime: moment().startOf('day'),
      finishTime: moment().startOf('day'),
      status: PASSES_STATUSES.draft,
      reqValues: [],
    },
    formatValueFunc: formatValue,
  });

  const createTicketMutation = useCreateTicketMutation({
    onSuccess({ success, id }) {
      if (success) {
        history.replace(PATH_PAGES.oneTicketInfo.replace(':facilityId', facilityId).replace(':id', id));
      }
    },
    onError(error) {
      if (error) {
        const { response } = error || {};
        const { data } = response || {};
        const backErrors = (data || {}).errors || {};

        setErrors({
          ...backErrors,
        });
      }
    },
  });

  const { isLoading: isCreateLoading } = createTicketMutation || {};

  const {
    data: activeTicketTypesData,
    isLoading: isActivePassTypesLoading,
  } = useActiveTicketTypes({
    variables: { facilityId },
  });

  const hasTicketUpdateRight = useCheckFacilityRights(RIGHT_KEYS.ticketUpdate, facilityId);

  const onSubmit = (e) => {
    e.preventDefault();
    e.nativeEvent.preventDefault();

    createTicketMutation.mutate({
      facilityId,
      customerId: fields.customerId,
      ticketTypeId: fields.ticketTypeId,
      placeId: fields.placeId || null,
      startTime: fields.startTime ? fields.startTime : null,
      finishTime: fields.finishTime ? fields.finishTime : null,
      status: PASSES_STATUSES.active,
      comment: fields.comment,
      reqValues: fields.reqValues
        .filter(({ value }) => !!value)
        .map(({ type, value, ...other }) => ({
          type,
          value: [PASS_REQ_TYPES.phone, PASS_REQ_TYPES.additionalPhone].includes(type) ? cleanPhone(value) : value,
          ...other,
        }))
        .filter(({ value, type }) => !([PASS_REQ_TYPES.phone, PASS_REQ_TYPES.additionalPhone].includes(type) && value === '7')),
    });
};

  const selTicketType = (activeTicketTypesData || []).find(({ id }) => id === fields.ticketTypeId);

  useEffect(() => {
    if (!selTicketType) {
      return;
    }

    const reqValues = [];
    Object.entries(selTicketType.reqData).forEach((entry) => {
      const [key, value] = entry;

      if (value && !key.includes('additional')) {
        reqValues.push({ type: key, value: '' });
      }
    });

    updateState({
      placeId: '',
      placeTitle: '',
      reqValues,
      startTime: moment(selTicketType.startTime),
      finishTime: moment(selTicketType.finishTime),
    });
  }, [selTicketType, updateState]);

  useEffect(() => {
    if (!selTicketType) {
      return;
    }

    updateState({
      finishTime: fields.startTime.clone().add(selTicketType.duration, 'hours'),
    });
  }, [selTicketType, updateState, fields.startTime]);

  const handleChangeCustomer = useCallback((e, v) => {
    updateState({
      customerId: v ? v.id : '',
    });
    setErrors({
      customerId: false,
    });

    if (v && (v.phone || v.id === null)) {
      const reqIndex = fields.reqValues.findIndex(({ type }) => type === PASS_REQ_TYPES.phone);

      if (reqIndex !== -1) {
        const newReqValues = clone(fields.reqValues);
        newReqValues[reqIndex].value = v.id === null ? '+7' : formatPhoneNumber(v.phone);
        updateState({
          reqValues: newReqValues,
        });
      }
    }

    if (v && (v.title || v.id === null)) {
      const reqIndex = fields.reqValues.findIndex(({ type }) => type === PASS_REQ_TYPES.fio);

      if (reqIndex !== -1) {
        const newReqValues = clone(fields.reqValues);
        newReqValues[reqIndex].value = v.id === null ? '' : v.title;
        updateState({
          reqValues: newReqValues,
        });
      }
    }
  }, [fields.reqValues, setErrors, updateState]);

  if (isActivePassTypesLoading) {
    return (
      <CircularIndeterminate style={{ minHeight: 600 }} />
    );
  }

  return (
    <div className={styles.container}>
      <HeaderWithBackBtn
        title="Добавление пропуска"
      />
      <form noValidate className={styles.form} onSubmit={onSubmit}>
        <div>
          <Typography>Выберите тип пропуска:</Typography>
          <Grid className={styles.typesContainer} container spacing={1} wrap='nowrap'>
            {(activeTicketTypesData || []).map(({id, name, tariffPlanName, intervalMode, intervalValue}) => (
              <Grid item key={id}>
                <Card
                  variant="outlined"
                  className={[
                    styles.typeCard,
                    id === fields.ticketTypeId ? styles.typeCardSelected : '',
                  ].join(' ')}
                  onClick={() => {
                    updateState({ticketTypeId: id});
                  }}
                >
                  <Typography className={styles.cardTitle}>{name}</Typography>
                  <Typography>{`${intervalValue} ${plural(intervalValue, UNITS_PLURAL_MAP[intervalMode])}`}</Typography>
                  {/*<Typography>{tariffPlanName}</Typography>*/}
                </Card>
              </Grid>
            ))}
          </Grid>
        </div>
        <Container maxWidth="sm" className={styles.innerContainer}>
          <Grid container spacing={2}>
            {fields.ticketTypeId && (
              <>
                <Grid item xs={12} md={6}>
                  <DateTimeInput
                    className={[
                      styles.datePicker,
                      (errors || {}).startTime ? styles.datePickerWithError : '',
                    ].join(' ')}
                    name="startTime"
                    label="Время начала"
                    min={moment().startOf('day')}
                    max={selTicketType.maxDayToStart
                      ? moment().add(selTicketType.maxDayToStart, 'days').endOf('day')
                      : undefined}
                    placeholder="Не задано"
                    rightIcon={(<CalendarIcon/>)}
                    onChange={({value}) => updateState({startTime: moment(value)})}
                    value={toNativeTimeFormat(fields.startTime)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DateTimeInput
                    className={[
                      styles.datePicker,
                      (errors || {}).finishTime ? styles.datePickerWithError : '',
                    ].join(' ')}
                    name="finishTime"
                    label="Время окончания"
                    min={fields.startTime.clone().startOf('day')}
                    max={fields.startTime.clone().add(selTicketType.duration, 'hours').endOf('day')}
                    placeholder="Не задано"
                    rightIcon={(<CalendarIcon/>)}
                    onChange={({value}) => updateState({finishTime: moment(value)})}
                    value={toNativeTimeFormat(fields.finishTime)}
                  />
                </Grid>
                {selTicketType.isBookPlace && (
                  <Grid item xs={12} md={10}>
                    <TicketPlaceSelect
                      facilityId={facilityId}
                      ticketTypeId={fields.ticketTypeId}
                      selTitle={fields.placeTitle}
                      onChange={(id, title) => updateState({placeId: id, placeTitle: title})}
                    />
                  </Grid>
                )}
                {/*<Grid item xs={12} md={8}>*/}
                {/*  <ItemSelectors*/}
                {/*    classNameForm={styles.selectorsForm}*/}
                {/*    classNameLabel={styles.selectorsLabel}*/}
                {/*    className={styles.selector}*/}
                {/*    disabled={!hasTicketUpdateRight}*/}
                {/*    label="Статус"*/}
                {/*    items={TICKETS_STATUSES_ITEMS}*/}
                {/*    error={!!errors.status}*/}
                {/*    name="status"*/}
                {/*    onChange={onChange}*/}
                {/*    currentValue={fields.status}*/}
                {/*  />*/}
                {/*</Grid>*/}
                <Grid item xs={12}>
                  <CustomTextInput
                    className={styles.inputItem}
                    error={!!errors.comment}
                    name="comment"
                    label="Комментарий"
                    value={fields.comment}
                    autoComplete="off"
                    disabled={!hasTicketUpdateRight}
                    onChange={onChange}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <CustomerAsyncSelect
                    facilityId={facilityId}
                    onChange={handleChangeCustomer}
                    error={!!errors.customerId}
                    label="Посетитель"
                  />
                </Grid>
                {(fields.customerId || fields.customerId === null) && (
                  <Grid item xs={12} container spacing={2} style={{padding: 0, margin: 0}}>
                    {fields && (fields.reqValues || []).map(({value, type}, reqIndex) => (
                      <Grid key={`req_value_${reqIndex}`} item xs={12} sm={6} md={4}>
                        <CustomTextInput
                          // required={!type.includes('additional')}
                          className={styles.inputItem}
                          error={errors[`requiredData.${reqIndex}.value`] || errors[`requiredData.${type}`] || false}
                          label={PASS_REQ_TYPES_MAP[type]}
                          value={value || ''}
                          autoComplete="off"
                          disabled={!hasTicketUpdateRight}
                          inputProps={{
                            maxLength:
                              [PASS_REQ_TYPES.phone, PASS_REQ_TYPES.additionalPhone].includes(type)
                                ? MAX_PHONE_NUMBER_LENGTH : 250,
                          }}
                          InputProps={{
                            endAdornment: type.includes('additional') ? (
                              <InputAdornment position="end">
                                <IconButton
                                  size="small"
                                  edge="end"
                                  onClick={() => {
                                    const newReqValues = clone(fields.reqValues);
                                    newReqValues.splice(reqIndex, 1);
                                    updateState({
                                      reqValues: newReqValues,
                                    });
                                  }}
                                >
                                  <CloseIcon fontSize="small"/>
                                </IconButton>
                              </InputAdornment>
                            ) : null,
                          }}
                          onChange={(e) => {
                            const {target} = e || {};
                            const {
                              value: newValue,
                            } = target || {};

                            const newReqValues = clone(fields.reqValues);

                            if ([PASS_REQ_TYPES.phone, PASS_REQ_TYPES.additionalPhone].includes(type)) {
                              newReqValues[reqIndex].value = formatPhoneNumberInput(newValue);
                            } else {
                              newReqValues[reqIndex].value = newValue;
                            }

                            updateState({
                              reqValues: newReqValues,
                            });
                          }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                )}
              </>
            )}
            <Grid container className={styles.btnsContainer} spacing={2} item xs={12}>
              <Grid item xs={3}>
                <CustomBtn
                  type="submit"
                  className={styles.btnSubmit}
                  btnType="primaryBlue"
                  disabled={!(fields.customerId || fields.customerId === null) || isCreateLoading}
                >
                  Добавить
                  {isCreateLoading && (
                    <CircularProgress
                      style={{marginLeft: 5}}
                      size={20}
                      color="inherit"
                    />
                  )}
                </CustomBtn>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </form>
    </div>
  );
}

export default NewOneTicketPage;
