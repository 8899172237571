import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  container: {
    // maxHeight: 440,
  },
  sortIcon: {
    marginLeft: 7,
  },
  clickable: {
    cursor: 'pointer',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  headerStr: {
    color: theme.palette.greyC.darkGrey,
    fontSize: theme.typography.fontSize12,
  },
  invoiceNumberStr: {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '18px',
  },
  userStr: {

  },
  carStr: {

  },
  priceStr: {

  },
  containerInCell: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    minHeight: 40,
  },
  tableRow: {
    '&.MuiTableRow-hover:hover': {
      backgroundColor: theme.palette.greyC.lightGrey,
    },
    height: 80,
  },
  tableRowLast: {
    borderBottom: 'none',
    '& .MuiTableCell-root': {
      borderBottom: 'none',
    },
  },
  noDataContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    minHeight: 330,
  },
  noDataStr: {
    color: theme.palette.greyC.darkGrey,
  },
  popper: {
    '& .MuiTooltip-tooltip': {
      fontSize: theme.typography.fontSize12,
      margin: '10px 0',
      backgroundColor: theme.palette.greyC.darkGrey,
    },
  },
  timeStr: {

  },
  dateStr: {
    marginTop: 7,
    color: theme.palette.greyC.darkGrey,
    fontSize: theme.typography.fontSize12,
  },
  statusStr: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  }
}));

export default {
  useStyles,
};
