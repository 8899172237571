import React from 'react';
import Typography from '@material-ui/core/Typography';

import { useStyles } from './styles';

export default function P360Logo({
  className,
}) {
  const styles = useStyles();

  return (
    <Typography className={[styles.logo, className].join(' ')}>
      P
      <span>360</span>
    </Typography>
  );
}
