import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  logo: {
    color: 'white',
    fontSize: 30,
    fontWeight: 700,

    '& span': {
      fontWeight: 500,
      color: '#02c173'
    }
  },
}));

export default {
  useStyles,
};
