import { callAPI } from '@parkly/shared/api';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import { getApiUrl } from '@parkly/shared/helpers';
import moment from 'moment-timezone';

async function validGetSessions({
  res,
}) {
  const { data } = res || {};
  const curData = (data || {}).data || [];
  const curMeta = (data || {}).meta || {};

  const {
    current_page,
    from,
    last_page,
    path,
    per_page,
    to,
    total,
  } = curMeta || {};

  const sessionsMeta = {
    currentPage: current_page,
    from,
    lastPage: last_page,
    path,
    perPage: per_page,
    to,
    total,
  };

  const sessionsData = curData.map((item) => {
    const {
      id,
      number,
      facilityId,
      customerId,
      customerName,
      startTime,
      finishTime,
      status,
      tariffPlanId,
      tariffPlanName,
      totalAmount,
      paidAmount,
      canChangeTariffPlan,
      startToken,
      finishToken,
      isFreeExit,
    } = item;

    return {
      id,
      number,
      facilityId,
      customerId,
      customerName,
      startTime,
      finishTime,
      status,
      tariffPlanId,
      tariffPlanName,
      totalAmount,
      paidAmount,
      canChangeTariffPlan,
      startToken,
      finishToken,
      isFreeExit,
    };
  });

  const result = {
    sessionsData,
    sessionsMeta,
  };

  return result;
}

export async function getSessions({
  facilityId,
  search,
  token,
  page,
  type,
  perPage,
  sortBy,
  sortDirection,
  dateFrom,
  dateTo,
  status,
  onlyFakeSessions,
}) {
  const query = new URLSearchParams(pickBy({
    search,
    token,
    type,
    page,
    perPage,
    sortBy,
    sortDirection,
    status: (status || []).join(','),
    dateFrom: dateFrom && moment(dateFrom).utc().toISOString(),
    dateTo: dateTo && moment(dateTo).utc().toISOString(),
    onlyFakeSessions: onlyFakeSessions ? 1 : 0,
  }, identity));

  const queryParams = query.toString();

  const endpoint = `facilities/${facilityId}/sessions?${queryParams}`;

  return callAPI(endpoint)
    .then((res) => validGetSessions({
      res,
    }));
}

export function validCreate(result) {
  const { res } = result || {};
  const { data } = res || {};

  const {
    id,
    success
  } = data;

  return {
    id,
    success,
  };
}

export async function changeSessionStatus({
  id,
  facilityId,
  status,
}) {
  return callAPI(
    `facilities/${facilityId}/sessions/${id}/status`,
    'POST',
    {
      status,
    },
  ).then((res) => validCreate({
    res,
  }));
}

export async function changeBulkSessionStatus({
  ids,
  facilityId,
  status,
}) {
  return callAPI(
    `facilities/${facilityId}/sessions/bulk/status`,
    'POST',
    {
      ids,
      status,
    },
  ).then((res) => validCreate({
    res,
  }));
}

export async function changeSessionTariffPlan({
  id,
  facilityId,
  tariffPlanId,
}) {
  return callAPI(
    `facilities/${facilityId}/sessions/${id}/tariff-plan`,
    'POST',
    {
      tariffPlanId,
    },
  ).then((res) => validCreate({
    res,
  }));
}

function validGetSessionAccessPhotoUrl(result) {
  const { res } = result || {};
  const { data } = res || {};

  const {
    url,
    success,
  } = data;

  return {
    url,
    success,
  };
}

export function makeSessionAccessPhotoUrl({
  id,
  facilityId,
}) {
  return `${getApiUrl()}/facilities/${facilityId}/sessions/gateway-event/${id}/photo`;
}

export async function getSessionAccessPhotoUrl({
  id,
  facilityId,
}) {
  return callAPI(
    `facilities/${facilityId}/sessions/gateway-event/${id}/photo`,
    'get',
    undefined,
    {},
    false,
  ).then((res) => validGetSessionAccessPhotoUrl({
    res,
  }));
}

function validGetSessionAccessTokens(result) {
  const { res } = result || {};
  const { data } = res || {};

  const resultData = (data || {}).data || {};

  const {
    cameraTokens,
    phoneTokens,
  } = resultData;

  return {
    cameraTokens,
    phoneTokens,
  };
}

export async function getSessionAccessTokens({
  id,
  facilityId,
}) {
  return callAPI(
    `facilities/${facilityId}/sessions/${id}/tokens`,
    'get',
  ).then((res) => validGetSessionAccessTokens({
    res,
  }));
}

function validSetSessionAccessTokens(result) {
  const { res } = result || {};
  const { data } = res || {};

  const {
    success,
    type,
    customer,
  } = data;

  return {
    success,
    type,
    customer,
  };
}

export async function setSessionAccessTokens({
  id,
  facilityId,
  token,
  force = false,
}) {
  return callAPI(
    `facilities/${facilityId}/sessions/access-event/${id}/token`,
    'POST',
    {
      token,
      force,
    },
  ).then((res) => validSetSessionAccessTokens({
    res,
  }));
}

export async function addSessionAccessTokens({
  id,
  facilityId,
  type,
  token,
  force = false,
}) {
  return callAPI(
    `facilities/${facilityId}/sessions/${id}/new-token`,
    'POST',
    {
      token,
      type,
      force,
    },
  ).then((res) => validSetSessionAccessTokens({
    res,
  }));
}
