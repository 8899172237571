import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    marginLeft: 0,
  },
  formContainer: {
    width: '100%',
  },
  form: {
    marginTop: 16,
  },
  inputItem: {
    margin: 0,
  },
  linkItem: {

  },
  divider: {
    marginTop: 16,
    backgroundColor: theme.palette.greyC.gray,
  },
  checkbox: {
    marginLeft: 0,
  },
  subTitle: {
    marginLeft: 8,
    marginTop: 16,
    marginBottom: 8,
    fontSize: 16,
    lineHeight: '20px',
    fontWeight: 700,
  },
  btnSubmit: {
    marginTop: 8,
    width: '100%',
    height: 48,
    display: 'block',
  }
}));

export default {
  useStyles,
};
