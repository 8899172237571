import { callAPI } from '@parkly/shared/api';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import moment from 'moment-timezone';

async function validGetPassesSettings({
  res,
}) {
  const { data } = res || {};
  const curData = (data || {}).data || [];
  const curMeta = (data || {}).meta || {};

  const {
    current_page,
    from,
    last_page,
    path,
    per_page,
    to,
    total,
  } = curMeta || {};

  const passesMeta = {
    currentPage: current_page,
    from,
    lastPage: last_page,
    path,
    perPage: per_page,
    to,
    total,
  };

  const passesData = curData.map((item) => {
    const {
      id,
      name,
      intervalValue,
      intervalMode,
      tariffPlanId,
      tariffPlanName,
      status,
    } = item;

    return {
      id,
      name,
      intervalValue,
      intervalMode,
      tariffPlanId,
      tariffPlanName,
      status,
    };
  });

  const result = {
    passesData,
    passesMeta,
  };

  return result;
}

export async function getPassesSettings({
  facilityId,
  page,
  perPage,
}) {
  const query = new URLSearchParams(pickBy({
    page,
    perPage,
  }, identity));

  const queryParams = query.toString();

  const endpoint = `facilities/${facilityId}/pass-types?${queryParams}`;

  return callAPI(endpoint)
    .then((res) => validGetPassesSettings({
      res,
    }));
}

async function validGetPassSettings({ res }) {
  const { data } = res || {};
  const resultData = (data || {}).data || {};

  const {
    id,
    name,
    description,
    status,
    tariffPlanId,
    intervalValue,
    intervalMode,
    isShowingAtSite,
    isSingle,
    isIgnoreSessions,
    isBookPlace,
    isFixedPlace,
    holdValue,
    holdMode,
    placeHoldValue,
    placeHoldMode,
    notifyValue,
    notifyMode,
    requiredData,
    places,
    maxDaysToStart,
    maxStartRenewals,
  } = resultData;

  const {
    phone: needPhone,
    fio: needFio,
    plateNumber: needPlateNumber,
    carModel: needCarModel,
    stsNumber: needStsNumber,
    idNumber: needIdNumber,
    additionalPhone: needAdditionalPhone,
    additionalPlateNumber: needAdditionalPlateNumber,
  } = requiredData || {};

  const isAllPlace = (places || []).length === 0;

  return {
    id,
    name,
    description,
    status,
    tariffPlanId,
    intervalValue,
    intervalMode,
    isShowingAtSite,
    isSingle,
    isIgnoreSessions,
    isBookPlace,
    isAllPlace,
    isFixedPlace,
    maxDaysToStart,
    maxStartRenewals,
    places,
    holdValue,
    holdMode,
    placeHoldValue,
    placeHoldMode,
    notifyValue,
    notifyMode,
    needPhone,
    needFio,
    needPlateNumber,
    needCarModel,
    needStsNumber,
    needIdNumber,
    needAdditionalPhone,
    needAdditionalPlateNumber,
  };
}

export async function getPassSetting({ facilityId, id }) {
  return callAPI(`facilities/${facilityId}/pass-types/${id}`)
    .then((res) => validGetPassSettings({
      res,
    }));
}

async function validGetPassSettingPlaces({ res }) {
  const { data } = res || {};
  const resultData = (data || {}).data || {};

  return resultData;
}

export async function getPassSettingPlaces({ facilityId, id }) {
  return callAPI(`facilities/${facilityId}/pass-types/active/${id}/places`)
    .then((res) => validGetPassSettingPlaces({
      res,
    }));
}

async function validGetActivePassTypes({
  res,
}) {
  const { data: resData } = res || {};
  const { data } = resData || {};

  const passesData = data.map((item) => {
    const {
      id,
      name,
      places,
      startTime,
      finishTime,
      requiredData,
      duration,
      price,
      isBookPlace,
      tariffPlanName,
      intervalMode,
      intervalValue,
    } = item;

    const {
      phone: needPhone,
      fio: needFio,
      plateNumber: needPlateNumber,
      carModel: needCarModel,
      stsNumber: needStsNumber,
      idNumber: needIdNumber,
      additionalPhone: needAdditionalPhone,
      additionalPlateNumber: needAdditionalPlateNumber,
    } = requiredData || {};

    const {
      phone,
      fio,
      plateNumber,
      carModel,
      stsNumber,
      idNumber,
      additionalPhone,
      additionalPlateNumber,
    } = requiredData || {};

    return {
      id,
      name,
      places,
      duration,
      startTime,
      finishTime,
      price,
      isBookPlace,
      tariffPlanName,
      intervalMode,
      intervalValue,
      reqData: {
        phone,
        fio,
        plateNumber,
        carModel,
        stsNumber,
        idNumber,
        additionalPhone,
        additionalPlateNumber,
      },
      needPhone,
      needFio,
      needPlateNumber,
      needCarModel,
      needStsNumber,
      needIdNumber,
      needAdditionalPhone,
      needAdditionalPlateNumber,
    };
  });

  return passesData;
}

export async function getActivePassSettings({
  facilityId,
  simple,
}) {
  const query = new URLSearchParams(pickBy({
    simple: simple ? 1 : undefined,
  }, identity));

  const queryParams = query.toString();

  return callAPI(`facilities/${facilityId}/pass-types/active?${queryParams}`)
    .then((res) => validGetActivePassTypes({
      res,
    }));
}

function validCreate(result) {
  const { res } = result || {};
  const { data } = res || {};

  const {
    id,
    success,
  } = data;

  return {
    id,
    success,
  };
}

export async function setPassSettings({
  facilityId,
  id,
  name,
  description,
  tariffPlanId,
  intervalValue,
  intervalMode,
  isShowingAtSite,
  isSingle,
  isIgnoreSessions,
  isBookPlace,
  isFixedPlace,
  holdValue,
  holdMode,
  placeHoldValue,
  placeHoldMode,
  notifyValue,
  notifyMode,
  maxDaysToStart,
  maxStartRenewals,
  needPhone,
  needFio,
  needPlateNumber,
  needCarModel,
  needStsNumber,
  needIdNumber,
  needAdditionalPhone,
  needAdditionalPlateNumber,
  status,
  places,
}) {
  return callAPI(
    `facilities/${facilityId}/pass-types`,
    'POST',
    {
      id: id || null,
      name,
      description,
      status,
      tariffPlanId,
      intervalValue,
      intervalMode,
      isShowingAtSite,
      isSingle,
      isIgnoreSessions,
      isBookPlace,
      isFixedPlace,
      holdValue: holdValue || 0,
      holdMode,
      placeHoldValue: placeHoldValue || 0,
      placeHoldMode,
      notifyValue: notifyValue || 0,
      notifyMode,
      maxDaysToStart: maxDaysToStart || 0,
      places,
      maxStartRenewals: maxStartRenewals || 1,
      requiredData: {
        phone: needPhone,
        fio: needFio,
        plateNumber: needPlateNumber,
        carModel: needCarModel,
        stsNumber: needStsNumber,
        idNumber: needIdNumber,
        additionalPhone: needAdditionalPhone,
        additionalPlateNumber: needAdditionalPlateNumber,
      },
    },
  )
    .then((res) => validCreate({
      res,
    }));
}

async function validGetPasses({
  res,
}) {
  const { data } = res || {};
  const curData = (data || {}).data || [];
  const curMeta = (data || {}).meta || {};

  const {
    current_page,
    from,
    last_page,
    path,
    per_page,
    to,
    total,
  } = curMeta || {};

  const passesMeta = {
    currentPage: current_page,
    from,
    lastPage: last_page,
    path,
    perPage: per_page,
    to,
    total,
  };

  const passesData = curData.map((item) => {
    const {
      id,
      number,
      passTypeName,
      passTypeId,
      customerName,
      customerId,
      finishTime,
      startTime,
      placeName,
      status,
      isOverdue,
      requiredData,
      price,
      canBeRenewal,
      canBeCanceled,
    } = item;

    return {
      id,
      number,
      passTypeName,
      passTypeId,
      customerName,
      customerId,
      finishTime,
      startTime,
      placeName,
      status,
      isOverdue,
      requiredData,
      price,
      canBeRenewal,
      canBeCanceled,
    };
  });

  const result = {
    passesData,
    passesMeta,
  };

  return result;
}

export async function getPasses({
  facilityId,
  type,
  status,
  passType,
  search,
  place,
  dateFrom,
  dateTo,
  page,
  perPage,
  sortBy,
  sortDirection,
}) {
  const query = new URLSearchParams(pickBy({
    page,
    perPage,
    type,
    status: status.join(','),
    dateFrom: dateFrom && moment(dateFrom).utc().toISOString(),
    dateTo: dateTo && moment(dateTo).utc().toISOString(),
    passType: passType === 'unselect' ? undefined : passType,
    search,
    place,
    sortBy,
    sortDirection,
  }, identity));

  const queryParams = query.toString();

  const endpoint = `facilities/${facilityId}/pass?${queryParams}`;

  return callAPI(endpoint)
    .then((res) => validGetPasses({
      res,
    }));
}

async function validGetPass({ res }) {
  const { data } = res || {};
  const resultData = (data || {}).data || {};

  const {
    id,
    number,
    customerName,
    customerId,
    status,
    price,
    passTypeId,
    passType,
    placeId,
    areaId,
    startTime,
    finishTime,
    requiredData,
    url,
    canBeRenewal,
    canBeCanceled,
  } = resultData;

  const {
    requiredData: passTypeRequiredData,
  } = passType || {};

  const {
    phone: needPhone,
    fio: needFio,
    plateNumber: needPlateNumber,
    carModel: needCarModel,
    stsNumber: needStsNumber,
    idNumber: needIdNumber,
    additionalPhone: needAdditionalPhone,
    additionalPlateNumber: needAdditionalPlateNumber,
  } = passTypeRequiredData || {};

  return {
    id,
    number,
    customerName,
    customerId,
    price,
    placeId,
    areaId,
    status,
    passTypeId,
    passType: {
      ...(passType || {}),
      needPhone,
      needFio,
      needPlateNumber,
      needCarModel,
      needStsNumber,
      needIdNumber,
      needAdditionalPhone,
      needAdditionalPlateNumber,
    },
    startTime,
    finishTime,
    requiredData,
    url,
    canBeRenewal,
    canBeCanceled,
  };
}

export async function getPass({ facilityId, id }) {
  return callAPI(`facilities/${facilityId}/pass/${id}`)
    .then((res) => validGetPass({
      res,
    }));
}

async function validGetPassInfo({ res }) {
  const { data } = res || {};
  const resultData = (data || {}).data || {};

  const {
    id,
    number,
    customer,
    status,
    price,
    passType,
    place,
    startTime,
    finishTime,
    requiredData,
    url,
    sessions,
    payments,
    canBeRenewal,
    canBeCanceled,
  } = resultData;

  return {
    id,
    number,
    customer,
    price,
    place,
    status,
    passType,
    startTime,
    finishTime,
    requiredData,
    url,
    sessions,
    payments,
    canBeRenewal,
    canBeCanceled,
  };
}

export async function getPassInfo({ facilityId, id }) {
  return callAPI(`facilities/${facilityId}/pass/${id}/info`)
    .then((res) => validGetPassInfo({
      res,
    }));
}

export async function createPass({
  facilityId,
  customerId,
  status,
  passTypeId,
  placeId,
  startTime,
  finishTime,
  reqValues,
}) {
  return callAPI(
    `facilities/${facilityId}/pass`,
    'POST',
    {
      status,
      customerId: customerId || null,
      passTypeId,
      placeId: placeId || null,
      startTime,
      finishTime,
      requiredData: reqValues,
    },
  )
    .then((res) => validCreate({
      res,
    }));
}

export async function updatePass({
  facilityId,
  id,
  customerId,
  status,
  passTypeId,
  placeId,
  startTime,
  finishTime,
  reqValues,
}) {
  return callAPI(
    `facilities/${facilityId}/pass`,
    'PUT',
    {
      id,
      status,
      customerId,
      passTypeId,
      placeId: placeId || null,
      startTime,
      finishTime,
      requiredData: reqValues,
    },
  )
    .then((res) => validCreate({
      res,
    }));
}

export async function addPassPayment({
  facilityId,
  id,
  amount,
}) {
  return callAPI(
    `facilities/${facilityId}/pass/${id}/payment`,
    'POST',
    {
      amount,
    },
  )
    .then((res) => validCreate({
      res,
    }));
}

export async function cancelPass({
  facilityId,
  id,
  withPayment = true,
}) {
  return callAPI(
    `facilities/${facilityId}/pass/${id}/cancel`,
    'POST',
    {
      withPayment,
    },
  )
    .then((res) => validCreate({
      res,
    }));
}

export async function stopSessionPass({
  facilityId,
  id,
}) {
  return callAPI(
    `facilities/${facilityId}/pass/${id}/finish-active-session`,
    'POST',
  )
    .then((res) => validCreate({
        res,
    }));
}

async function validRenewalPassInfo({ res }) {
  const { data } = res || {};
  const resultData = (data || {}).data || {};

  const {
    from,
    to,
    price,
  } = resultData || {};

  return {
    from,
    to,
    price,
  };
}

export async function renewalPassInitInfo({
  facilityId,
  id,
}) {
  return callAPI(
    `facilities/${facilityId}/pass/${id}/renewal/init`,
    'POST',
  )
    .then((res) => validRenewalPassInfo({
      res,
    }));
}

export async function renewalPassPriceInfo({
  facilityId,
  id,
  to,
}) {
  return callAPI(
    `facilities/${facilityId}/pass/${id}/renewal/price`,
    'POST',
    {
      to,
    },
  )
    .then((res) => validRenewalPassInfo({
      res,
    }));
}

async function validRenewalRenew({ res }) {
  const { data } = res || {};
  const resultData = (data || {}).data || {};

  const {
    from,
    to,
    price,
  } = resultData || {};

  return {
    from,
    to,
    price,
  };
}

export async function renewalPassRenew({
  facilityId,
  id,
  to,
  price,
  isPaid,
}) {
  return callAPI(
    `facilities/${facilityId}/pass/${id}/renewal/renew`,
    'POST',
    {
      to,
      price,
      isPaid,
    }
  )
    .then((res) => validCreate({
      res,
    }));
}

export async function sendPassLinkToCustomer({
  facilityId,
  id,
}) {
  return callAPI(
    `facilities/${facilityId}/pass/${id}/notify-link`,
    'POST',
  )
    .then((res) => validCreate({
      res,
    }));
}
