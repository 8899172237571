import React from 'react';
import { Grid, Tab, Tabs } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import GateIcon from '@parkly/shared/components/atoms/icons/GateIcon';
import VideocamIcon from '@material-ui/icons/Videocam';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import GateControls from 'components/organisms/GateControls';
import CameraStreamList from 'components/organisms/CameraStreamList';
import { SessionsWithParams } from 'components/organisms/Sessions';
import { GatewayEventsWithParams } from 'components/organisms/GatewayEvents';
import { FAIL_GATEWAY_EVENTS_STATUSES, RIGHT_KEYS } from 'config/constants';
import { useCheckFacilityRights } from 'helpers/hooks';
import { useStyles } from './styles';

export default function MobileHomeContent({
  facilityId,
}) {
  const styles = useStyles();
  const hasOpenGateRight = useCheckFacilityRights(RIGHT_KEYS.automationOpenGate, facilityId);
  const hasSessionShowRight = useCheckFacilityRights(RIGHT_KEYS.sessionShow, facilityId);
  const hasGatewayLogShowRight = useCheckFacilityRights(RIGHT_KEYS.gatewayLogShow, facilityId);

  const [value, setValue] = React.useState(() => {
    switch (true) {
      case hasGatewayLogShowRight:
        return 0;
      case hasSessionShowRight:
        return 1;
      case hasOpenGateRight:
        return 2;
      default:
        return 3;
    }
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container className={styles.container}>
      <Box
        position="fixed"
        sx={{
          bottom: 0, left: 0, width: '100%', zIndex: 1000, backgroudColor: '#000',
        }}
      >
        <Paper square elevation={16}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            {hasGatewayLogShowRight && (
              <Tab
                classes={{ root: styles.tab }}
                value={0}
                label="Неуспешные проезды"
                icon={<SyncAltIcon />}
              />
            )}

            {hasSessionShowRight && (
              <Tab
                classes={{ root: styles.tab }}
                value={1}
                label="Последние сессии"
                icon={<DirectionsCarIcon />}
              />
            )}
            {hasOpenGateRight && (
              <Tab
                classes={{ root: styles.tab }}
                value={2}
                label="Управление барьерами"
                icon={<GateIcon stroke={value === 2 ? '#004BF6' : '#1D3262'} />}
              />
            )}
            <Tab
              classes={{ root: styles.tab }}
              value={3}
              label="Просмотр камер"
              icon={<VideocamIcon />}
            />
          </Tabs>
        </Paper>
      </Box>
      <Grid container>
        {hasGatewayLogShowRight && value === 0 && (
          <Grid item xs={12}>
            <GatewayEventsWithParams
              initParams={{ perPage: 15, status: [FAIL_GATEWAY_EVENTS_STATUSES.join(',')] }}
              facilityId={facilityId}
              refetchInterval={15 * 1000} // 15 sec
            />
          </Grid>
        )}
        {hasSessionShowRight && value === 1 && (
          <Grid item xs={12}>
            <SessionsWithParams
              hideCheckbox
              initParams={{ sorting: { activityAt: 'sortReverse' } }}
              facilityId={facilityId}
              defaultSortByActivityAt
            />
          </Grid>
        )}
        {hasOpenGateRight && value === 2 && (
          <Grid item xs={12}>
            <GateControls />
          </Grid>
        )}
        {value === 3 && (
          <Grid item xs={12}>
            <CameraStreamList
              facilityId={facilityId}
              isExpanded
            />
          </Grid>
        )}
      </Grid>
    </Container>
  );
}
