import React from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { BooleanParam, NumberParam, StringParam, withDefault } from 'use-query-params';

import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';
import DateTimeInput from '@parkly/shared/components/atoms/DateTimeInput';
import CalendarIcon from '@parkly/shared/components/atoms/icons/CalendarIcon';
import MultiItemSelectors from '@parkly/shared/components/molecules/MultiItemSelectors';
import { toNativeTimeFormat } from '@parkly/shared/helpers';

import { useSessions } from 'api/query/sessions';
import SessionsContent from 'components/organisms/Sessions/SessionsContent';
import FiltersContainer from 'components/templates/containers/FiltersContainer';
import { LS_KEYS, SESSION_STATUSES, SESSION_STATUSES_MAP } from 'config/constants';
import { useDebounce, useLocalStorageState } from 'helpers/hooks';
import {
  createEnumDelimitedNumericArrayParam, PageParam, withParams, withQueryParams,
} from 'helpers/queryParams';

import { useStyles } from './styles';

function getInitParams(params) {
  return {
    searchValue: '',
    token: '',
    sorting: {},
    status: [],
    page: 1,
    dateFrom: '',
    dateTo: '',
    onlyFakeSessions: false,
    ...params,
  };
}

const STATUS_ITEMS = [
  { id: SESSION_STATUSES.active, title: SESSION_STATUSES_MAP[SESSION_STATUSES.active] },
  { id: SESSION_STATUSES.finished, title: SESSION_STATUSES_MAP[SESSION_STATUSES.finished] },
  { id: SESSION_STATUSES.stopped, title: SESSION_STATUSES_MAP[SESSION_STATUSES.stopped] },
  { id: SESSION_STATUSES.canceled, title: SESSION_STATUSES_MAP[SESSION_STATUSES.canceled] },
];

function prepareParam({
  params,
}) {
  const {
    search: searchValue,
    token: tokenValue,
    sorting = {},
    page,
    tabValue,
    dateFrom,
    dateTo,
    status,
    onlyFakeSessions,
  } = params || {};

  const sortRules = Object.keys(sorting).filter((currentId) => !!sorting[currentId]);
  const sortParam = sortRules.length > 0
    ? {
      sortBy: sortRules.join(','),
      sortDirection: sortRules.map((id) => {
        const rule = sorting[id];
        if (rule === 'sortReverse') {
          return 'desc';
        }

        return 'asc';
      }).join(','),
    }
    : {};

  const search = (searchValue || '').trim();
  const token = (tokenValue || '').trim();

  const reqParam = {
    search,
    token,
    page,
    tabValue,
    dateFrom,
    dateTo,
    status,
    onlyFakeSessions,
    ...sortParam,
  };

  return reqParam;
}

function Sessions({
  showControls = false,
  showPagination = false,
  hideCheckbox = false,
  facilityId,

  // from HOC
  params,
  setParams,
}) {
  const styles = useStyles();
  const { t } = useTranslation();

  const [showPhotos, setShowPhotos] = useLocalStorageState(LS_KEYS.sessionsShowPhotos, true);

  const variables = useDebounce(params, 300);

  const { data: sessions, isLoading } = useSessions({
    variables: { ...variables, facilityId },
    refetchInterval: 1000 * 15, // 15 secs
    refetchIntervalInBackground: true,
  });

  const {
    sessionsData,
    sessionsMeta,
  } = sessions || {};

  const handleParamChange = (paramName, newValue) => {
    if (params[paramName] === newValue) {
      return;
    }

    setParams((prevState) => ({
      ...prevState,
      page: 1,
      [paramName]: newValue,
    }));
  };
  function handleChanges(event) {
    const propName = event.target.name;
    let value;
    if (event.target.type === 'checkbox') {
      value = event.target.checked;
    } else {
      value = event.target.value;
    }
    handleParamChange(propName, value);
  }
  function onPageChange(_, pageNumber) {
    handleParamChange('page', pageNumber);
  }
  const handleDateChange = ({ value, name }) => {
    handleParamChange(name, toNativeTimeFormat(value));
  };

  const {
    search,
    token,
    tabValue,
    dateFrom,
    dateTo,
    status,
    onlyFakeSessions,
  } = params;

  return (
    <Grid container spacing={2}>
      {showControls && (
        <FiltersContainer>
          <Grid item xs={12}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <CustomTextInput
                  style={{ margin: 0 }}
                  label="Поиск"
                  placeholder="Номер билета, ID клиента"
                  name="search"
                  onChange={handleChanges}
                  value={search}
                />
              </Grid>
              <Grid item xs={6} md={4} lg={3}>
                <DateTimeInput
                  className={[
                    styles.datePicker,
                  ].join(' ')}
                  name="dateFrom"
                  placeholder="Не выбрано"
                  label="От"
                  initStartDayTime
                  rightIcon={(
                    <>
                      <IconButton
                        size="small"
                        onClick={() => {
                          handleParamChange('dateFrom', '');
                        }}
                      >
                        <CloseIcon htmlColor="#7E8AA7" />
                      </IconButton>
                      <CalendarIcon />
                    </>
                  )}
                  onChange={handleDateChange}
                  value={dateFrom || undefined}
                />
              </Grid>
              <Grid item xs={6} md={4} lg={3}>
                <DateTimeInput
                  className={[
                    styles.datePicker,
                  ].join(' ')}
                  name="dateTo"
                  placeholder="Не выбрано"
                  label="До"
                  initStartDayTime
                  rightIcon={(
                    <>
                      <IconButton
                        size="small"
                        onClick={() => {
                          handleParamChange('dateTo', '');
                        }}
                      >
                        <CloseIcon htmlColor="#7E8AA7" />
                      </IconButton>
                      <CalendarIcon />
                    </>
                  )}
                  onChange={handleDateChange}
                  value={dateTo || undefined}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <MultiItemSelectors
                  classNameForm={styles.statusSelectForm}
                  className={styles.statusSelect}
                  name="status"
                  currentValue={status}
                  items={STATUS_ITEMS}
                  unselectText="Статус не выбран"
                  onChange={handleChanges}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <CustomTextInput
                  style={{ margin: 0 }}
                  label="Токен"
                  name="token"
                  onChange={handleChanges}
                  value={token}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={showPhotos}
                      onChange={(e) => { setShowPhotos(e.target.checked); }}
                      color="primary"
                    />
                  )}
                  labelPlacement="end"
                  label="Показывать фото"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={onlyFakeSessions}
                      name="onlyFakeSessions"
                      onChange={handleChanges}
                      color="primary"
                    />
                  )}
                  labelPlacement="end"
                  label="Свободный выезд"
                />
              </Grid>
            </Grid>
          </Grid>
        </FiltersContainer>
      )}
      <Grid item xs={12}>
        <SessionsContent
          facilityId={facilityId}
          isLoading={isLoading}
          sessionsData={sessionsData}
          sessionsMeta={sessionsMeta}
          showPagination={showPagination}
          hidePhotos={!showPhotos}
          hideCheckbox={hideCheckbox}
          onPageChange={onPageChange}
        />
      </Grid>
    </Grid>
  );
}

export const SessionsWithParams = withParams({
  initParams: getInitParams(),
  prepareParam,
})(Sessions);

const StatusArrayEnumParam = createEnumDelimitedNumericArrayParam([
  SESSION_STATUSES.active,
  SESSION_STATUSES.canceled,
  SESSION_STATUSES.finished,
  SESSION_STATUSES.await_finish,
  SESSION_STATUSES.stopped,
  SESSION_STATUSES.auto_finish,
]);

const SessionsWithQueryParams = withQueryParams({
  queryParams: {
    dateTo: withDefault(StringParam, ''),
    dateFrom: withDefault(StringParam, ''),
    status: withDefault(StatusArrayEnumParam, []),
    token: withDefault(StringParam, ''),
    search: withDefault(StringParam, ''),
    tabValue: withDefault(NumberParam, 0),
    onlyFakeSessions: withDefault(BooleanParam, false),
    page: PageParam,
  },
  prepareParam,
})(Sessions);

export default SessionsWithQueryParams;
