import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, Switch } from 'react-router-dom';
import moment from 'moment-timezone';

import PrivateRoute from 'components/templates/containers/PrivateRoute';
import SettingsContainer from 'components/templates/containers/SettingsContainer';
import FacilitySettingsPage from 'components/pages/settings/FacilitySettingsPage';
import CompanySettingsPage from 'components/pages/settings/CompanySettingsPage';
import ScheduleSettingsPage from 'components/pages/settings/ScheduleSettingsPage';
import PlacesSettingsPage from 'components/pages/settings/PlacesSettingsPage';
import TariffsPage from 'components/pages/settings/tariffs/TariffsPage';
import TariffPlansPage from 'components/pages/settings/tariffPlans/TariffPlansPage';
import IntegrationSettingsPage from 'components/pages/settings/IntegrationSettingsPage';
import SiteSettingsPage from 'components/pages/settings/SiteSettingsPage';
import LicenseSettingsPage from 'components/pages/settings/LicenseSettingsPage';
import PassesSettingsPage from 'components/pages/settings/passes/PassesSettingsPage';
import TicketsSettingsPage from 'components/pages/settings//tickets/TicketsSettingsPage';
import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';

import { getCurrentOperatorAction } from 'actions/operators';
import { getRegistrationStatusAction } from 'actions/company';
import { getFacilitiesAction, getFacilityAction } from 'actions/facilities';
import { PATH_PAGES } from 'config/constants';


function getRedirect({ registrationStatusData, facilityId, userRights }) {
  const {
    isCompanyCompleted,
    isFacilityCompleted,
    isFacilityScheduleCompleted,
    isFacilityPlacesCompleted,
    isFacilityIntegrationCompleted,
    isRegistrationCompleted,
  } = registrationStatusData || {};

  if (!isCompanyCompleted) {
    return PATH_PAGES.companySettings;
  }

  if (!isFacilityCompleted) {
    return PATH_PAGES.facilitySettings.replace(':facilityId', facilityId);
  }

  if (!isFacilityScheduleCompleted) {
    return PATH_PAGES.scheduleSettings.replace(':facilityId', facilityId);
  }

  if (!isFacilityPlacesCompleted) {
    return PATH_PAGES.placesSettings.replace(':facilityId', facilityId);
  }

  if (!isFacilityIntegrationCompleted) {
    return PATH_PAGES.integrationSettings.replace(':facilityId', facilityId);
  }

  if (isRegistrationCompleted) {
    const {
      isAdmin,
      isFacilityShow,
      isPlacesShow,
      isAutomationShow,
      isTariffShow,
      isPassTypeShow,
      isSiteShow,
    } = userRights || {};

    if (isAdmin) {
      return PATH_PAGES.companySettings;
    }

    if (isFacilityShow) {
      return PATH_PAGES.facilitySettings.replace(':facilityId', facilityId);
    }

    if (isPlacesShow) {
      return PATH_PAGES.placesSettings.replace(':facilityId', facilityId);
    }

    if (isAutomationShow) {
      return PATH_PAGES.integrationSettings.replace(':facilityId', facilityId);
    }

    if (isTariffShow) {
      return PATH_PAGES.tariffSettings.replace(':facilityId', facilityId);
    }

    if (isPassTypeShow) {
      return PATH_PAGES.passesSettings.replace(':facilityId', facilityId);
    }

    if (isSiteShow) {
      return PATH_PAGES.siteSettings.replace(':facilityId', facilityId);
    }

  }

  return PATH_PAGES.companySettings;
}

function SettingsPage({
  getRegistrationStatusReq,
  registrationStatusState,

  currentOperatorState,
  currentFacilityState,
}) {
  const {
    loading,
    data: registrationStatusData,
  } = registrationStatusState || {};

  const {
    isCompanyCompleted,
    isFacilityCompleted,
    isFacilityScheduleCompleted,
    isFacilityPlacesCompleted,
    isFacilityIntegrationCompleted,
    isRegistrationCompleted,
  } = registrationStatusData || {};

  const {
    loading: isCurrentFacilityIdLoading,
    data: currentFacilityData,
  } = currentFacilityState || {};

  const {
    id: currentFacilityId,
    timezone: tz,
  } = currentFacilityData || {};

  const { userRights } = currentOperatorState || {};

  const isLoading = loading || registrationStatusData === null || isCurrentFacilityIdLoading;

  const facilityId = !!currentFacilityId ? currentFacilityId : 'new';

  useEffect(() => {
    if (tz) {
      moment.tz.setDefault(tz);
      return;
    }

    moment.tz.setDefault();
  }, [tz]);

  return (
    <SettingsContainer
      currentOperatorState={currentOperatorState}
      registrationStatus={registrationStatusData}
      facilityId={facilityId}
    >
      {(isLoading) && (
        <CircularIndeterminate style={{ minHeight: 600 }} />
      )}
      {!isLoading && (
        <Switch>
          <PrivateRoute exact path={PATH_PAGES.companySettings} component={CompanySettingsPage} />
          <PrivateRoute exact path={PATH_PAGES.newFacilitySettings} component={FacilitySettingsPage} />
          <PrivateRoute exact path={PATH_PAGES.facilitySettings} component={FacilitySettingsPage} />
          <PrivateRoute exact path={PATH_PAGES.scheduleSettings} component={ScheduleSettingsPage} />
          <PrivateRoute exact path={PATH_PAGES.placesSettings} component={PlacesSettingsPage} />
          <PrivateRoute exact path={PATH_PAGES.integrationSettings} component={IntegrationSettingsPage} />
          <PrivateRoute path={PATH_PAGES.tariffSettings} component={TariffsPage} />
          <PrivateRoute path={PATH_PAGES.tariffPlanSettings} component={TariffPlansPage} />
          <PrivateRoute path={PATH_PAGES.passesSettings} component={PassesSettingsPage} />
          <PrivateRoute path={PATH_PAGES.ticketsSettings} component={TicketsSettingsPage} />
          <PrivateRoute path={PATH_PAGES.siteSettings} component={SiteSettingsPage} />
          <PrivateRoute path={PATH_PAGES.licenseSettings} component={LicenseSettingsPage} />
          <Redirect to={getRedirect({ registrationStatusData, facilityId, userRights })} />
        </Switch>
      )}
    </SettingsContainer>
  );
}

function mapStateToProps(state) {
  const { operators } = state || {};
  const { companies } = state || {};
  const { facilities } = state || {};
  const { currentFacility } = state || {};
  const { currentOperator } = operators || {};
  const { registrationStatus } = companies || {};
  const { allFacilities, oneFacility } = facilities || {};
  const { currentFacility: currentFacilityState } = currentFacility || {};

  return {
    currentOperatorState: currentOperator,
    registrationStatusState: registrationStatus,
    allFacilitiesState: allFacilities,
    oneFacilityState: oneFacility,
    currentFacilityState,
  };
}

const ConnectedSettingsPage = connect(
  mapStateToProps,
  {
    getCurrentOperatorReq: getCurrentOperatorAction,
    getRegistrationStatusReq: getRegistrationStatusAction,
    getFacilitiesReq: getFacilitiesAction,
    getFacilityReq: getFacilityAction,
  },
)(SettingsPage);

export default ConnectedSettingsPage;
