import React from 'react';
import { useHistory } from 'react-router-dom';

import CustomModalContentWrapper from '@parkly/shared/components/templates/CustomModalContentWrapper';
import { CircularProgress, Grid } from '@material-ui/core';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import Typography from '@material-ui/core/Typography';
import GridTitleBase from '@parkly/shared/components/molecules/GridTitleBase';
import { usePaymentInfo } from 'api/query/payments';
import { useModal } from '@parkly/shared/helpers';
import CancelPaymentModalContent from 'components/organisms/CancelPaymentModalContent';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@material-ui/lab';
import CustomLink from '@parkly/shared/components/atoms/CustomLink';
import { PATH_PAGES, RIGHT_KEYS } from 'config/constants';
import { useCheckFacilityRights } from 'helpers/hooks';
import moment from 'moment-timezone';
import {
  getPayableContent,
  getPayableDetailsContent,
  getStatusContentByStatus,
} from 'components/templates/PaymentsTable/PaymentsTable';
import { useStyles } from './styles';

function PaymentInfoModalContent({
  paymentId,
  facilityId,
  onClose = () => {},
}) {
  const styles = useStyles();
  const history = useHistory();

  const hasCustomerShowRight = useCheckFacilityRights(RIGHT_KEYS.customerShow, facilityId);
  const hasPaymentsCancelRight = useCheckFacilityRights(RIGHT_KEYS.paymentsCancel, facilityId);

  const {
    data: payment,
    isLoading,
  } = usePaymentInfo({
    variables: { id: paymentId, facilityId },
    cacheTime: 0,
  });

  const [cancelModalNode, openCancelModal, hideCancelModal] = useModal({
    content: <CancelPaymentModalContent
      facilityId={facilityId}
      paymentId={paymentId}
      onComplete={() => {
        hideCancelModal();
      }}
      onClose={() => {
        hideCancelModal();
      }}
    />,
  });

  const {
    id,
    sum,
    customerId,
    customerName,
    statuses,
    paidAt,
    status,
    paymentType,
    payable,
    canBeCanceled,
    receipts,
  } = payment || {};

  if (isLoading) {
    return (
      <CustomModalContentWrapper
        title="Платеж"
        onClose={onClose}
        paperClassname={styles.paper}
        bodyClassname={styles.modalBody}
      >
        <Grid style={{ height: '100%', minHeight: 300 }} container justifyContent="center" alignItems="center">
          <CircularProgress />
        </Grid>
      </CustomModalContentWrapper>
    );
  }

  return (
    <>
      <CustomModalContentWrapper
        title="Оплата"
        onClose={onClose}
        paperClassname={styles.paper}
        bodyClassname={styles.modalBody}
      >
        <Grid container spacing={2} style={{ marginBottom: 72 }}>
          <Grid item xs={12} sm={7}>
            <GridTitleBase
              classNameContainer={styles.itemContainer}
              title="ID"
              titleSm={6}
            >
              <Typography>
                {id}
              </Typography>
            </GridTitleBase>
            <GridTitleBase
              classNameContainer={styles.itemContainer}
              title="Дата"
              titleSm={6}
            >
              <Typography>
                {moment(paidAt).format('DD.MM.yyyy HH:mm:ss')}
              </Typography>
            </GridTitleBase>
            <GridTitleBase
              classNameContainer={styles.itemContainer}
              title="Сумма"
              titleSm={6}
            >
              <Typography>
                {`${(+sum || 0).toLocaleString()} ₽`}
              </Typography>
            </GridTitleBase>
            <GridTitleBase
              classNameContainer={styles.itemContainer}
              title="Клиент"
              titleSm={6}
            >
              {customerId && hasCustomerShowRight ? (
                <CustomLink
                  href={PATH_PAGES.oneCustomerInfo.replace(':facilityId', facilityId).replace(':id', customerId)}
                  onClick={(e) => {
                    e.preventDefault();
                    history.push(PATH_PAGES.oneCustomerInfo.replace(':facilityId', facilityId).replace(':id', customerId));
                  }}
                >
                  {customerName}
                </CustomLink>
              ) : (
                <Typography>
                  {customerName || 'Гость'}
                </Typography>
              )}
            </GridTitleBase>
            <GridTitleBase
              classNameContainer={styles.itemContainer}
              title="Статус оплаты"
              titleSm={6}
            >
              <Typography className={styles.status}>
                {getStatusContentByStatus(status)}
              </Typography>
            </GridTitleBase>
            <GridTitleBase
              classNameContainer={styles.itemContainer}
              title="За что платили"
              titleSm={6}
            >
              <div>
                {getPayableContent(payable, facilityId, history)}
                {getPayableDetailsContent(payable, styles.payableDetailsStr)}
              </div>
            </GridTitleBase>
            <GridTitleBase
              classNameContainer={styles.itemContainer}
              title="Способ оплаты"
              titleSm={6}
            >
              <Typography>
                {paymentType === 'manual' ? 'Ручной платеж' : 'На сайте'}
              </Typography>
            </GridTitleBase>
          </Grid>
          <Grid item container xs={12} sm={5} justifyContent="center">
            <Typography className={styles.paymentStatusesStr}>Статусы платежа:</Typography>
            <Timeline>
              {(statuses || []).map(({ date, status: statusStr }, index) => (
                <TimelineItem key={index}>
                  <TimelineOppositeContent>
                    <Typography color="textSecondary">{moment(date).format('HH:mm:ss')}</Typography>
                    <Typography color="textSecondary">{moment(date).format('DD.MM.yyyy')}</Typography>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot color="primary" />
                    {index !== ((statuses || []).length - 1) && (
                      <TimelineConnector />
                    )}
                  </TimelineSeparator>
                  <TimelineContent>
                    <Typography>{statusStr}</Typography>
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
          </Grid>
        </Grid>
        <div className={styles.btnsContainer}>
          <Grid container spacing={2}>
            {hasPaymentsCancelRight && canBeCanceled && (
              <Grid item xs={4}>
                <CustomBtn
                  className={styles.btn}
                  btnType="primaryRed"
                  onClick={openCancelModal}
                >
                  Вернуть деньги
                </CustomBtn>
              </Grid>
            )}
            {/*<Grid item xs={4}>*/}
            {/*  <CustomBtn className={styles.btn}>*/}
            {/*    Отправить квитанцию*/}
            {/*  </CustomBtn>*/}
            {/*</Grid>*/}
            {(receipts || []).map((receiptUrl, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Grid item xs={4} key={index}>
                <a href={receiptUrl} target="_blank" rel="noreferrer">
                  <CustomBtn className={styles.btn}>
                    {`Скачать чек №${index + 1}`}
                  </CustomBtn>
                </a>
              </Grid>
            ))}
          </Grid>
        </div>
      </CustomModalContentWrapper>
      {cancelModalNode}
    </>
  );
}

export default PaymentInfoModalContent;
