import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import clone from 'lodash/clone';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  CircularProgress,
  Grid,
  Menu,
  Tooltip,
} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import PhoneIcon from '@parkly/shared/components/atoms/icons/PhoneIcon';
import BarrierIcon from '@parkly/shared/components/atoms/icons/BarrierIcon';
import GateIcon from '@parkly/shared/components/atoms/icons/GateIcon';
import DoorIcon from '@parkly/shared/components/atoms/icons/DoorIcon';
import ItemSelectors from '@parkly/shared/components/molecules/ItemSelectors';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import TestExistsControllerModalContent from 'components/organisms/TestExistsControllerModalContent';

import {
  downloadQrImageFacilityGateAction,
  downloadQrPdfFacilityGateAction,
  getFacilityGatesWithControllersAction,
} from 'actions/facilities';
import { GATE_DIRECTIONS, GATE_DIRECTIONS_LABEL, GATE_TYPES, RIGHT_KEYS } from 'config/constants';
import { getRegistrationStatusAction } from 'actions/company';
import { getConditionalControllersAction, getControllersAction } from 'actions/controllers';
import { syncGatePortsControllers } from 'api/controllers';
import { formatPhoneNumber, useModal } from '@parkly/shared/helpers';
import EditIcon from '@material-ui/icons/Edit';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { useCheckFacilityRights } from 'helpers/hooks';
import CustomLink from '@parkly/shared/components/atoms/CustomLink';
import ChangeExtensionMenuItem from './ChangeExtensionMenuItem';
import { useStyles } from './styles';

function getCurrentGates({
  match,
  allFacilityGatesWithControllersState,
}) {
  const { facilityId } = (match || {}).params || {};

  const {
    loading: isGatesLoading,
    list: gatesList,
    error: gatesError,
  } = allFacilityGatesWithControllersState || {};

  const currentStrParam = JSON.stringify({
    id: facilityId,
  });

  const currentGates = gatesList.find((item) => {
    const { strParam } = item || {};
    const isCurrent = strParam === currentStrParam;

    return isCurrent;
  });

  return {
    isGatesLoading,
    currentGates,
    gatesError,
  };
}

function getCurrentControllers({
  match,
  allControllersState,
}) {
  const { facilityId } = (match || {}).params || {};

  const {
    loading: isControllersLoading,
    list: controllersList,
    error: controllersError,
  } = allControllersState || {};

  const currentStrParam = JSON.stringify({
    facilityId,
  });

  const currentControllers = controllersList.find((item) => {
    const { strParam } = item || {};
    const isCurrent = strParam === currentStrParam;

    return isCurrent;
  });

  return {
    isControllersLoading,
    currentControllers,
    controllersError,
  };
}

function TestIntegrationControllersSetting({
  match,

  allFacilityGatesWithControllersState,
  getFacilityGatesWithControllersReg,

  getControllersReg,
  allControllersState,

  getConditionalControllersReg,
  allConditionalControllersState,

  getRegistrationStatusReg,
  registrationStatusData,

  downloadQrImageFacilityGateReg,
  downloadQrPdfFacilityGateReg,

  syncGatePortsControllersApi = syncGatePortsControllers,
}) {
  const styles = useStyles();
  const { facilityId } = (match || {}).params || {};

  const [expandedGates, setExpandedGates] = useState([]);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [gatesData, setGatesData] = useState(null);
  const [testModalParams, setTestModalParams] = useState({});

  const { isRegistrationCompleted } = registrationStatusData || {};

  const hasAutomationUpdateRight = useCheckFacilityRights(RIGHT_KEYS.automationUpdate, facilityId);

  const [
    testExistsControllerModal,
    onOpenTestExistsControllerModal,
    onHideTestExistsControllerModal,
  ] = useModal({
    content: (
      <TestExistsControllerModalContent
        facilityId={facilityId}
        type={testModalParams.type}
        controllerId={testModalParams.controllerId}
        portId={testModalParams.portId}
        gateId={testModalParams.gateId}
        onClose={() => {
          onHideTestExistsControllerModal();
          setTestModalParams({});
        }}
      />
    ),
  });

  useEffect(() => {
    if (!facilityId) {
      return;
    }

    const {
      currentGates,
      isGatesLoading,
      gatesError,
    } = getCurrentGates({
      match,
      allFacilityGatesWithControllersState,
    });

    if (currentGates) {
      const { data } = currentGates;

      setGatesData(data);

      return;
    }

    if (isGatesLoading || currentGates || gatesError) {
      return;
    }

    getFacilityGatesWithControllersReg({ id: facilityId });
  }, [allFacilityGatesWithControllersState, facilityId, getFacilityGatesWithControllersReg, match]);

  useEffect(() => {
    if (!facilityId) {
      return;
    }

    const {
      controllersError,
      isControllersLoading,
      currentControllers,
    } = getCurrentControllers({
      match,
      allControllersState,
    });

    if (isControllersLoading || currentControllers || controllersError) {
      return;
    }

    getControllersReg({ facilityId });
  }, [allControllersState, allFacilityGatesWithControllersState, facilityId, getControllersReg, getFacilityGatesWithControllersReg, match]);

  useEffect(() => {
    if (!facilityId) {
      return;
    }

    const {
      controllersError,
      isControllersLoading,
      currentControllers,
    } = getCurrentControllers({
      match,
      allControllersState: allConditionalControllersState,
    });

    if (isControllersLoading || currentControllers || controllersError) {
      return;
    }

    getConditionalControllersReg({ facilityId });
  }, [allConditionalControllersState, facilityId, getConditionalControllersReg, match]);

  // useEffect(() => {
  //   if (isEmpty(gatesData) || isRegistrationCompleted) {
  //     return;
  //   }
  //
  //   if (gatesData.every(({ status }) => status === DEVICE_STATUS.active)) {
  //     getRegistrationStatusReg();
  //   }
  // }, [gatesData, getRegistrationStatusReg, isRegistrationCompleted]);

  function createDownloadQrImageGateHandler(id) {
    return () => {
      downloadQrImageFacilityGateReg({ facilityId, id });
    };
  }

  function createDownloadQrPdfGateHandler(id) {
    return () => {
      downloadQrPdfFacilityGateReg({ facilityId, id });
    };
  }

  const {
    isGatesLoading,
  } = getCurrentGates({
    match,
    allFacilityGatesWithControllersState,
  });

  const {
    currentControllers,
    isControllersLoading,
  } = getCurrentControllers({
    match,
    allControllersState,
  });

  const {
    data: controllersData,
  } = currentControllers || {};

  const controllersItems = useMemo(
    () => (controllersData || []).map(({ id, name }) => ({ id, title: name })),
    [controllersData],
  );

  const portsItemsMap = useMemo(() => {
    const result = {};

    (controllersData || []).forEach(({ id, ports }) => {
      const portsResult = [];
      (ports || []).forEach(({ id: portId, name: portName }) => {
        portsResult.push({ id: portId, title: portName });
      });
      result[id] = portsResult;
    });

    return result;
  }, [controllersData]);

  const {
    currentControllers: currentCondControllers,
    isControllersLoading: isCondControllersLoading,
  } = getCurrentControllers({
    match,
    allControllersState: allConditionalControllersState,
  });

  const {
    data: condControllersData,
  } = currentCondControllers || {};

  const condControllersItems = useMemo(
    () => (condControllersData || []).map(({ id, name }) => ({ id, title: name })),
    [condControllersData],
  );

  const condPortsItemsMap = useMemo(() => {
    const result = {};

    (condControllersData || []).forEach(({ id, ports }) => {
      const portsResult = [];
      (ports || []).forEach(({ id: portId, name: portName }) => {
        portsResult.push({ id: portId, title: portName });
      });
      result[id] = portsResult;
    });

    return result;
  }, [condControllersData]);

  function createTestControllerHandler(gateId, controllerId, portId) {
    return () => {
      if (!controllerId || !portId) {
        return;
      }

      const controller = controllersData.find(({ id }) => id === controllerId);
      setTestModalParams({
        type: controller.type,
        controllerId,
        portId,
        gateId,
      });
      onOpenTestExistsControllerModal();
    };
  }

  const onChangeController = useCallback((event) => {
    const { target } = event || {};
    const {
      name: filedName,
      value,
    } = target || {};

    const filedNameSplit = filedName.split('.');

    const type = filedNameSplit[0];
    const gateIndex = filedNameSplit[1];
    const controllerIndex = filedNameSplit[2];

    if (type === 'controller') {
      const controller = gatesData[gateIndex].controllers[controllerIndex];
      const ports = portsItemsMap[value] || [];
      controller.id = value;

      if(ports.length === 1) {
        controller.portId = ports[0].id;
      } else {
        controller.portId = '';
      }

      setGatesData(clone(gatesData));
    }

    if (type === 'controllerPort') {
      const controller = gatesData[gateIndex].controllers[controllerIndex];
      controller.portId = value;

      setGatesData(clone(gatesData));
    }
  }, [gatesData]);

  const onChangeTwoStageController = useCallback((event) => {
    const { target } = event || {};
    const {
      name: filedName,
      value,
    } = target || {};

    const filedNameSplit = filedName.split('.');

    const type = filedNameSplit[0];
    const gateIndex = filedNameSplit[1];
    const controllerIndex = filedNameSplit[2];

    if (type === 'twoStageController') {
      const controller = gatesData[gateIndex].twoStageControllers[controllerIndex];
      const ports = portsItemsMap[value] || [];
      controller.id = value;

      if(ports.length === 1) {
        controller.portId = ports[0].id;
      } else {
        controller.portId = '';
      }

      setGatesData(clone(gatesData));
    }

    if (type === 'twoStageControllerPort') {
      const controller = gatesData[gateIndex].twoStageControllers[controllerIndex];
      controller.portId = value;

      setGatesData(clone(gatesData));
    }
  }, [gatesData]);

  const onChangeAwaitFinishController = useCallback((event) => {
    const { target } = event || {};
    const {
      name: filedName,
      value,
    } = target || {};

    const filedNameSplit = filedName.split('.');

    const type = filedNameSplit[0];
    const gateIndex = filedNameSplit[1];
    const controllerIndex = filedNameSplit[2];

    if (type === 'awaitFinishController') {
      const controller = gatesData[gateIndex].awaitFinishControllers[controllerIndex];
      const ports = portsItemsMap[value] || [];
      controller.id = value;

      if(ports.length === 1) {
        controller.portId = ports[0].id;
      } else {
        controller.portId = '';
      }


      setGatesData(clone(gatesData));
    }

    if (type === 'awaitFinishControllerPort') {
      const controller = gatesData[gateIndex].awaitFinishControllers[controllerIndex];
      controller.portId = value;

      setGatesData(clone(gatesData));
    }
  }, [gatesData]);

  const onChangeManualController = useCallback((event) => {
    const { target } = event || {};
    const {
      name: filedName,
      value,
    } = target || {};

    const filedNameSplit = filedName.split('.');

    const type = filedNameSplit[0];
    const gateIndex = filedNameSplit[1];
    const controllerIndex = filedNameSplit[2];

    if (type === 'manualController') {
      const controller = gatesData[gateIndex].manualControllers[controllerIndex];
      const ports = portsItemsMap[value] || [];
      controller.id = value;

      if(ports.length === 1) {
        controller.portId = ports[0].id;
      } else {
        controller.portId = '';
      }

      setGatesData(clone(gatesData));
    }

    if (type === 'manualControllerPort') {
      const controller = gatesData[gateIndex].manualControllers[controllerIndex];
      controller.portId = value;

      setGatesData(clone(gatesData));
    }
  }, [gatesData]);

  const createDeleteController = (gateIndex, controllerIndex) => () => {
    gatesData[gateIndex].controllers.splice(controllerIndex, 1);

    setGatesData(clone(gatesData));
  };

  const createAddNewControllerHandler = (gateIndex) => () => {
    const gateControllers = gatesData[gateIndex].controllers;

    gateControllers.push({ id: '', portId: '' });

    setGatesData(clone(gatesData));
  };

  const createDeleteTwoStageController = (gateIndex, controllerIndex) => () => {
    gatesData[gateIndex].twoStageControllers.splice(controllerIndex, 1);

    setGatesData(clone(gatesData));
  };

  const createDeleteAwaitFinishController = (gateIndex, controllerIndex) => () => {
    gatesData[gateIndex].awaitFinishControllers.splice(controllerIndex, 1);

    setGatesData(clone(gatesData));
  };

  const createAddNewTwoStageControllerHandler = (gateIndex) => () => {
    const gateTwoStageControllers = gatesData[gateIndex].twoStageControllers;

    gateTwoStageControllers.push({ id: '', portId: '' });

    setGatesData(clone(gatesData));
  };

  const createAddNewAwaitFinishControllerHandler = (gateIndex) => () => {
    const gateAwaitFinishControllers = gatesData[gateIndex].awaitFinishControllers;

    gateAwaitFinishControllers.push({ id: '', portId: '' });

    setGatesData(clone(gatesData));
  };

  const createDeleteManualController = (gateIndex, controllerIndex) => () => {
    gatesData[gateIndex].manualControllers.splice(controllerIndex, 1);

    setGatesData(clone(gatesData));
  };

  const createAddNewManualControllerHandler = (gateIndex) => () => {
    const gateManualControllers = gatesData[gateIndex].manualControllers;

    gateManualControllers.push({ id: '', portId: '' });

    setGatesData(clone(gatesData));
  };

  const createExpandedHandler = (id) => (e, isExpanded) => {
    if (isExpanded) {
      expandedGates.push(id);
      setExpandedGates(clone(expandedGates));
    } else {
      setExpandedGates(expandedGates.filter((expandedId) => (expandedId !== id)));
    }
  };

  const createChangeLineExtensionHandler = (popup, gateIndex, lineIndex) => (value) => {
    popup.close();

    const line = gatesData[gateIndex].lines[lineIndex];
    line.extension = value;

    setGatesData(clone(gatesData));
  };

  const onChangeConditionalController = useCallback((event) => {
    const { target } = event || {};
    const {
      name: filedName,
      value,
    } = target || {};

    const filedNameSplit = filedName.split('.');

    const type = filedNameSplit[0];
    const gateIndex = filedNameSplit[1];
    const controllerIndex = filedNameSplit[2];
    const conditionalIndex = filedNameSplit[3];

    if (type === 'conditionalController') {
      const conditional = gatesData[gateIndex].controllers[controllerIndex].conditionals[conditionalIndex];
      conditional.id = value;

      const ports = portsItemsMap[conditional.id] || [];

      if(ports.length === 1) {
        conditional.portId = ports[0].id;
      } else {
        conditional.portId = '';
      }
      conditional.value = '';

      setGatesData(clone(gatesData));
    }

    if (type === 'conditionalControllerPort') {
      const conditional = gatesData[gateIndex].controllers[controllerIndex].conditionals[conditionalIndex];
      conditional.portId = value;
      conditional.value = '';

      setGatesData(clone(gatesData));
    }

    if (type === 'conditionalControllerValue') {
      const conditional = gatesData[gateIndex].controllers[controllerIndex].conditionals[conditionalIndex];
      conditional.value = value;

      setGatesData(clone(gatesData));
    }
  }, [gatesData]);

  const createAddConditionalHandler = (gateIndex, controllerIndex) => () => {
    const controllersData1 = gatesData[gateIndex].controllers[controllerIndex];

    controllersData1.conditionals = [
      ...(controllersData1.conditionals || []),
      { id: '', portId: '', value: '' },
    ];

    setGatesData(clone(gatesData));
  };

  const createDeleteConditionalHandler = (gateIndex, controllerIndex, conditionalIndex) => () => {
    gatesData[gateIndex].controllers[controllerIndex].conditionals.splice(conditionalIndex, 1);

    setGatesData(clone(gatesData));
  };

  const onSave = useCallback(() => {
    const syncData = gatesData.map(({
      id: gateId, controllers, lines, twoStageControllers, awaitFinishControllers, manualControllers,
    }) => ({
      id: gateId,
      lines: lines.map(({ id: lineId, extension }) => ({
        id: lineId,
        extension: `${extension}`,
      })),
      controllers: controllers
        .filter(({ id: controllerId, portId }) => (controllerId && portId))
        .map(({ id: controllerId, portId, conditionals }) => ({
          controllerId,
          portId,
          conditionals: (conditionals || [])
            .filter(({ id: condControllerId, portId: condPortId }) => (condControllerId && condPortId))
            .map(({ id: condControllerId, portId: condPortId, value }) => ({
              controllerId: condControllerId,
              portId: condPortId,
              value,
              logic: 'and',
            })),
        })),
      twoStageControllers: twoStageControllers
        .filter(({ id: controllerId, portId }) => (controllerId && portId))
        .map(({ id: controllerId, portId }) => ({
          controllerId,
          portId,
        })),
      awaitFinishControllers: awaitFinishControllers
        .filter(({ id: controllerId, portId }) => (controllerId && portId))
        .map(({ id: controllerId, portId }) => ({
          controllerId,
          portId,
        })),
      manualControllers: manualControllers
        .filter(({ id: controllerId, portId }) => (controllerId && portId))
        .map(({ id: controllerId, portId }) => ({
          controllerId,
          portId,
        })),
    }));

    setIsSaveLoading(true);
    syncGatePortsControllersApi({
      facilityId,
      data: syncData,
    }).then(() => {
      setIsSaveLoading(false);
      getFacilityGatesWithControllersReg({ id: facilityId });
      if (!isRegistrationCompleted) {
        getRegistrationStatusReg({ id: facilityId });
      }
    }).catch((error) => {
      setIsSaveLoading(false);
    });
  }, [facilityId, gatesData, getFacilityGatesWithControllersReg, getRegistrationStatusReg, isRegistrationCompleted, syncGatePortsControllersApi]);

  if (isGatesLoading || isControllersLoading || isCondControllersLoading) {
    return (
      <CircularIndeterminate style={{ minHeight: 600 }} />
    );
  }

  return (
    <Grid container spacing={2}>
      {(gatesData || []).map(({
        id, type, direction, name, lines, controllers, twoStageControllers, awaitFinishControllers, manualControllers,
      }, gateIndex) => (
        <Grid key={id} item xs={12} lg={9}>
          <Accordion
            expanded={expandedGates.includes(id)}
            onChange={createExpandedHandler(id)}
          >
            <AccordionSummary
              classes={{
                content: styles.summary,
              }}
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography className={styles.gateName}>
                {type === GATE_TYPES.barrier && (
                  <BarrierIcon />
                )}
                {type === GATE_TYPES.gate && (
                  <GateIcon />
                )}
                {type === GATE_TYPES.door && (
                  <DoorIcon />
                )}
                {name}
                <span>
                  {` (${GATE_DIRECTIONS_LABEL[type === GATE_TYPES.door ? `door_${direction}` : direction]})`}
                </span>
              </Typography>
            </AccordionSummary>
            <Divider />
            <AccordionDetails className={styles.details}>
              <Grid container spacing={2}>
                <Grid container spacing={2} item xs={12}>
                  {(lines || []).map(({ line, number, extension }, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Grid key={index} item>
                      <Typography className={styles.lineText}>
                        <PhoneIcon />
                        {`${formatPhoneNumber(number)},${extension}`}
                        <PopupState variant="popover" popupId={`line-popup-${gateIndex}-${index}`}>
                          {(popupState) => (
                            <>
                              <Tooltip title="Сменить добавочный">
                                <IconButton {...bindTrigger(popupState)}>
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                              <Menu {...bindMenu(popupState)}>
                                <ChangeExtensionMenuItem
                                  initValue={extension}
                                  onComplete={createChangeLineExtensionHandler(popupState, gateIndex, index)}
                                />
                              </Menu>
                            </>
                          )}
                        </PopupState>
                      </Typography>
                    </Grid>
                  ))}

                </Grid>
                <Grid item>
                  <CustomBtn
                    onClick={createDownloadQrImageGateHandler(id)}
                  >
                    QR для звонка
                  </CustomBtn>
                </Grid>
                <Grid item>
                  <CustomBtn
                    onClick={createDownloadQrPdfGateHandler(id)}
                  >
                    Вывеска
                  </CustomBtn>
                </Grid>
                <Grid item xs={12}>
                  <Typography>Звонок на номер активирует:</Typography>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                  {(controllers || []).map(({ id: controllerId, portId, conditionals }, controllerIndex) => (
                    <Grid item container spacing={0} xs={12} key={`${gateIndex}.${controllerIndex}.${controllerId}.${portId}`}>
                      <Grid item container spacing={1} xs={12}>
                        <Grid item xs={5}>
                          <ItemSelectors
                            classNameForm={styles.selectorsForm}
                            classNameLabel={styles.selectorsLabel}
                            name={`controller.${gateIndex}.${controllerIndex}`}
                            label="Контроллер"
                            items={controllersItems}
                            currentValue={controllerId || ''}
                            onChange={onChangeController}
                            disabled={!hasAutomationUpdateRight}
                            required
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <ItemSelectors
                            classNameForm={styles.selectorsForm}
                            classNameLabel={styles.selectorsLabel}
                            name={`controllerPort.${gateIndex}.${controllerIndex}`}
                            label="Порт"
                            items={portsItemsMap[gatesData[gateIndex].controllers[controllerIndex].id] || []}
                            currentValue={portId || ''}
                            onChange={onChangeController}
                            disabled={!controllerId || !hasAutomationUpdateRight}
                            required
                          />
                        </Grid>
                        {hasAutomationUpdateRight && (
                          <Grid item xs={1}>
                            <IconButton
                              className={styles.iconButton}
                              onClick={createDeleteController(gateIndex, controllerIndex)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        )}
                        {hasAutomationUpdateRight && (
                          <Grid item xs={1}>
                            <CustomBtn
                              className={styles.btn}
                              onClick={createTestControllerHandler(id, controllerId, portId)}
                            >
                              Тест
                            </CustomBtn>
                          </Grid>
                        )}
                      </Grid>
                      <Grid
                        container
                        item
                        spacing={0}
                        xs={12}
                        className={[
                          (conditionals || []).length !== 0 ? styles.conditionalContainer : '',
                        ].join(' ')}
                      >
                        {(conditionals || []).map(({ id: conditionalControllerId, portId: conditionalPortId, value: conditionalValue }, conditionalIndex) => (
                          <React.Fragment key={`${gateIndex}.${controllerIndex}.${controllerId}.${portId}.${conditionalIndex}.${conditionalControllerId}.${conditionalPortId}`}>
                            {conditionalIndex !== 0 && (
                              <Grid item xs={12}>
                                <Chip label="И" size="small" />
                              </Grid>
                            )}
                            <Grid container item spacing={1} xs={12} alignItems="center">
                              <Grid item xs={1}>
                                <Typography>Если</Typography>
                              </Grid>
                              <Grid item xs={5}>
                                <ItemSelectors
                                  classNameForm={styles.selectorsForm}
                                  classNameLabel={styles.selectorsLabel}
                                  name={`conditionalController.${gateIndex}.${controllerIndex}.${conditionalIndex}`}
                                  label="Контроллер"
                                  items={condControllersItems}
                                  currentValue={conditionalControllerId || ''}
                                  onChange={onChangeConditionalController}
                                  disabled={!hasAutomationUpdateRight}
                                  required
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <ItemSelectors
                                  classNameForm={styles.selectorsForm}
                                  classNameLabel={styles.selectorsLabel}
                                  name={`conditionalControllerPort.${gateIndex}.${controllerIndex}.${conditionalIndex}`}
                                  label="Порт"
                                  items={condPortsItemsMap[gatesData[gateIndex].controllers[controllerIndex].conditionals[conditionalIndex].id] || []}
                                  currentValue={conditionalPortId || ''}
                                  onChange={onChangeConditionalController}
                                  disabled={!conditionalControllerId || !hasAutomationUpdateRight}
                                  required
                                />
                              </Grid>
                              <Grid item xs={2}>
                                <ItemSelectors
                                  classNameForm={styles.selectorsForm}
                                  classNameLabel={styles.selectorsLabel}
                                  name={`conditionalControllerValue.${gateIndex}.${controllerIndex}.${conditionalIndex}`}
                                  label="Значение"
                                  items={[{ id: '0', title: '0' }, { id: '1', title: '1' }]}
                                  currentValue={conditionalValue || ''}
                                  onChange={onChangeConditionalController}
                                  disabled={!conditionalControllerId || !hasAutomationUpdateRight}
                                  required
                                />
                              </Grid>
                              {hasAutomationUpdateRight && (
                                <Grid item xs={1}>
                                  <IconButton
                                    className={styles.iconButton}
                                    onClick={createDeleteConditionalHandler(gateIndex, controllerIndex, conditionalIndex)}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Grid>
                              )}
                            </Grid>
                          </React.Fragment>
                        ))}
                        <Grid item container spacing={2}>
                          <Grid item xs={12}>
                            <CustomLink onClick={createAddConditionalHandler(gateIndex, controllerIndex)}>
                              + Добавить условие
                            </CustomLink>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                  {hasAutomationUpdateRight && (
                    <Grid item xs={12}>
                      <CustomBtn
                        btnType="primaryGreen"
                        onClick={createAddNewControllerHandler(gateIndex)}
                      >
                        Добавить контроллер
                      </CustomBtn>
                    </Grid>
                  )}
                </Grid>
                {[GATE_DIRECTIONS.enter, GATE_DIRECTIONS.both].includes(direction) && (
                  <>
                    {(twoStageControllers || []).length === 0 && (
                      <Grid item xs={12}>
                        <CustomBtn
                          onClick={createAddNewTwoStageControllerHandler(gateIndex)}
                        >
                          Добавить вторую фазу проезда
                        </CustomBtn>
                      </Grid>
                    )}
                    {(twoStageControllers || []).length > 0 && (
                      <Grid item xs={12} container spacing={2}>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>
                            Вторая фаза проезда активируется:
                          </Typography>
                        </Grid>
                        {(twoStageControllers || []).map(({ id: controllerId, portId }, controllerIndex) => (
                          <Grid item xs={12} container spacing={1} key={`twoStage.${gateIndex}.${controllerIndex}.${controllerId}.${portId}`}>
                            <Grid item xs={5}>
                              <ItemSelectors
                                classNameForm={styles.selectorsForm}
                                classNameLabel={styles.selectorsLabel}
                                name={`twoStageController.${gateIndex}.${controllerIndex}`}
                                label="Контроллер"
                                items={condControllersItems}
                                currentValue={controllerId || ''}
                                onChange={onChangeTwoStageController}
                                disabled={!hasAutomationUpdateRight}
                                required
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <ItemSelectors
                                classNameForm={styles.selectorsForm}
                                classNameLabel={styles.selectorsLabel}
                                name={`twoStageControllerPort.${gateIndex}.${controllerIndex}`}
                                label="Порт"
                                items={condPortsItemsMap[gatesData[gateIndex].twoStageControllers[controllerIndex].id]}
                                currentValue={portId || ''}
                                onChange={onChangeTwoStageController}
                                disabled={!controllerId || !hasAutomationUpdateRight}
                                required
                              />
                            </Grid>
                            {hasAutomationUpdateRight && (
                              <Grid item xs={1}>
                                <IconButton
                                  className={styles.iconButton}
                                  onClick={createDeleteTwoStageController(gateIndex, controllerIndex)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Grid>
                            )}
                          </Grid>
                        ))}
                        <Grid item xs={12}>
                          <CustomBtn
                            btnType="primaryGreen"
                            onClick={createAddNewTwoStageControllerHandler(gateIndex)}
                          >
                            Добавить контроллер
                          </CustomBtn>
                        </Grid>
                      </Grid>
                    )}
                  </>
                )}
                {[GATE_DIRECTIONS.exit, GATE_DIRECTIONS.both].includes(direction) && (
                  <>
                    {(awaitFinishControllers || []).length === 0 && (
                      <Grid item xs={12}>
                        <CustomBtn
                          onClick={createAddNewAwaitFinishControllerHandler(gateIndex)}
                        >
                          Добавить вторую фазу выезда
                        </CustomBtn>
                      </Grid>
                    )}
                    {(awaitFinishControllers || []).length > 0 && (
                      <Grid item xs={12} container spacing={2}>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>
                            Подтверждение выезда активируется:
                          </Typography>
                        </Grid>
                        {(awaitFinishControllers || []).map(({ id: controllerId, portId }, controllerIndex) => (
                          <Grid item xs={12} container spacing={1} key={`twoStage.${gateIndex}.${controllerIndex}.${controllerId}.${portId}`}>
                            <Grid item xs={5}>
                              <ItemSelectors
                                classNameForm={styles.selectorsForm}
                                classNameLabel={styles.selectorsLabel}
                                name={`awaitFinishController.${gateIndex}.${controllerIndex}`}
                                label="Контроллер"
                                items={condControllersItems}
                                currentValue={controllerId || ''}
                                onChange={onChangeAwaitFinishController}
                                disabled={!hasAutomationUpdateRight}
                                required
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <ItemSelectors
                                classNameForm={styles.selectorsForm}
                                classNameLabel={styles.selectorsLabel}
                                name={`awaitFinishControllerPort.${gateIndex}.${controllerIndex}`}
                                label="Порт"
                                items={condPortsItemsMap[gatesData[gateIndex].awaitFinishControllers[controllerIndex].id]}
                                currentValue={portId || ''}
                                onChange={onChangeAwaitFinishController}
                                disabled={!controllerId || !hasAutomationUpdateRight}
                                required
                              />
                            </Grid>
                            {hasAutomationUpdateRight && (
                              <Grid item xs={1}>
                                <IconButton
                                  className={styles.iconButton}
                                  onClick={createDeleteAwaitFinishController(gateIndex, controllerIndex)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Grid>
                            )}
                          </Grid>
                        ))}
                        <Grid item xs={12}>
                          <CustomBtn
                            btnType="primaryGreen"
                            onClick={createAddNewAwaitFinishControllerHandler(gateIndex)}
                          >
                            Добавить контроллер
                          </CustomBtn>
                        </Grid>
                      </Grid>
                    )}
                  </>
                )}
                {(manualControllers || []).length === 0 && (
                  <Grid item xs={12}>
                    <CustomBtn
                      onClick={createAddNewManualControllerHandler(gateIndex)}
                    >
                      Добавить автоматизацию кнопок
                    </CustomBtn>
                  </Grid>
                )}
                {(manualControllers || []).length > 0 && (
                  <Grid item xs={12} container spacing={2}>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>
                        Кнопки активируют:
                      </Typography>
                    </Grid>
                    {(manualControllers || []).map(({ id: controllerId, portId }, controllerIndex) => (
                      <Grid item xs={12} container spacing={1} key={`manual.${gateIndex}.${controllerIndex}.${controllerId}.${portId}`}>
                        <Grid item xs={5}>
                          <ItemSelectors
                            classNameForm={styles.selectorsForm}
                            classNameLabel={styles.selectorsLabel}
                            name={`manualController.${gateIndex}.${controllerIndex}`}
                            label="Контроллер"
                            items={controllersItems}
                            currentValue={controllerId || ''}
                            onChange={onChangeManualController}
                            disabled={!hasAutomationUpdateRight}
                            required
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <ItemSelectors
                            classNameForm={styles.selectorsForm}
                            classNameLabel={styles.selectorsLabel}
                            name={`manualControllerPort.${gateIndex}.${controllerIndex}`}
                            label="Порт"
                            items={portsItemsMap[gatesData[gateIndex].manualControllers[controllerIndex].id]}
                            currentValue={portId || ''}
                            onChange={onChangeManualController}
                            disabled={!controllerId || !hasAutomationUpdateRight}
                            required
                          />
                        </Grid>
                        {hasAutomationUpdateRight && (
                          <Grid item xs={1}>
                            <IconButton
                              className={styles.iconButton}
                              onClick={createDeleteManualController(gateIndex, controllerIndex)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        )}
                      </Grid>
                    ))}
                    <Grid item xs={12}>
                      <CustomBtn
                        btnType="primaryGreen"
                        onClick={createAddNewManualControllerHandler(gateIndex)}
                      >
                        Добавить контроллер
                      </CustomBtn>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      ))}
      {hasAutomationUpdateRight && (
        <Grid container item xs={12}>
          <Grid item xs={2}>
            <CustomBtn
              className={styles.btn}
              btnType="primaryGreen"
              onClick={onSave}
              disabled={isSaveLoading}
            >
              Сохранить
              {isSaveLoading && (
                <CircularProgress
                  style={{ marginLeft: 5 }}
                  size={20}
                  color="inherit"
                />
              )}
            </CustomBtn>
          </Grid>
        </Grid>
      )}
      {testExistsControllerModal}
    </Grid>
  );
}

function mapStateToProps(state) {
  const {
    facilities, companies, controllers,
  } = state || {};
  const { allFacilityGatesWithControllers } = facilities || {};
  const { allControllers, allConditionalControllers } = controllers || {};
  const { registrationStatus } = companies || {};

  const { data: registrationStatusData } = registrationStatus || {};

  return {
    registrationStatusData,
    allFacilityGatesWithControllersState: allFacilityGatesWithControllers,
    allControllersState: allControllers,
    allConditionalControllersState: allConditionalControllers,
  };
}

const ConnectedTestIntegrationControllersSetting = connect(
  mapStateToProps,
  {
    getRegistrationStatusReg: getRegistrationStatusAction,
    getFacilityGatesWithControllersReg: getFacilityGatesWithControllersAction,
    getControllersReg: getControllersAction,
    getConditionalControllersReg: getConditionalControllersAction,
    downloadQrImageFacilityGateReg: downloadQrImageFacilityGateAction,
    downloadQrPdfFacilityGateReg: downloadQrPdfFacilityGateAction,
  },
)(TestIntegrationControllersSetting);

export default ConnectedTestIntegrationControllersSetting;
