import React, { useState } from 'react';

import { Checkbox, Collapse } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import NavigationLink from '@parkly/shared/components/atoms/NavigationLink';
import P360Logo from '@parkly/shared/components/atoms/P360Logo';

import { useCheckFacilityRights, useHasModule, useIsOperatorRole } from 'helpers/hooks';

import { MODULES_KEYS, PATH_PAGES, RIGHT_KEYS, ROLES } from 'config/constants';

import { useStyles } from './styles';

const CustomListItemMenu = withStyles({
  root: {
    height: 50,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingLeft: 16,
    alignItems: 'center',
    padding: 0,
    textAlign: 'center',
  },
})(ListItem);

const CustomListItemMenuNested = withStyles({
  root: {
    height: 30,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingLeft: 36,
    alignItems: 'center',
    padding: 0,
    textAlign: 'center',
  },
})(ListItem);

function SettingsContainer({ children, registrationStatus, facilityId, currentOperatorState }) {
  const styles = useStyles();

  const [isExpanded, setIsExpanded] = useState(true);

  const isAdminRole = useIsOperatorRole(ROLES.admin);
  const isDealerRole = useIsOperatorRole(ROLES.dealer);
  const isAdmin = isAdminRole || isDealerRole;
  const hasFacilityShowRight = useCheckFacilityRights(RIGHT_KEYS.facilityShow, facilityId);
  const hasPlacesShowRight = useCheckFacilityRights(RIGHT_KEYS.placesShow, facilityId);
  const hasAutomationShowRight = useCheckFacilityRights(RIGHT_KEYS.automationShow, facilityId);
  const hasSiteShowRight = useCheckFacilityRights(RIGHT_KEYS.siteShow, facilityId);
  const hasTariffShowRight = useCheckFacilityRights(RIGHT_KEYS.tariffShow, facilityId);
  const hasPassTypeShowRight = useCheckFacilityRights(RIGHT_KEYS.passTypeShow, facilityId);
  const hasTicketTypeShowRight = useCheckFacilityRights(RIGHT_KEYS.ticketTypeShow, facilityId);

  const hasPaymentModule = useHasModule(facilityId, MODULES_KEYS.payments);

  const {
    isCompanyCompleted,
    isFacilityCompleted,
    isFacilityScheduleCompleted,
    isFacilityPlacesCompleted,
    isFacilityIntegrationCompleted,
    isRegistrationCompleted,
  } = registrationStatus || {};

  const isFacilitySettingsDisabled = !isCompanyCompleted;
  const isFacilityScheduleSettingsDisabled = isFacilitySettingsDisabled || !isFacilityCompleted;
  const isFacilityPlacesSettingsDisabled = isFacilityScheduleSettingsDisabled || !isFacilityScheduleCompleted;
  const isFacilityIntegrationSettingsDisabled = isFacilityPlacesSettingsDisabled || !isFacilityPlacesCompleted;

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        {!isRegistrationCompleted && <P360Logo className={styles.icon} />}
        {isRegistrationCompleted && <Typography className={styles.headerStr}>Информация о парковке</Typography>}
        <List className={styles.menuList}>
          {isAdmin && (
            <NavigationLink isNeedExact className={styles.navigationLink} to={PATH_PAGES.companySettings}>
              {({ isActive }) => (
                <CustomListItemMenu className={isActive ? styles.activeCustomListItemMenu : ''} button>
                  {!isRegistrationCompleted && <Checkbox color="primary" checked={isCompanyCompleted} />}
                  <ListItemText className={styles.text} primary="Карточка компании" />
                </CustomListItemMenu>
              )}
            </NavigationLink>
          )}
          {hasFacilityShowRight && (
            <NavigationLink
              isNeedExact
              className={styles.navigationLink}
              to={PATH_PAGES.facilitySettings.replace(':facilityId', facilityId)}
              disabled={isFacilitySettingsDisabled}
            >
              {({ isActive }) => (
                <CustomListItemMenu
                  className={isActive ? styles.activeCustomListItemMenu : ''}
                  button
                  disabled={isFacilitySettingsDisabled}
                >
                  {!isRegistrationCompleted && <Checkbox color="primary" checked={isFacilityCompleted} />}
                  {!isRegistrationCompleted ? (
                    <ListItemText className={styles.text} primary="Добавление парковки" />
                  ) : (
                    <ListItemText className={styles.text} primary="Парковка" />
                  )}
                </CustomListItemMenu>
              )}
            </NavigationLink>
          )}
          {hasFacilityShowRight && (
            <NavigationLink
              isNeedExact
              className={styles.navigationLink}
              to={PATH_PAGES.scheduleSettings.replace(':facilityId', facilityId)}
              disabled={isFacilityScheduleSettingsDisabled}
            >
              {({ isActive }) => (
                <CustomListItemMenu
                  className={isActive ? styles.activeCustomListItemMenu : ''}
                  button
                  disabled={isFacilityScheduleSettingsDisabled}
                >
                  {!isRegistrationCompleted && <Checkbox color="primary" checked={isFacilityScheduleCompleted} />}
                  <ListItemText className={styles.text} primary="График работы" />
                </CustomListItemMenu>
              )}
            </NavigationLink>
          )}
          {hasPlacesShowRight && (
            <NavigationLink
              isNeedExact
              className={styles.navigationLink}
              to={PATH_PAGES.placesSettings.replace(':facilityId', facilityId)}
              disabled={isFacilityPlacesSettingsDisabled}
            >
              {({ isActive }) => (
                <CustomListItemMenu
                  className={isActive ? styles.activeCustomListItemMenu : ''}
                  button
                  disabled={isFacilityPlacesSettingsDisabled}
                >
                  {!isRegistrationCompleted && <Checkbox color="primary" checked={isFacilityPlacesCompleted} />}
                  <ListItemText className={styles.text} primary="Конфигурация машиномест" />
                </CustomListItemMenu>
              )}
            </NavigationLink>
          )}
          {hasAutomationShowRight && (
            <NavigationLink
              isNeedExact
              className={styles.navigationLink}
              to={PATH_PAGES.integrationSettings.replace(':facilityId', facilityId)}
              disabled={isFacilityIntegrationSettingsDisabled}
            >
              {({ isActive }) => (
                <CustomListItemMenu
                  className={isActive ? styles.activeCustomListItemMenu : ''}
                  button
                  disabled={isFacilityIntegrationSettingsDisabled}
                >
                  {!isRegistrationCompleted && <Checkbox color="primary" checked={isFacilityIntegrationCompleted} />}
                  <ListItemText className={styles.text} primary="Автоматизация доступа" />
                </CustomListItemMenu>
              )}
            </NavigationLink>
          )}
          {isRegistrationCompleted && hasTicketTypeShowRight && (
            <NavigationLink
              className={styles.navigationLink}
              to={PATH_PAGES.ticketsSettings.replace(':facilityId', facilityId)}
              // disabled={isFacilityIntegrationSettingsDisabled}
            >
              {({ isActive }) => (
                <CustomListItemMenu
                  className={isActive ? styles.activeCustomListItemMenu : ''}
                  button
                  // disabled={isFacilityIntegrationSettingsDisabled}
                >
                  <ListItemText className={styles.text} primary="Типы пропусков" />
                </CustomListItemMenu>
              )}
            </NavigationLink>
          )}
          {isRegistrationCompleted && hasPaymentModule && (hasTariffShowRight || hasPassTypeShowRight) && (
            <>
              <CustomListItemMenu button onClick={() => setIsExpanded(!isExpanded)}>
                <ListItemText className={styles.text} primary="Платная парковка" />
                {isExpanded ? (
                  <ExpandLessIcon className={styles.expandIcon} fontSize="small" />
                ) : (
                  <ExpandMoreIcon className={styles.expandIcon} fontSize="small" />
                )}
              </CustomListItemMenu>
              <Collapse in={isExpanded}>
                <List component="div" disablePadding>
                  {hasTariffShowRight && (
                    <>
                      <NavigationLink
                        className={styles.navigationLink}
                        to={PATH_PAGES.tariffSettings.replace(':facilityId', facilityId)}
                        // disabled={isFacilityIntegrationSettingsDisabled}
                      >
                        {({ isActive }) => (
                          <CustomListItemMenuNested
                            className={isActive ? styles.activeCustomListItemMenu : ''}
                            button
                            // disabled={isFacilityIntegrationSettingsDisabled}
                          >
                            <ListItemText className={styles.text} primary="• Тарифы" />
                          </CustomListItemMenuNested>
                        )}
                      </NavigationLink>
                      <NavigationLink
                        className={styles.navigationLink}
                        to={PATH_PAGES.tariffPlanSettings.replace(':facilityId', facilityId)}
                        // disabled={isFacilityIntegrationSettingsDisabled}
                      >
                        {({ isActive }) => (
                          <CustomListItemMenuNested
                            className={isActive ? styles.activeCustomListItemMenu : ''}
                            button
                            // disabled={isFacilityIntegrationSettingsDisabled}
                          >
                            <ListItemText className={styles.text} primary="• Тарифные планы" />
                          </CustomListItemMenuNested>
                        )}
                      </NavigationLink>
                    </>
                  )}
                  {hasPassTypeShowRight && (
                    <NavigationLink
                      className={styles.navigationLink}
                      to={PATH_PAGES.passesSettings.replace(':facilityId', facilityId)}
                      // disabled={isFacilityIntegrationSettingsDisabled}
                    >
                      {({ isActive }) => (
                        <CustomListItemMenuNested
                          className={isActive ? styles.activeCustomListItemMenu : ''}
                          button
                          // disabled={isFacilityIntegrationSettingsDisabled}
                        >
                          <ListItemText className={styles.text} primary="• Абонементы" />
                        </CustomListItemMenuNested>
                      )}
                    </NavigationLink>
                  )}
                </List>
              </Collapse>
            </>
          )}
          {isRegistrationCompleted && hasSiteShowRight && (
            <NavigationLink
              isNeedExact
              className={styles.navigationLink}
              to={PATH_PAGES.siteSettings.replace(':facilityId', facilityId)}
              // disabled={isFacilityIntegrationSettingsDisabled}
            >
              {({ isActive }) => (
                <CustomListItemMenu
                  className={isActive ? styles.activeCustomListItemMenu : ''}
                  button
                  // disabled={isFacilityIntegrationSettingsDisabled}
                >
                  <ListItemText className={styles.text} primary="Веб-сайт" />
                </CustomListItemMenu>
              )}
            </NavigationLink>
          )}
          {isRegistrationCompleted && isAdmin && (
            <NavigationLink
              isNeedExact
              className={styles.navigationLink}
              to={PATH_PAGES.licenseSettings.replace(':facilityId', facilityId)}
              disabled={!isAdmin}
            >
              {({ isActive }) => (
                <CustomListItemMenu
                  className={isActive ? styles.activeCustomListItemMenu : ''}
                  button
                  disabled={!isAdmin}
                >
                  <ListItemText className={styles.text} primary="Лицензия" />
                </CustomListItemMenu>
              )}
            </NavigationLink>
          )}
          {/*{isRegistrationCompleted && isAdminRole && (*/}
          {/*  <NavigationLink*/}
          {/*    isNeedExact*/}
          {/*    className={styles.navigationLink}*/}
          {/*    to={PATH_PAGES.newFacilitySettings}*/}
          {/*    disabled={!isAdminRole}*/}
          {/*  >*/}
          {/*    { ({ isActive }) => (*/}
          {/*      <CustomListItemMenu*/}
          {/*        className={isActive ? styles.activeCustomListItemMenu : ''}*/}
          {/*        button*/}
          {/*        disabled={!isAdminRole}*/}
          {/*      >*/}
          {/*        <ListItemText className={styles.text} primary="Добавить парковку" />*/}
          {/*      </CustomListItemMenu>*/}
          {/*    )}*/}
          {/*  </NavigationLink>*/}
          {/*)}*/}
        </List>
      </div>
      <div className={styles.children}>{children}</div>
    </div>
  );
}

export default SettingsContainer;
